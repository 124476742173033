import React from "react";
import {Button, Checkbox} from "semantic-ui-react";
import Utils, {Loading, MyDateInput, MySelect, SelectData} from "../../../../utils/Utils";
import {Request} from "../../../../utils/Request";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {SystemUser} from "../../../AppBar";
import {ToastsStore} from "react-toasts";
import moment from "moment";

interface Test {
    cn: string
    dc: string
    di: number
    dn: string
    rp: string
    rs: string
    un: string
    tr: string
    tl: string
    tu: string
}

export default function TestReport(props: { systemUser: SystemUser, centers: Array<SelectData> }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [pdf, setPdf] = React.useState({show: false, url: ""})
    const [tests, setTests] = React.useState<Array<Test>>([])
    const [search, setSearch] = React.useState({
        min_date: Utils.today(), max_date: Utils.today(), status: '', upload_status: 2,
        center_id: props.systemUser.center.center_id
    })

    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [rows, setRows] = React.useState<Array<{ editable: boolean, name: string, title: string, selected: boolean }>>([
        {editable: true, selected: true, name: 'diagnosis_no', title: 'Diagnosis No.'},
        {editable: true, selected: true, name: 'dl.log_time', title: 'Date Created'},
        {editable: true, selected: true, name: 'district', title: 'District'},
        {
            editable: true,
            selected: true,
            name: "COALESCE(concat(cs.last_name, ' ', cs.first_name, ' ', cs.other_name), '') AS client_name",
            title: 'Client Name'
        },
        {editable: true, selected: true, name: "COALESCE(concat(ud.last_name, ' ', ud.first_name), '') AS user_name", title: 'User Name'},
        {editable: true, selected: true, name: 'testing_purpose', title: 'Testing Purpose'},
        {editable: true, selected: true, name: 'results', title: 'Test Results'},
        {editable: true, selected: true, name: 'passport_no', title: 'Passport No.'},
        {editable: true, selected: true, name: 'date_of_birth', title: 'Date of Birth'},
        {editable: true, selected: true, name: 'result_status', title: 'Results Status'},
        {editable: true, selected: true, name: 'client_gender', title: 'Gender'},
        {editable: true, selected: true, name: 'diagnosis.diagnosis_id', title: 'Diagnosis ID'},
        {editable: true, selected: true, name: 'coalesce(cs.email_address, "") as email_address', title: 'Email Address'},
        {editable: true, selected: true, name: 'cs.mobile_contact as client_contact', title: 'Contact'},
        {editable: true, selected: true, name: "coalesce(world.name, nationality) as nationality", title: 'Nationality'},
        {editable: true, selected: true, name: 'diagnosis.discount_reason', title: 'Reason for Discount'},
        {editable: true, selected: true, name: 'diagnosis.discount', title: 'Discount Amount'},
        {editable: true, selected: true, name: "coalesce(mail_status, '') as mail_status", title: 'Mail Status'},
    ])

    const search_payments = (type: 'table' | 'pdf' | 'csv') => {
        let _rows: Array<{ name: string, title: string }> = []

        if (type === 'csv') {
            _rows = rows
                .filter((_row) => _row.selected)
                .map((_row) => ({name: _row.name, title: _row.title}))
            if (_rows.length === 0) {
                ToastsStore.info("No data rows selected for export")
                return
            }
        }

        setLoader({message: "Generating report, please wait", show: true})
        Request.get_tests_reports({
            max_date: search.max_date, min_date: search.min_date, status: search.status, type: type,
            upload_status: search.upload_status, center_id: search.center_id, rows: JSON.stringify(_rows)
        })
            .then((response) => {
                if (type === 'pdf') {
                    setPdf({show: true, url: Request.BASE_URL + response.data.data.url})
                } else if (type === 'table') {
                    setTests(response.data.data.tests)
                    setPdf({show: false, url: ''})
                } else {
                    const anchor = document.createElement("a");
                    anchor.href = Request.BASE_URL + response.data.data.url;
                    anchor.setAttribute("download", response.data.data.name);
                    anchor.click();
                }
                setLoader({message: "", show: false})
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not generate report")
            })
    }

    const [fromStart, setFromStart] = React.useState(false)
    React.useEffect(() => {
        setSearch({...search, min_date: fromStart ? '2020-06-06' : Utils.today()})
    }, [fromStart])

    React.useEffect(() => {
        search_payments('table')
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <>
                <div className="content_bar two_buttons">
                    <div className="search_bar">
                        <div className='dropdown'>
                            <Button icon='ordered list' primary size='tiny'/>
                            <div className='drop_list'>
                                <div className='hide_large'>
                                    <div className='search_check'>
                                        <Checkbox
                                            label='From Beginning' checked={fromStart}
                                            onChange={(event, data) => setFromStart(data.checked as boolean)}/>
                                    </div>
                                    <label>Minimum Date</label>
                                    <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                                 change={handle_search} maxDate={Utils.today()}/>

                                    <label>Maximum Date</label>
                                    <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                                 change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                                </div>

                                <label>Center Name</label>
                                <MySelect
                                    disabled={
                                        Request.COMPANY === 'MEDSAFE' ||
                                        !Utils.has_center_role({role: "manage_centers", roles: props.systemUser.center.center_roles})
                                    }
                                    name={'center_id'} value={search.center_id} placeholder='Select a center'
                                    change={(value) => handle_search('center_id', value as number)}
                                    options={[{text: 'Show all centers', value: 0}, ...props.centers]}/>

                                <label>Results status</label>
                                <MySelect
                                    change={(value) => handle_search('status', value as string)}
                                    name="status" value={search.status} placeholder="Display all results"
                                    options={
                                        [
                                            {value: '', text: 'All results'}, {value: 'pending', text: 'Receipt Pending'},
                                            {value: 'received', text: 'Lab Received'}, {value: 'rejected', text: 'Lab Rejected'},
                                            {value: 'complete', text: 'Lab Completed'}, {value: 'verified', text: 'Admin Verified'}
                                        ]
                                    }/>

                                <label>Name, Email or Diagnosis No.</label>
                                <MySelect
                                    change={(value) => handle_search('upload_status', value as number)}
                                    name="upload_status" value={search.upload_status} placeholder="Display all results"
                                    options={
                                        [
                                            {value: 2, text: 'All results'},
                                            {value: 0, text: 'Pending Upload'},
                                            {value: 1, text: 'Uploaded'}
                                        ]}/>
                            </div>
                        </div>

                        <div className='dropdown'>
                            <Button icon='ordered list' primary size='tiny'/>
                            <div className='drop_list list_only'>
                                {
                                    rows
                                        .sort(function (b, a) {
                                            return a.title > b.title ? -1 : a.title < b.title ? 1 : 0;
                                        })
                                        .map((row, index) =>
                                            <Checkbox
                                                key={row.name} label={row.title} checked={row.selected}
                                                disabled={!row.editable}
                                                onChange={(event, data) => {
                                                    setRows(rows.map((_row, _index) =>
                                                        _index === index ? {..._row, selected: data.checked as boolean} : _row
                                                    ))
                                                }}/>)
                                }
                            </div>
                        </div>

                        <div className='search_check hide_small'>
                            <Checkbox label='From Beginning' checked={fromStart}
                                      onChange={(event, data) => {
                                          setFromStart(data.checked as boolean)
                                      }}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum test date"
                                         change={handle_search} maxDate={Utils.today()}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum test date"
                                         change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                        </div>
                    </div>

                    <div className="content_buttons d-none d-md-inline-block">
                        <div className="payment_button">
                            <Button negative size='mini' content='Table' onClick={() => search_payments('table')}/>
                        </div>
                        <div className="payment_button">
                            <Button negative size='mini' content='PDF' onClick={() => search_payments('pdf')}/>
                        </div>
                        <div className="payment_button">
                            <Button negative size='mini' content='CSV' onClick={() => search_payments('csv')}/>
                        </div>
                    </div>

                    <div className="content_buttons d-md-none">
                        <Button primary size='mini' icon='table' onClick={() => search_payments('table')}/>
                        <Button primary size='mini' icon='file pdf' onClick={() => search_payments('pdf')}/>
                        <Button primary size='mini' icon='file excel' onClick={() => search_payments('csv')}/>
                    </div>
                </div>

                <div className="table_container test_report_container">
                    {
                        !pdf.show &&
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Diagnosis No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Status</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '80px'}}>Results</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Created By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Time Created</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Received</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Loaded</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Results</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    tests.map((payment, index) =>
                                        <Table.Row key={payment.di}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{payment.dn}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{payment.cn}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{Utils.format_status_name(payment.rs)}</Table.Cell>
                                            <Table.Cell style={{width: '80px'}}>{payment.rp}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{payment.un}</Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{moment(payment.dc).format("llll")}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>
                                                {payment.tr === ""? "": moment(payment.tr).format("hh:mm:ss A")}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>
                                                {payment.tl === ""? "": moment(payment.tl).format("hh:mm:ss A")}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>
                                                {payment.tu === ""? "": moment(payment.tu).format("hh:mm:ss A")}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    }
                    {
                        pdf.show && pdf.url !== "" &&
                        <iframe src={pdf.url} style={{width: '100%', height: '100%'}} frameBorder="0"/>
                    }
                </div>
            </>
        </>
    )
}
