import React from "react"
import {Checkbox} from "semantic-ui-react";
import {Triage} from "./CreateTest";
import Utils, {MyDateInput} from "../../../utils/Utils";

export default function DiagnosisTriage(params: { triage: Triage, setTriage: (triage: Triage) => void }) {
    return (
        <div className='row m-0 triage'>
            <div className='col-12 col-md-6 p-2 h-100'>
                <div className="test_header">Clinical Diagnosis</div>
                <div className="test_data">
                    <div className="row m-0">
                        <div className="col-12 p-1">
                            <div className="diagnosis_test">
                                <div className='test_header'>Date of onset of signs</div>
                                <MyDateInput value={params.triage.date} name='date' placeholder='Onset Date' maxDate={Utils.today()}
                                             minDate={"2020-01-01"}
                                             change={(text, value) => {
                                                 params.setTriage({...params.triage, date: value})
                                             }}/>
                            </div>
                        </div>

                        {
                            [
                                "Chest pain", "Shortness of Breath", "Cough", "General Body weakness",
                                "Headache", "Runny nose", "Sore throat"
                            ]
                                .map((sign, index) =>
                                    <div className="col-12 p-1" key={index}>
                                        <div className="diagnosis_test">
                                            <Checkbox
                                                label={sign} checked={params.triage.signs.includes(sign)}
                                                onChange={((event, data) => {
                                                    if (data.checked as boolean) {
                                                        params.setTriage({
                                                            ...params.triage,
                                                            signs: [...params.triage.signs, sign]
                                                        })
                                                    } else {
                                                        params.setTriage({
                                                            ...params.triage,
                                                            signs: params.triage.signs.filter((_sign) => _sign !== sign)
                                                        })
                                                    }
                                                })}/>
                                        </div>
                                    </div>)
                        }
                    </div>
                </div>
            </div>

            <div className='col-12 col-md-6 p-2 h-100'>
                <div className="test_header">Other Conditions</div>
                <div className="test_data">
                    <div className="row m-0">
                        {
                            [
                                "Neurological disease", "Cardiovascular disease", "Tuberculosis", "Postpartum less than 6 months",
                                "Hypertension Diabetics", "Renal Disease", "HIV", "Chronic Lung condition", "Liver disease"
                            ]
                                .map((state, index) =>
                                    <div className="col-12 p-1" key={index}>
                                        <div className="diagnosis_test">
                                            <Checkbox label={state} checked={params.triage.state.includes(state)}
                                                      onChange={((event, data) => {
                                                          if (data.checked as boolean) {
                                                              params.setTriage({
                                                                  ...params.triage,
                                                                  state: [...params.triage.state, state]
                                                              })
                                                          } else {
                                                              params.setTriage({
                                                                  ...params.triage,
                                                                  state: params.triage.state.filter((_state) => _state !== state)
                                                              })
                                                          }
                                                      })}/>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
