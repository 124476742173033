import React from "react";
import Utils, {Loading, MessageProps, my_initial_confirm, MyConfirmAction, MyDateInput, MyInput, MyMessage} from "../../../utils/Utils";
import {Button, Checkbox, Confirm, Form, Modal, Table, TextArea} from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";
import {Request} from "../../../utils/Request";
import update from "react-addons-update";
import {SystemUser} from "../../AppBar";
import {ToastsStore} from "react-toasts";

export interface Group {
    group_id: number
    group_name: string
    ordinary_price: number | string
    express_amount: number | string
    date_created: string
    contact_person: string
    contact_email: string
    payment_method: 'cash' | 'credit',
    group_no: string
    group_type: 'organised' | 'collection'
}

interface GroupMember {
    client_name: string
    client_id: string
    mobile_contact: string
    email_address: string
    tests: number
}

const init_group: Group = {
    express_amount: 0, contact_email: "", contact_person: "", date_created: Utils.today(), group_id: 0, group_name: "",
    group_type: 'organised', ordinary_price: 0, group_no: "", payment_method: 'credit'
}

export default function Groups(props: { systemUser: SystemUser }) {
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [loading, setLoading] = React.useState(false)
    const [saving, setSaving] = React.useState(false)

    const [groups, setGroups] = React.useState<Array<Group>>([])
    const [group, setGroup] = React.useState<Group>(init_group)

    const handle_change = (name: string, value: string | number) => {
        setGroup({...group, [name]: value})
    }

    /*group data*/
    const [search, setSearch] = React.useState({name: ""})
    const load_groups = () => {
        setLoading(true)
        Request.get_groups({name: search.name})
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("data")) {
                    setGroups(response.data.data)
                    if (response.data.data.length === 0) {
                        ToastsStore.info("No groups found")
                    }
                } else {
                    setMessage({active: true, message: "Could not fetch groups, please retry", type: 'error'})
                }
            })
            .catch(() => {
                setLoading(false)
                setMessage({active: true, message: "Could not fetch groups, please retry", type: 'error'})
            })
    }

    const save_group = () => {
        if (group.group_name.trim().length < 3) {
            setMessage({active: true, message: "Enter a valid group name, at least 3 characters", type: 'error'})
        } else if (Utils.invalid_contact(group.contact_person)) {
            setMessage({active: true, message: "Enter a valid group contact number", type: 'error'})
        } else if (Utils.in_valid_email(group.contact_email)) {
            setMessage({active: true, message: "Enter a valid group contact email", type: 'error'})
        } else if (!Utils.is_valid_number(group.ordinary_price as string) || parseFloat(group.ordinary_price as string) < 0) {
            setMessage({active: true, message: "Enter a valid group individual ordinary price", type: 'error'})
        } else if (!Utils.is_valid_number(group.express_amount as string) || parseFloat(group.express_amount as string) < 0) {
            setMessage({active: true, message: "Enter a valid group individual express price", type: 'error'})
        } else {
            setConfirm({
                ...confirm,
                'open': true,
                'content': "Do you want to continue saving this group data? Please note that to check correct payment method is selected for the group.",
                'onConfirm': () => {
                    setConfirm({...confirm, 'open': false})
                    setSaving(true)
                    Request.save_group(group)
                        .then((response) => {
                            setSaving(false)
                            if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                                if (group.group_id === 0) {
                                    const _group: Group = {
                                        ...group,
                                        group_id: response.data.data.group_id,
                                        group_no: response.data.data.group_no
                                    }
                                    setGroup(_group)
                                    setGroups([_group, ...groups])
                                } else {
                                    for (let index = 0; index < groups.length; index++) {
                                        if (groups[index].group_id === group.group_id) {
                                            setGroups(update(groups, {[index]: {$set: group}}))
                                            break
                                        }
                                    }
                                }
                                setMessage({active: true, message: "Successfully saved group information", type: 'success'})
                            } else {
                                setMessage({active: true, message: "Could not save group information, please retry", type: 'error'})
                            }
                        })
                        .catch(() => {
                            setSaving(false)
                            setMessage({active: true, message: "Could save not group information, please retry", type: 'error'})
                        })
                },
                'onCancel': () => setConfirm({...confirm, 'open': false})
            })

        }
    }

    const [loader, setLoader] = React.useState({show: false, message: "Uploading data, please wait"})
    const [loadingMembers, setLoadingMembers] = React.useState(false)
    const [showMembers, setShowMembers] = React.useState(false)
    const [groupMembers, setGroupMembers] = React.useState<Array<GroupMember>>([])

    const get_group_members = () => {
        setLoadingMembers(true)
        Request.get_groups_members({group_id: group.group_id})
            .then((response) => {
                if (response.data.hasOwnProperty("data")) {
                    setGroupMembers(response.data.data.members)
                    if (response.data.data.members.length === 0) {
                        setMessage({active: true, message: "No group members found", type: 'info'})
                    }
                } else {
                    setMessage({active: true, message: "Could not get group members", type: 'error'})
                }
                setLoadingMembers(false)
            })
            .catch(() => {
                setLoadingMembers(false)
                setMessage({active: true, message: "Could not get group members", type: 'error'})
            })
    }

    React.useEffect(() => {
        load_groups()
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content} centered={false}/>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar two_buttons">
                        <div className="search_bar">
                            <div style={{width: '250px'}}>
                                <MyInput placeholder="Search by group name" name="name"
                                         value={search.name} enter={load_groups}
                                         change={((name, value) => setSearch({name: value}))}/>
                            </div>
                        </div>

                        <div className="content_buttons d-none d-md-inline-block">
                            <Button
                                primary size='mini' loading={loading} disabled={loading || saving}
                                icon='search' labelPosition="left" content="Search Groups" onClick={load_groups}/>
                        </div>

                        <div className="content_buttons d-md-none">
                            <Button
                                primary size='mini' loading={loading} disabled={loading || saving} icon='search' onClick={load_groups}/>
                        </div>
                    </div>

                    <div className="table_container group_container bg_white px-2 py-0">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '210px'}}>Group Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Contact Person</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Ordinary Price</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Express Price</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    groups.map((test, index) =>
                                        <Table.Row
                                            key={test.group_id}
                                            onClick={() => setGroup({...test, contact_person: '+' + test.contact_person})}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '210px'}}>{test.group_name}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{test.contact_person}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(test.ordinary_price as number)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(test.express_amount as number)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="app_data_form bg_white">
                        <div className="label">Group name</div>
                        <div className="user_data_container">
                            <MyInput placeholder="Enter group name" name="group_name"
                                     value={group.group_name} change={handle_change}/>
                        </div>

                        <div className="label">Group Number</div>
                        <div className="user_data_container">
                            <MyInput placeholder="Enter group number" name="group_no" value={group.group_no} disabled={true}/>
                        </div>

                        <div className="label">Group Contact Email Address</div>
                        <div className="user_data_container">
                            <MyInput placeholder="Enter email address" name="contact_email"
                                     value={group.contact_email} change={handle_change}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Group Mobile Contact</div>
                            <PhoneInput
                                countryCodeEditable={true} country={'ug'} value={group.contact_person}
                                onChange={(value) => handle_change('contact_person', value)}/>
                        </div>

                        <div className="label mt-3">Group type</div>
                        <Form>
                            <div className="row m-0">
                                {
                                    [{value: "organised", text: "Organised Company"}, {value: "collection", text: "Random Collection"}]
                                        .map((item) =>
                                            <div className="col-6 px-1" key={item.value}>
                                                <Form.Field>
                                                    <Checkbox
                                                        radio label={item.text}
                                                        name='group_type_input' disabled={group.group_id > 0}
                                                        value={item.value} checked={group.group_type === item.value}
                                                        onChange={(event, data) => {
                                                            handle_change('group_type', data.value as string)
                                                        }}/>
                                                </Form.Field>
                                            </div>
                                        )
                                }
                            </div>
                        </Form>

                        <div className="label mt-3">Select payment type</div>
                        <Form>
                            <div className="row m-0">
                                {
                                    [{value: "cash", text: "Cash Payment"}, {value: "credit", text: "Credit Payment"}]
                                        .map((item) =>
                                            <div className="col-6 px-1" key={item.value}>
                                                <Form.Field>
                                                    <Checkbox
                                                        radio label={item.text} name='group_payment_method'
                                                        value={item.value} checked={group.payment_method === item.value}
                                                        onChange={(event, data) => {
                                                            handle_change('payment_method', data.value as string)
                                                        }}/>
                                                </Form.Field>
                                            </div>
                                        )
                                }
                            </div>
                        </Form>

                        <div className='row m-0'>
                            <div className='col-6 pl-0 pr-1'>
                                <div className="label mt-3">Ordinary amount</div>
                                <div className="user_data_container mt-1">
                                    <MyInput placeholder="Enter ordinary price" name="ordinary_price" change={handle_change}
                                             value={group.ordinary_price as string}/>
                                </div>
                            </div>
                            <div className='col-6 pl-0 pr-1'>
                                <div className="label mt-3">Express amount</div>
                                <div className="user_data_container mt-1">
                                    <MyInput placeholder="Enter express price" name="express_amount" change={handle_change}
                                             value={group.express_amount as string}/>
                                </div>
                            </div>
                        </div>

                        <div className="label mt-3">Group creation date</div>
                        <div className="user_data_container">
                            <MyDateInput
                                value={group.date_created} name="date_created" placeholder="Date created"
                                maxDate={Utils.today()} disabled={group.group_id > 0}
                                change={(name, value) => handle_change(name, value)}/>

                        </div>
                    </div>

                    <div className="app_data_button bg_white">
                        <div className="row m-0">
                            <div className="col-4 pl-0 pr-1">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                        disabled={saving || loading}
                                        content="Clear Data" onClick={() => setGroup(init_group)}/>
                            </div>

                            <div className="col-4 p-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid loading={saving}
                                        content="Save Data" onClick={save_group} disabled={saving || loading}/>
                            </div>

                            <div className="col-4 pl-1 pr-0">
                                <Button
                                    primary size='mini' icon='users' labelPosition="left" content="Members"
                                    disabled={group.group_id === 0}
                                    onClick={() => {
                                        setShowMembers(true)
                                        get_group_members()
                                    }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size='small' open={showMembers} centered={false} onClose={() => setShowMembers(false)}>
                <div className="test_slip">
                    <div className="content_bar two_buttons">
                        <div className="search_bar">
                            <div className="group_name">
                                <MyInput placeholder="Search by name" name="name" value={group.group_name} disabled={true}/>
                            </div>
                        </div>

                        <div className="content_buttons">
                            <Button
                                primary size='mini' loading={loadingMembers} disabled={loadingMembers}
                                icon='search' labelPosition="left" content="Search" onClick={get_group_members}/>
                        </div>
                    </div>

                    <div className="table_container group_members p-2">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell>Client Email</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '130px'}}>Client Contact</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">Tests</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    groupMembers.map((client, index) =>
                                        <Table.Row key={client.client_id}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">
                                                {Utils.row_number(index)}
                                            </Table.Cell>
                                            <Table.Cell>{client.client_name}</Table.Cell>
                                            <Table.Cell>{client.email_address}</Table.Cell>
                                            <Table.Cell style={{width: '130px'}}>{client.mobile_contact}</Table.Cell>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{client.tests}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Modal>
        </>
    )
}
