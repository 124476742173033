import React from "react";
import Utils, {Loading, MessageProps, my_initial_confirm, MyConfirmAction, MyInput, MyMessage} from "../../../../utils/Utils";
import {Request} from "../../../../utils/Request";
import {Button, Checkbox, Confirm, Modal, Table} from "semantic-ui-react";
import update from "react-addons-update";

interface PaymentInvoice {
    amount_paid: number
    client_name: string
    date_created: string
    diagnosis_no: string
    discount: number
    diagnosis_id: number
    used: number
}

export default function HandleInvoices(params: { company_id: number }) {
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [pendingInvoices, setPendingInvoices] = React.useState<Array<PaymentInvoice>>([])
    const get_invoices = () => {
        setLoader({message: "Loading un-invoiced payments, please wait", show: true})
        Request.get_group_pending_invoices({group_id: params.company_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setPendingInvoices(response.data.data)
                } else {
                    setMessage({active: true, message: "Could not fetch invoice data, please retry", type: 'error'})
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                setMessage({active: true, message: "Could not fetch invoice data, please retry", type: 'error'})
            })
    }

    const [url, setURL] = React.useState("")

    const [details, setDetails] = React.useState({
        show: false, invoice_no: "", tin_no: "1009455064", address_person: ""
    })
    const generate_invoices = () => {
        setDetails({...details, show: false})

        let ids: Array<number> = []
        pendingInvoices.forEach((invoice) => {
            if (invoice.used === 1) {
                ids = [...ids, invoice.diagnosis_id]
            }
        })

        if (ids.length === 0) {
            setMessage({active: true, message: "No invoices to generate, select the checkboxes to add invoices", type: 'info'})
        } else {
            setLoader({message: "Generating company invoices, please wait", show: true})
            Request.print_group_invoice({
                invoice_no: details.invoice_no, tin_no: details.tin_no,
                group_id: params.company_id, ids: JSON.stringify(ids), address_person: details.address_person
            })
                .then((response) => {
                    setLoader({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setURL(Request.BASE_URL + response.data.data.url)
                    } else {
                        setMessage({active: true, message: "Could not generate invoice, please retry", type: 'error'})
                    }
                })
                .catch(() => {
                    setLoader({message: "", show: false})
                    setMessage({active: true, message: "Could not generate invoice, please retry", type: 'error'})
                })
        }
    }

    const update_status = () => {
        let ids: Array<number> = []
        pendingInvoices.forEach((invoice) => {
            if (invoice.used === 1) {
                ids = [...ids, invoice.diagnosis_id]
            }
        })

        if (ids.length === 0) {
            setMessage({active: true, message: "No invoices to generate, select the checkboxes to add invoices", type: 'info'})
        } else {
            setConfirm({
                ...confirm,
                'open': true,
                'content': 'Do you want to continue marking these transactions as invoiced? ' +
                    'Please note that this cannot be reversed',
                'onConfirm': () => {
                    setConfirm({...confirm, 'open': false})
                    setLoader({message: "Generating company invoice, please wait", show: true})
                    Request.update_to_invoiced({group_id: params.company_id, ids: JSON.stringify(ids)})
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                                setPendingInvoices(pendingInvoices.filter((_invoice) => {
                                    return !ids.includes(_invoice.diagnosis_id)
                                }))
                            } else {
                                setMessage({active: true, message: "Could not update to invoiced, please retry", type: 'error'})
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                            setMessage({active: true, message: "Could not update to invoiced, please retry", type: 'error'})
                        })
                },
                'onCancel': () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    React.useEffect(() => {
        if (params.company_id > 0) {
            get_invoices()
        }
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content} centered={false}/>

            <Modal size='mini' open={details.show} dimmer="blurring" centered={false} closeOnDimmerClick={false} closeOnEscape={false}>
                <div className="p-2">
                    <div className="test_header">Address Person</div>
                    <MyInput placeholder="Enter Address Person" name="address_person" value={details.address_person} className='my-2'
                             change={(name, value) => setDetails({...details, address_person: value})}/>

                    <div className="test_header">Invoice Number</div>
                    <MyInput placeholder="Enter Invoice Number" name="invoice_no" value={details.invoice_no} className='my-2'
                             change={(name, value) => setDetails({...details, invoice_no: value})}/>

                    <div className="test_header">Tin Number</div>
                    <MyInput placeholder="Enter TIN Number" name="tin_no" value={details.tin_no} className='my-2'
                             change={(name, value) => setDetails({...details, tin_no: value})}/>

                    <div className='row m-0'>
                        <div className="col-6 px-1">
                            <Button
                                secondary icon="cancel" labelPosition="left" size="tiny" fluid
                                content="Cancel" onClick={() => setDetails({...details, show: false})}/>
                        </div>
                        <div className="col-6 px-1">
                            <Button
                                primary icon="save" labelPosition="left" size="tiny" fluid
                                onClick={generate_invoices} content="Generate Invoice"/>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-7 p-1 h-100">
                    <div className="table_container group_pending_invoices p-0">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                    <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Diagnosis Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Invoice Amount</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    pendingInvoices.map((invoice, index) =>
                                        <Table.Row key={invoice.diagnosis_id}>
                                            <Table.Cell style={{width: '50px'}} textAlign='center'>{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '50px'}} textAlign='center'>
                                                <Checkbox
                                                    label='' checked={invoice.used === 1}
                                                    onChange={(event, data) => {
                                                        setPendingInvoices(update(pendingInvoices, {
                                                            [index]: {$set: {...invoice, used: (data.checked as boolean) ? 1 : 0}}
                                                        }))
                                                    }}/>
                                            </Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{invoice.client_name}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{Utils.localise_date(invoice.date_created)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(invoice.amount_paid)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="row m-0">
                        <div className="col-4 px-1">
                            <Button
                                primary icon="refresh" labelPosition="left" size="tiny" fluid
                                content="Load Invoices" onClick={get_invoices}/>
                        </div>
                        <div className="col-4 px-1">
                            <Button
                                primary icon="print" labelPosition="left" size="tiny" fluid
                                disabled={pendingInvoices.length === 0} content="Generate Invoice"
                                onClick={() => setDetails({...details, show: true})}/>
                        </div>
                        <div className="col-4 px-1">
                            <Button
                                primary icon="save" labelPosition="left" size="tiny" fluid
                                disabled={pendingInvoices.length === 0} onClick={update_status} content="Change Status"/>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-5 p-1 h-100'>
                    {url !== "" && <iframe src={url} style={{width: '100%', height: '100%'}} frameBorder="0"/>}
                </div>
            </div>
        </>
    )
}