import React from "react";
import Utils, {Loading, MessageProps, MyDateInput, MyInput, MyMessage, MySelect, SelectData} from "../../../../utils/Utils";
import {Request} from "../../../../utils/Request";
import {ToastsStore} from "react-toasts";
import {SystemUser} from "../../../AppBar";
import firebase from "firebase/compat";
import DiagnosisDetails from "./functions/DiagnosisDetails";
import EmailResults from "./functions/EmailResults";
import CancelDiagnosis from "./functions/CancelDiagnosis";
import VirologyInfo from "./functions/VirologyInfo";
import {Button, Checkbox, Table} from "semantic-ui-react";
import UploadResults from "./functions/UploadResults";
import UpdateResults from "./functions/lab/UpdateResults";
import CSVResults from "./functions/CSVResults";
import VerifyAll from "./functions/VerifyAll";
import {Machine} from "./functions/lab/PCRMachines";
import Laboratory from "./functions/lab/Laboratory";

export interface Genes {
    gi: number
    ina: string
    it: "control" | "gene"
    rt: string
    rv: string | number
    rs: 'Positive' | 'Negative' | 'Invalid'
    co: number
}

export interface ReportTest {
    age: number
    center_name: string
    client_age: number | string
    client_contact: string | number
    client_gender: "Male" | "Female"
    client_id: number
    client_name: string
    date_created: string
    date_of_birth: string
    diagnosis_id: number
    diagnosis_no: string
    district: string
    email_address: string
    gene: string
    genes: Genes[]
    machine_id: number
    mail_status: string
    nationality: string
    passport_no: string
    result_status: sample_status
    results: "Positive" | 'Negative' | 'N/A' | 'Invalid' | 'Inconclusive'
    server: 1 | 0 //whether the document has been uploaded
    test_name: string
    test_type: string
    tested_positive: "No" | "Yes"
    testing_purpose: string
    travel_time: string
    vaccinated: string
    vaccine: string
    virology: 0 | 1
    lab_no: string
}

interface Update {
    results: "Positive" | 'Negative' | 'N/A' | 'Invalid' | 'Inconclusive'
    genes: Genes[]
    diagnosis_no: string
    machine_id: number
}

export type sample_status = "created" | 'rejected' | 'received' | 'loaded' | 'results' | 'verified' | 'uploaded'

export default function PCRTests(params: { systemUser: SystemUser, centers: Array<SelectData> }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [machines, setMachines] = React.useState(Array<Machine>())

    /*cancel results*/
    const [cancel, setCancel] = React.useState({diagnosis_id: 0})
    /*receiving results*/
    const [laboratory, setLaboratory] = React.useState({show: false})
    /*csv results*/
    const [update, setUpdate] = React.useState<Update>({diagnosis_no: "", genes: [], results: 'N/A', machine_id: 0})
    /*verifying results*/
    const [verify, setVerify] = React.useState({diagnosis_id: 0})
    /*verifying results*/
    const [verifyAll, setVerifyAll] = React.useState({show: false})
    /*csv results*/
    const [csv, setCSV] = React.useState({show: false})
    /*virology results*/
    const [virology, setVirology] = React.useState({diagnosis_id: 0})
    /*emailing results*/
    const [email, setEmail] = React.useState<{ test: ReportTest | undefined }>({test: undefined})

    const [search, setSearch] = React.useState({
        min_date: Utils.today(), max_date: Utils.today(), status: '', search_name: "",
        center_id: Request.COMPANY === 'MEDSAFE' ? params.systemUser.center.center_id :
            (params.systemUser.center.center_id === 1 ? 0 : params.systemUser.center.center_id)
    })
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }
    const [tests, setTests] = React.useState<Array<ReportTest>>([])
    const load_tests = () => {
        setLoader({message: "Loading results, please wait", show: true})
        Request.get_results_tests({
            max_date: search.max_date, min_date: search.min_date, status: search.status, center_id: search.center_id
        })
            .then((response) => {
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        setMachines(response.data.data.machines)
                        setTests(response.data.data.tests)
                        if (response.data.data.tests.length === 0) {
                            ToastsStore.info("No results found in selection")
                        }
                    } else if (response.data.code === 2) {
                        ToastsStore.error("You have no rights to update test results, logout and login again to update your user rights")
                    }
                } else {
                    ToastsStore.error("Could not get test data, please retry")
                }
                setLoader({message: "", show: false})
            })
            .catch(() => {
                ToastsStore.error("Could not get test data, please retry")
                setLoader({message: "", show: false})
            })
    }

    /*sending client results via email*/
    if (Utils.supports_fcm()) {
        const messaging = firebase.messaging();
        messaging.onMessage(function (payload) {
            const data = payload.data;
            if (data.type === 'received' || data.type === 'bounced') {
                const response = JSON.parse(data.data)
                setTests(
                    tests.map((test) => parseInt(response.diagnosis_id) === test.diagnosis_id ?
                        {...test, mail_status: response.status} : {...test}
                    )
                )
            }
        })
    }

    const [fromStart, setFromStart] = React.useState(false)
    React.useEffect(() => {
        setSearch({...search, min_date: fromStart ? '2020-06-06' : Utils.today()})
    }, [fromStart])

    React.useEffect(() => {
        load_tests()
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            {/*verification modal*/}
            <DiagnosisDetails diagnosis_id={verify.diagnosis_id} close={() => setVerify({diagnosis_id: 0})}
                              tests={tests} setTests={setTests} systemUser={params.systemUser}/>
            {/*sending email results*/}
            <EmailResults tests={tests} setTests={setTests} close={() => setEmail({test: undefined})} test={email.test}/>
            {/*cancelling a diagnosis*/}
            <CancelDiagnosis diagnosis_id={cancel.diagnosis_id} close={(success) => {
                if (success) {
                    if (search.status !== 'rejected' && search.status !== '') {
                        setTests(tests.filter((test) => test.diagnosis_id !== cancel.diagnosis_id))
                    }
                }
                setCancel({diagnosis_id: 0})
            }}/>
            {/*virology modal*/}
            <VirologyInfo close={() => setVirology({diagnosis_id: 0})} diagnosis_id={virology.diagnosis_id}/>
            {/*update results*/}
            <UpdateResults diagnosis_no={update.diagnosis_no} tests={tests} setTests={setTests} genes={update.genes} results={update.results}
                           close={() => setUpdate({genes: [], results: 'N/A', diagnosis_no: "", machine_id: 0})}
                           machines={machines} machine_id={update.machine_id}/>
            {/*csv results*/}
            <CSVResults show={csv.show} tests={tests} setTests={setTests} search={search}
                        close={(submitted) => {
                            setCSV({show: false})
                            submitted && load_tests()
                        }}/>

            {/*verify all*/}
            <VerifyAll show={verifyAll.show} tests={tests} setTests={setTests} close={() => setVerifyAll({show: false})}/>

            {/*laboratory Management*/}
            <Laboratory show={laboratory.show} tests={tests} setTests={setTests} machines={machines} setMachines={setMachines}
                        close={() => setLaboratory({show: false})}/>

            <>
                <div className="content_bar">
                    <div className="search_bar">
                        <div className='dropdown'>
                            <Button icon='ordered list' primary size='tiny'/>

                            <div className='drop_list'>
                                <div className='hide_large'>
                                    <div className='search_check'>
                                        <Checkbox
                                            label='From Beginning' checked={fromStart}
                                            onChange={(event, data) => setFromStart(data.checked as boolean)}/>
                                    </div>
                                    <label>Minimum Date</label>
                                    <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                                 change={handle_search} maxDate={Utils.today()}/>

                                    <label>Maximum Date</label>
                                    <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                                 change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                                </div>

                                <label>Center Name</label>
                                <MySelect
                                    disabled={
                                        Request.COMPANY === 'MEDSAFE' ||
                                        !Utils.has_center_role({role: "manage_centers", roles: params.systemUser.center.center_roles})
                                    }
                                    name={'center_id'} value={search.center_id} placeholder='Select a center'
                                    change={(value) => handle_search('center_id', value as number)}
                                    options={[{text: 'Show all centers', value: 0}, ...params.centers]}/>

                                <label>Result status</label>
                                <MySelect
                                    change={(value) => handle_search('status', value as string)}
                                    name="status" value={search.status} placeholder="Display all results"
                                    options={
                                        [
                                            {value: '', text: 'All results'},
                                            {value: 'created', text: 'Sample Taken'},
                                            {value: 'rejected', text: 'Rejected Samples'},
                                            {value: 'received', text: 'Lab Received'},
                                            {value: 'loaded', text: 'Lab Loaded'},
                                            {value: 'results', text: 'Lab Complete'},
                                            {value: 'verified', text: 'Admin Verified'},
                                            {value: 'uploaded', text: 'RDS Uploaded'},
                                        ]
                                    }/>

                                <label>Name, Email or Diagnosis No.</label>
                                <MyInput placeholder="Name, Email or Diagnosis No." name='search_name' value={search.search_name}
                                         change={handle_search}/>
                            </div>
                        </div>
                        <div className='search_check hide_small'>
                            <Checkbox label='From Beginning' checked={fromStart}
                                      onChange={(event, data) => setFromStart(data.checked as boolean)}/>
                        </div>

                        <div className='hide_small'>
                            <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                         change={handle_search} maxDate={Utils.today()}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                         change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                        </div>
                    </div>

                    <div className="content_buttons d-none d-md-inline-block">
                        <Button primary size='mini' icon='search' labelPosition="left" content="Search" onClick={load_tests}/>
                        {
                            params.systemUser.roles.includes("rds_sync") &&
                            <Button primary size='mini' icon='upload' labelPosition="left"
                                    content="Upload Results" onClick={() => setCSV({show: true})}/>
                        }
                        {
                            params.systemUser.roles.includes("verify_results") &&
                            <Button
                                primary size='mini' icon='check' labelPosition="left"
                                content="Verify Results" onClick={() => setVerifyAll({show: true})}/>
                        }
                        {
                            (params.systemUser.roles.includes("receive_samples") || params.systemUser.roles.includes("update_tests")) &&
                            <Button primary size='mini' icon='lab' labelPosition="left"
                                    content="Laboratory" onClick={() => setLaboratory({show: true})}/>
                        }
                    </div>

                    <div className="content_buttons d-md-none">
                        <Button primary size='mini' icon='search' onClick={load_tests}/>
                        {
                            params.systemUser.roles.includes("rds_sync") &&
                            <Button primary size='mini' icon='upload' onClick={() => setCSV({show: true})}/>
                        }
                        {
                            params.systemUser.roles.includes("verify_results") &&
                            <Button
                                primary size='mini' icon='check' onClick={() => setVerifyAll({show: true})}
                                disabled={tests.filter((test) => test.result_status === 'results').length === 0}/>
                        }
                        {
                            (params.systemUser.roles.includes("receive_samples") || params.systemUser.roles.includes("update_tests")) &&
                            <Button primary size='mini' icon='lab' onClick={() => setLaboratory({show: true})}/>
                        }
                    </div>
                </div>

                <div className="table_container">
                    <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}} textAlign="center">Actions</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Date Created</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Diagnosis No</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '70px'}}>Well No</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Test Type</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Test Status</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Travel Time</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '90px'}}>Results</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '270px'}}>C<sub>t</sub> Values</Table.HeaderCell>
                                {
                                    params.systemUser.roles.includes("verify_results") &&
                                    <>
                                        <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '120px'}}>Contact</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '250px'}}>Email Address</Table.HeaderCell>
                                    </>
                                }
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                tests
                                    .filter((test) => {
                                        return test.client_name.toLocaleLowerCase().includes(search.search_name.toLocaleLowerCase())
                                            || test.email_address.toLocaleLowerCase().includes(search.search_name.toLocaleLowerCase())
                                            || test.diagnosis_no.toLocaleLowerCase().includes(search.search_name.toLocaleLowerCase())
                                    })
                                    .map((test, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>

                                            <Table.Cell style={{width: '180px'}}>
                                                <Button
                                                    primary size='mini' icon='edit' compact
                                                    disabled={
                                                        !params.systemUser.roles.includes("update_tests") ||
                                                        !["loaded", "results"].includes(test.result_status)
                                                    }
                                                    onClick={() => setUpdate({
                                                        diagnosis_no: test.diagnosis_no, genes: test.genes,
                                                        results: test.results, machine_id: test.machine_id
                                                    })}/>
                                                <Button primary size='mini' icon='info circle' compact
                                                        disabled={!params.systemUser.roles.includes("verify_results")}
                                                        onClick={() => setVerify({diagnosis_id: test.diagnosis_id})}/>
                                                <Button primary size='mini' icon='trash' compact
                                                        disabled={
                                                            !params.systemUser.roles.includes("verify_results") ||
                                                            test.result_status != 'created'
                                                        }
                                                        onClick={() => setCancel({diagnosis_id: test.diagnosis_id})}/>
                                                <Button className={test.server === 1 ? 'server_uploaded' : 'server_missing'}
                                                        primary size='mini' icon='cloud upload' compact/>
                                                <Button
                                                    primary disabled={test.result_status !== 'uploaded'} className={test.mail_status}
                                                    size='mini' icon='mail' compact onClick={() => setEmail({test: test})}/>
                                                {/*  <Button primary size='mini' icon compact disabled={test.virology !== 1}
                                                        onClick={() => setVirology({diagnosis_id: test.diagnosis_id})}>
                                                    <Icon name='info circle'/></Button>*/}
                                            </Table.Cell>

                                            <Table.Cell style={{width: '140px'}}>{Utils.localise_date(test.date_created)}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{test.diagnosis_no}</Table.Cell>
                                            <Table.Cell style={{width: '70px'}} textAlign="center">{test.lab_no}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{test.test_name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.format_status_name(test.result_status)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{test.travel_time}</Table.Cell>
                                            <Table.Cell style={{width: '90px'}}>{test.results}</Table.Cell>
                                            <Table.Cell style={{width: '270px'}}>{test.results !== 'N/A' ? test.gene : ''}</Table.Cell>

                                            {
                                                params.systemUser.roles.includes("verify_results") &&
                                                <>
                                                    <Table.Cell style={{width: '200px'}}>{test.client_name}</Table.Cell>
                                                    <Table.Cell style={{width: '120px'}}>{test.client_contact}</Table.Cell>
                                                    <Table.Cell style={{width: '250px'}}>{test.email_address.toLowerCase()}</Table.Cell>
                                                </>
                                            }
                                        </Table.Row>
                                    )
                            }
                        </Table.Body>
                    </Table>
                </div>

                <UploadResults tests={tests} close={(_tests) => _tests !== undefined && setTests(_tests)}/>
            </>
        </>
    )
}
