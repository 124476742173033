import {Loading, MessageProps, MyMessage, MySelect, SelectData} from "../../../../../utils/Utils";
import {Button, Modal} from "semantic-ui-react";
import React from "react";
import {Request} from "../../../../../utils/Request";

export default function VirologyInfo(params: { diagnosis_id: number, close: () => void }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const initial_virology = {
        analysed_by: 0, analyser: "", igg: "", igm: "", time_analysed: "", time_verified: "", verified_by: 0, verifier: ""
    }
    const [users, setUsers] = React.useState<Array<SelectData>>([])

    const virology_results: Array<SelectData> = [
        {text: 'Pending Results', value: ''}, {text: 'Reactive', value: 'true'}, {text: 'Non Reactive', value: 'false'}
    ]

    const [virology, setVirology] = React.useState(initial_virology)
    const handle_virology = (name: string, value: string | number) => {
        setVirology({...virology, [name]: value})
    }

    const update_virology = () => {
        setLoader({message: "Updating virology data, please wait", show: true})
        Request.update_virology_data({
            analysed_by: virology.analysed_by, igg: virology.igg, igm: virology.igm,
            diagnosis_id: params.diagnosis_id, verified_by: virology.verified_by
        })
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty('code') && response.data.code == 1) {
                    setVirology({...virology, verifier: 'ok', analyser: 'ok'})
                } else {
                    setMessage({active: true, message: "Error while updating virology report", type: 'error'})
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                setMessage({active: true, message: "Error while updating virology report", type: 'error'})
            })
    }

    const print_virology = () => {
        setLoader({message: "Printing virology report, please wait", show: true})
        Request.print_virology_data({diagnosis_id: params.diagnosis_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setVirologyURL(Request.BASE_URL + response.data.data.url)
                } else {
                    setMessage({active: true, message: "Error while printing virology report", type: 'error'})
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                setMessage({active: true, message: "Error while printing virology report", type: 'error'})
            })
    }

    const [virologyURL, setVirologyURL] = React.useState("")
    const get_virology_data = (diagnosis_id: number) => {
        setLoader({message: "Loading virology data, please wait", show: true})
        Request.get_virology_data({diagnosis_id: diagnosis_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty('code') && response.data.code === 1) {
                    setVirology(response.data.data.results)
                    setUsers(response.data.data.users)
                } else {
                    setMessage({active: true, message: "Error while loading data", type: 'error'})
                    params.close()
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                setMessage({active: true, message: "Error while loading data", type: 'error'})
                params.close()
            })
    }

    React.useEffect(() => {
        params.diagnosis_id > 0 && get_virology_data(params.diagnosis_id)
    }, [params.diagnosis_id])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={params.diagnosis_id > 0} dimmer="blurring" centered={false}
                   onClose={() => setVirology(initial_virology)}>
                <div className="client_modal">
                    <div className="test_header">Test Analysed By</div>
                    <MySelect
                        change={(value) => handle_virology("analysed_by", value as number)}
                        name="analysed_by" value={virology.analysed_by} placeholder="Select analyser name" options={users}/>

                    <div className="test_header">Test Verified By</div>
                    <MySelect
                        change={(value) => handle_virology("verified_by", value as number)}
                        name="verified_by" value={virology.verified_by} placeholder="Select verifier name" options={users}/>

                    <div className="test_header">IGG Results</div>
                    <MySelect
                        change={(value) => handle_virology("igg", value as string)}
                        name="igg" value={virology.igg} placeholder="Select IGG status" options={virology_results}/>

                    <div className="test_header">IGM Results</div>
                    <MySelect
                        change={(value) => handle_virology("igm", value as string)}
                        name="igm" value={virology.igm} placeholder="Select IGM status" options={virology_results}/>

                    <div className="row mx-0 mt-3">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="print" labelPosition="left" size="tiny" fluid content="Print Report"
                                    onClick={print_virology} disabled={virology.verifier === '' || virology.analyser === ''}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                    content="Update Info" onClick={update_virology}/>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal size='small' open={virologyURL !== ""} dimmer="blurring" centered={false} onClose={() => setVirologyURL("")}>
                <div className="test_slip">
                    {
                        virologyURL !== "" &&
                        <iframe src={virologyURL} style={{width: '100%', height: '100%'}} frameBorder="0"/>
                    }
                </div>
            </Modal>
        </>
    )
}
