import React from "react";
import Utils, {MyDateInput, MyInput, MySelect, MyTimeInput, PaymentMethod, PaymentMethods, SelectData} from "../../../utils/Utils";
import {Button, Checkbox, Form, Table} from "semantic-ui-react";
import AddPayment from "../payments/AddPayment";
import {Request} from "../../../utils/Request";
import {Patient, test_types} from "./CreateTest";

export default function DiagnosisPayment(params: {
    patient: Patient, close: () => void, save_diagnosis: (proceed: 1 | 0) => void,
    setPatient: (patient: Patient) => void, saving: boolean, user_token: string
}) {
    const [payment, setPayment] = React.useState<PaymentMethod>({amount: '0', show: false, payment_type: 'cash'})

    const modify_payable = (_patient: Patient) => {
        /*calculating the amount to be paid*/
        let tire_amount = 0
        if (_patient.company) {
            const get_company_amount = () => {
                switch (_patient.test_type) {
                    case "express":
                        return _patient.group_payment.express
                    case "ordinary":
                        return _patient.group_payment.ordinary
                    case "igg_igm":
                        return _patient.group_payment.igg_igm
                    case "igg_igm_pcr":
                        return _patient.group_payment.igg_igm_pcr
                }
                return 0
            }
            tire_amount = get_company_amount()
        } else {
            _patient.payment_tiers.forEach((type) => {
                if (type.test_type === _patient.test_type) {
                    tire_amount = type.test_amount
                }
            })
        }
        if (_patient.virology) {
            tire_amount = tire_amount + params.patient.virology_amount
        }

        let amount_payable = tire_amount - _patient.discount
        if (amount_payable < 0) {
            amount_payable = 0
        }

        /*calculating if there is any other payment amount*/
        let other_amount = 0
        if (_patient.other_payments.has && Utils.is_valid_number(_patient.other_payments.amount)
            && parseFloat(_patient.other_payments.amount.toString()) > 0) {
            other_amount = parseFloat(_patient.other_payments.amount.toString())
        }

        /*getting the amount to be paid on the next payment amount*/
        let total_payable: number = (amount_payable + other_amount)
        _patient.payments.forEach((_payment) => total_payable -= _payment.amount_paid)

        setPayment({...payment, amount: total_payable.toString()})
        params.setPatient({
            ...params.patient, tier_amount: tire_amount, amount_payable: amount_payable,
            before_midnight: params.patient.purpose === 'Traveller' && params.patient.test_type === 'express'
        })
    }

    React.useEffect(() => {
        modify_payable(params.patient)
    }, [
        params.patient.other_payments.has, params.patient.other_payments.amount, params.patient.payments,
        params.patient.test_type, params.patient.company, params.patient.virology, params.patient.purpose
    ])

    const load_test_types = () => {
        let _test_types: SelectData[] = []
        if (params.patient.company) {
            Object
                .keys(params.patient.group_payment)
                .forEach((payment) => {
                    if (payment === 'express') {
                        _test_types = [..._test_types, {value: "express", text: "Express PCR Test"}]
                    } else if (payment === 'ordinary') {
                        _test_types = [..._test_types, {value: "ordinary", text: "Ordinary PCR Test"}]
                    } else if (payment === 'igg_igm_pcr') {
                        _test_types = [..._test_types, {value: "igg_igm_pcr", text: "PCR IGG/IGM Test"}]
                    } else if (payment === 'igg_igm') {
                        _test_types = [..._test_types, {value: "igg_igm", text: "IGG/IGM Test"}]
                    }
                })

        } else {
            _test_types = params
                .patient.payment_tiers
                .map((tier) => ({value: tier.test_type, text: tier.test_name}))
        }
        return _test_types
    }

    return (
        <>
            <AddPayment
                payment_type={payment.payment_type} show={payment.show} payable={payment.amount}
                close={() => setPayment({...payment, show: false})}
                save_payment={(payment_type, ref_no, account_name, amount_paid) => {
                    params.setPatient({
                        ...params.patient,
                        payments: [...params.patient.payments, {
                            payment_type: payment_type, ref_no: ref_no, account_name: account_name, amount_paid: amount_paid
                        }]
                    })
                    setPayment({...payment, show: false})
                }}/>

            <div className="test_data">
                <div className="row m-0 h-100">
                    <div className="col-12 col-md-4 p-2 h-100">
                        <div className="searched_users">
                            <div className="test_header">Client Name</div>
                            <MyInput placeholder='Client Name' name='client_name'
                                     value={`${params.patient.client_name} (+${params.patient.mobile_contact})`}/>

                            {/* <div className="test_header">Client Email address</div>
                            <MyInput placeholder='Email Address' name='email_address' value={params.patient.email_address}/>*/}

                            {
                                params.patient.group_name !== "" &&
                                <>
                                    <div className="test_header">Client Company</div>
                                    <MyInput placeholder='Discount' name='client_discount' value={params.patient.group_name}/>
                                </>
                            }
                            <div className='test_header'>Purpose of Testing</div>
                            <MySelect name='purpose' value={params.patient.purpose} placeholder='Purpose of testing'
                                      change={(value) => params.setPatient({...params.patient, purpose: value as string})}
                                      options={[
                                          {text: 'Case', value: 'Covid Case'},
                                          {text: 'Contact', value: 'Contact Person'},
                                          {text: 'Point Of Entry', value: 'Point of entry (Border regions)'},
                                          {text: 'Alert', value: 'Alert Person'},
                                          {text: 'Quarantine', value: 'Quarantine'},
                                          {text: 'Health Worker', value: 'Health Worker'},
                                          {text: 'Traveller', value: 'Traveller'},
                                          {text: 'Other reason (please describe)', value: ''},
                                      ]}/>
                            {
                                ![
                                    'Covid Case', 'Contact Person', 'Point of entry (Border regions)', 'Alert Person',
                                    'Quarantine', 'Health Worker', 'Traveller'
                                ].includes(params.patient.purpose) &&
                                <MyInput placeholder='Describe other purpose of testing' name='purpose' className='mt-2'
                                         value={params.patient.purpose}
                                         change={(name, value) => {
                                             params.setPatient({...params.patient, purpose: value});
                                         }}/>
                            }
                            {
                                params.patient.purpose === 'Traveller' && params.patient.test_type === 'express' &&
                                <>
                                    <div className='test_header'>Travel Details</div>
                                    <Checkbox
                                        label='Travelling before today midnight?' checked={params.patient.before_midnight}
                                        onChange={((event, data) => {
                                            params.setPatient({...params.patient, before_midnight: data.checked as boolean})
                                        })}/>

                                    <div className='test_header'>Travelling Time</div>
                                    <MyTimeInput
                                        value={params.patient.travel_time} name='travel_time' placeholder='Enter travel time'
                                        change={(name, value) => {
                                            params.setPatient({...params.patient, travel_time: value});
                                        }}/>
                                </>
                            }

                            <div className='test_header'>Ever tested positive for Covid-19?</div>
                            <MySelect name='tested_positive' value={params.patient.tested_positive} placeholder='Tested Positive'
                                      change={(value) => {
                                          params.setPatient({...params.patient, tested_positive: value as 'Yes' | 'No'});
                                      }}
                                      options={[{text: 'No', value: 'No'}, {text: 'Yes', value: 'Yes'}]}/>

                            <div className='row m-0'>
                                <div className='col-4 pl-0 pr-1'>
                                    <div className='test_header'>Vaccinated?</div>
                                    <MySelect name='vaccinated' value={params.patient.vaccinated} placeholder='Ever Vaccinated'
                                              change={(value) => {
                                                  params.setPatient({
                                                      ...params.patient, vaccinated: value as any,
                                                      vaccine: '', last_dose: '', vaccine_doses: 0
                                                  });
                                              }}
                                              options={[{text: 'No', value: 'No'}, {text: 'Yes', value: 'Yes'}]}/>
                                </div>
                                <div className='col-8 pl-1 pr-0'>
                                    <div className='test_header'>Vaccine Name</div>
                                    <MySelect name='vaccinated' value={params.patient.vaccine} placeholder='Select a Vaccine Name'
                                              disabled={params.patient.vaccinated === 'No'}
                                              change={(value) => params.setPatient({...params.patient, vaccine: value as any})}
                                              options={[
                                                  {text: 'AstraZeneca', value: 'AstraZeneca'},
                                                  {text: 'Johnson & Johnson', value: 'Johnson & Johnson'},
                                                  {text: 'Moderna', value: 'Moderna'},
                                                  {text: 'Pfizer', value: 'Pfizer'},
                                                  {text: 'Sinopham', value: 'Sinopham'},
                                                  {text: 'Sinovac', value: 'Sinovac'},
                                              ]}/>
                                </div>
                            </div>

                            {
                                Request.TRUCK_DRIVERS &&
                                <div className='row m-0'>
                                    <div className='col-4 pl-0 pr-1'>
                                        <div className='test_header'>Doses</div>
                                        <MySelect name='vaccine_doses' value={params.patient.vaccine_doses} placeholder='Doses'
                                                  disabled={params.patient.vaccinated === 'No'}
                                                  change={(value) => {
                                                      params.setPatient({...params.patient, vaccine_doses: value as number})
                                                  }}
                                                  options={[
                                                      {text: 'One (1)', value: 1}, {text: 'Two (2)', value: 2},
                                                      {text: 'Three (3)', value: 3}, {text: 'Four (4)', value: 4}]
                                                  }/>
                                    </div>
                                    <div className='col-8 pl-1 pr-0'>
                                        <div className='test_header'>Last Dose Date</div>
                                        <MyDateInput value={params.patient.last_dose} name='last_dose' placeholder='Last Dose'
                                                     maxDate={Utils.today()} disabled={params.patient.vaccinated === 'No'}
                                                     change={(text, value) => {
                                                         params.setPatient({...params.patient, last_dose: value})
                                                     }}/>
                                    </div>
                                    <div className="col-12 p-0">
                                        <div className='test_header'>EAC Pass ID</div>
                                        <MyInput placeholder="Enter 32 Character EAC Pass ID" name='eac_pass_id'
                                                 value={params.patient.eac_pass_id}
                                                 change={(text, value) => {
                                                     params.setPatient({...params.patient, eac_pass_id: value})
                                                 }}/>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className="col-12 col-md-4 p-2 h-100">
                        <div className="searched_users">
                            {
                                params.patient.virology !== 0 &&
                                <>
                                    <Checkbox
                                        label='Include virology test' checked={params.patient.virology === 1} className='mt-2'
                                        onChange={((event, data) => {
                                            params.setPatient({...params.patient, virology: (data.checked as boolean) ? 1 : 0})
                                        })}/>
                                </>
                            }

                            <>
                                <Checkbox
                                    label='Use payment provided by company' checked={params.patient.company}
                                    disabled={params.patient.group_id === 0} className='mt-3'
                                    onChange={((event, data) => {
                                        let checked = data.checked as boolean
                                        if (checked) {
                                            params.setPatient({
                                                ...params.patient, company: true, payment_method: params.patient.payment_method
                                            })
                                        } else {
                                            params.setPatient({...params.patient, company: false, payment_method: 'cash'})
                                        }
                                    })}/>

                                <div className="test_header mt-3">Payment Type</div>
                                <Form>
                                    <div className="row m-0">
                                        {
                                            [{value: "cash", text: "Cash Payment"}, {value: "credit", text: "Credit Payment"}]
                                                .map((item) =>
                                                    <div className="col-6 px-1 py-2" key={item.value}>
                                                        <Form.Field>
                                                            <Checkbox
                                                                radio label={item.text} name='payment_type_input' disabled={true}
                                                                value={item.value} checked={params.patient.payment_method === item.value}
                                                                onChange={(event, data) => {
                                                                    params.setPatient({
                                                                        ...params.patient, payment_method: data.value as 'cash' | 'credit'
                                                                    })
                                                                }}/>
                                                        </Form.Field>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </Form>
                            </>

                            <div className="test_header mt-3">Test Type</div>
                            <Form>
                                <div className="row m-0">
                                    {
                                        load_test_types()
                                            .map((item) =>
                                                <div className="col-12 p-1" key={item.value}>
                                                    <Form.Field>
                                                        <Checkbox
                                                            radio label={item.text} name=''
                                                            value={item.value} checked={params.patient.test_type === item.value}
                                                            onChange={(event, data) => {
                                                                params.setPatient({
                                                                    ...params.patient, test_type: data.value as test_types
                                                                })
                                                            }}/>
                                                    </Form.Field>
                                                </div>
                                            )
                                    }
                                </div>
                            </Form>

                            <div className="test_header mt-3">Extra Collections</div>
                            <Checkbox
                                label='Has extra collections'
                                checked={params.patient.other_payments.has} className='mt-3'
                                onChange={((event, data) => {
                                    params.setPatient({
                                        ...params.patient,
                                        other_payments: {...params.patient.other_payments, has: data.checked as boolean}
                                    })
                                })}/>

                            {
                                params.patient.other_payments.has &&
                                <>
                                    <div className="test_sub_header mt-3">Enter Description</div>
                                    <MyInput placeholder="Enter more description about payment" name=""
                                             value={params.patient.other_payments.description} className='mb-2'
                                             change={(name, value) => {
                                                 params.setPatient({
                                                     ...params.patient,
                                                     other_payments: {...params.patient.other_payments, description: value}
                                                 })
                                             }}/>

                                    <div className="test_sub_header mt-3">Enter Amount Collected</div>
                                    <MyInput placeholder="Enter total extra amount" name=""
                                             value={params.patient.other_payments.amount.toString()} className='mb-2'
                                             change={(name, value) => {
                                                 params.setPatient({
                                                     ...params.patient, other_payments: {...params.patient.other_payments, amount: value}
                                                 })
                                             }}/>
                                </>
                            }

                        </div>
                    </div>

                    <div className="col-12 col-md-4 p-2 h-100">
                        <div className="searched_users">
                            <div
                                className={(((params.patient.company && params.patient.payment_method !== "credit") || !params.patient.company) ? '' : ' blur') + ' assistant_payment'}>
                                <div className="row mx-0 mt-0">
                                    <div className="col-6 pl-0 pr-1">
                                        <div className="test_header">Payment Discount</div>
                                        <MyInput placeholder='Discount' name='client_discount'
                                                 value={Utils.comma_number(params.patient.discount)}/>
                                    </div>
                                    <div className="col-6 pl-1 pr-0">
                                        <div className="test_header">Payment Amount</div>
                                        <MyInput placeholder='Amount Payable' name='amount_payable'
                                                 value={Utils.comma_number(params.patient.amount_payable)}/>
                                    </div>
                                </div>
                                <div className='diagnosis_discount_reason'>
                                    {params.patient.discount_reason}
                                </div>
                                <div className="test_header mt-2">Total Money Collected</div>


                                <PaymentMethods setPayment={setPayment} payment={payment} payments={params.patient.payments}/>

                                <div className="table_container">
                                    <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                                <Table.HeaderCell style={{width: '200px'}}>Payment Information</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                (params.patient.payments)
                                                    .map((payment, index) =>
                                                        <Table.Row key={index}>
                                                            <Table.Cell style={{width: '50px'}}>
                                                                <Button
                                                                    primary size='mini' icon='trash' compact
                                                                    onClick={() => {
                                                                        let _payments: any[] = []
                                                                        params.patient.payments
                                                                            .forEach((_payment, _index) => {
                                                                                if (_index !== index) {
                                                                                    _payments = [..._payments, _payment]
                                                                                }
                                                                            })
                                                                        params.setPatient({...params.patient, payments: _payments})
                                                                    }}>
                                                                </Button>
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '200px'}}>
                                                                <div className='payment_type'>{Utils.get_method(payment.payment_type) + " Payment"}</div>
                                                                {
                                                                    payment.account_name !== '' &&
                                                                    <div className='account_info'>{payment.account_name}</div>
                                                                }
                                                                {payment.ref_no !== '' && <div className='ref_no'>{payment.ref_no}</div>}
                                                                <div className='amount'>{Utils.comma_number(payment.amount_paid)}</div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>

                            <div className="row m-0 button_data">
                                <div className="col-6 pl-0 pr-1">
                                    <Button
                                        negative icon="close" labelPosition="right" size="tiny" fluid
                                        content="Close window" disabled={params.saving} onClick={params.close}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button
                                        positive icon="save" labelPosition="left" size="tiny" fluid loading={params.saving}
                                        disabled={params.saving || params.patient.client_id === 0}
                                        onClick={() => params.save_diagnosis(0)} content="Save Diagnosis"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
