import {Button, Confirm, Form, Modal, TextArea} from "semantic-ui-react";
import {Loading, MessageProps, my_initial_confirm, MyConfirmAction, MyMessage, MySelect} from "../../../../../utils/Utils";
import React from "react";
import {Request} from "../../../../../utils/Request";
import {ToastsStore} from "react-toasts";

export default function CancelDiagnosis(params: { diagnosis_id: number, close: (success: boolean) => void }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)

    const [cancelReason, setCancelReason] = React.useState({reason: "", status: 'cancelled'})
    const delete_test = () => {
        setConfirm({
            ...confirm,
            'open': true, 'content': 'Do you want to continue deleting this test? Once deleted, this cannot be undone',
            'onConfirm': () => {
                setConfirm({...confirm, 'open': false})
                setLoader({message: "Deleting test data, please wait", show: true})

                Request.delete_test({
                    diagnosis_id: params.diagnosis_id, cancel_reason: cancelReason.reason, status: cancelReason.status
                })
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            switch (response.data.code) {
                                case 1:
                                    setMessage({active: true, type: 'success', message: "Successfully deleted test"})
                                    params.close(true)
                                    break;
                                case 2:
                                    ToastsStore.error("You have no right to delete this test")
                                    break;
                                case 3:
                                    ToastsStore.error("Test was not found")
                                    break;
                                case 4:
                                    ToastsStore.info("Test was already received by laboratory, you cannot delete it")
                                    break;
                            }
                        }
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        ToastsStore.error("Could not delete diagnosis, please retry")
                    })
            },
            'onCancel': () => setConfirm({...confirm, 'open': false}),
        })
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} centered={false}/>

            <Modal size='mini' open={params.diagnosis_id > 0} dimmer="blurring" centered={false} onClose={() => params.close(false)}>
                <div className="client_modal">
                    <div className="test_header">New state of sample</div>
                    <MySelect
                        change={(value) => setCancelReason({...cancelReason, status: value as string})}
                        name="status" value={cancelReason.status} placeholder=""
                        options={[{value: 'cancelled', text: 'Administrator Cancelled'}, {value: 'rejected', text: 'Laboratory Rejected'}]}/>

                    <div className="test_header">Reason for rejection</div>
                    <div className="user_data_container">
                        <Form className="mt-2">
                            <TextArea placeholder='Enter reason why test has been rejected' rows={6} value={cancelReason.reason}
                                      onChange={(event, data) => {
                                          setCancelReason({...cancelReason, reason: data.value as string})
                                      }}/>
                        </Form>
                    </div>


                    <div className="row mx-0 mt-3">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="trash" labelPosition="left" size="tiny" fluid content="Close"
                                    onClick={() => params.close(false)}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid content="Proceed" onClick={delete_test}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
