import {Button, Form, Modal, TextArea} from "semantic-ui-react";
import Utils, {MyInput} from "../../../utils/Utils";
import React from "react";
import {Request} from "../../../utils/Request";
import {ToastsStore} from "react-toasts";

export default function AddDiscount(params: {
    show: boolean, close: (discount: number) => void, client_name: string, client_id: number
}) {
    const [discount, setDiscount] = React.useState({amount: '', discount_reason: ''})
    const [discounting, setDiscounting] = React.useState(false)
    const add_discount = () => {
        if (!Utils.is_valid_number(discount.amount) || parseFloat(discount.amount) < 0) {
            ToastsStore.error("Enter a valid discount amount")
        } else if (discount.discount_reason.trim().length < 5) {
            ToastsStore.error("Please provide a valid reason for discount at least 5 characters")
        } else {
            setDiscounting(true)
            Request.add_client_discount({
                client_id: params.client_id, discount: parseFloat(discount.amount), discount_reason: discount.discount_reason.trim()
            })
                .then((response) => {
                    setDiscounting(false)
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        ToastsStore.success("Successfully added discount")
                        setDiscount({amount: "", discount_reason: ""})
                        params.close(parseFloat(discount.amount))
                    } else {
                        ToastsStore.error("Could not add discount, please retry")
                    }
                })
                .catch(() => {
                    setDiscounting(false)
                    ToastsStore.error("Could not add discount, please retry")
                })
        }
    }

    return (
        <Modal size='mini' open={params.show} dimmer="blurring" centered={false} onClose={() => params.close(0)}
               closeOnEscape={!discounting} closeOnDocumentClick={!discounting} closeOnDimmerClick={!discounting}>
            <div className="client_modal">
                <div className="modal_header">{params.client_name}</div>

                <div className="label">Discount Amount</div>
                <div className="user_data_container">
                    <MyInput placeholder="Enter discount amount" name="userDiscount" value={discount.amount}
                             change={(name, value) => {
                                 setDiscount({...discount, amount: value})
                             }}/>
                </div>

                <div className="label mb-1">Reason for discount</div>
                <Form className="mb-2">
                    <TextArea
                        placeholder='Enter reason for giving user discount' rows={4} value={discount.discount_reason}
                        onChange={(event, data) => {
                            setDiscount({...discount, discount_reason: data.value as string})
                        }}/>
                </Form>

                <div className="row m-0">
                    <div className="col-8 offset-2 pl-1 pr-0">
                        <Button positive icon="save" labelPosition="left" size="mini" fluid loading={discounting}
                                content="Set Discount" onClick={add_discount} disabled={discounting}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}