import React from "react";
import {Button, Checkbox, Table} from "semantic-ui-react";
import Utils, {MyInput, MySelect, SelectData} from "../../../utils/Utils";
import {Request} from "../../../utils/Request";
import {SystemUser} from "../../AppBar";
import {ToastsStore} from "react-toasts";

export interface Roles {
    [key: string]: 0 | 1
}

export interface RoleNames {
    [key: string]: { title: string, show: boolean }
}

interface User {
    user_id: number
    first_name: string
    last_name: string
    user_name: string
    login_password: string
    user_roles: string[]
    center_id: number
    email_address: string
}

export interface TestCenter {
    center_id: number
    center_name: string
    center_roles: Roles
}

export default function Users(params: { user: SystemUser, centers: Array<SelectData> }) {
    const [initialUser, setInitialUser] = React.useState<User>({
        user_id: 0, login_password: "", first_name: "", last_name: "", user_name: "",
        user_roles: [], center_id: params.user.center.center_id, email_address: ''
    })
    const [loading, setLoading] = React.useState(false)
    const [saving, setSaving] = React.useState(false)

    const [user, setUser] = React.useState(initialUser)
    const handle_change = (name: string, value: string | number) => {
        setUser({...user, [name]: value})
    }
    const [roles, setRoles] = React.useState<RoleNames>({})
    const handle_role = (name: string, checked: boolean) => {
        setUser({...user, user_roles: checked ? [...user.user_roles, name] : user.user_roles.filter((_role) => _role !== name)})
    }

    const [users, setUsers] = React.useState<Array<User>>([])
    const [search, setSearch] = React.useState({center_id: params.user.center.center_id})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [centers, setCenters] = React.useState<Array<TestCenter>>([])
    const load_centers = () => {
        setLoading(true)
        Request.get_test_center()
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setCenters(response.data.data)
                } else {
                    ToastsStore.error("Error while loading test centers")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Error while loading test centers")
            })
    }

    const load_users = () => {
        if (search.center_id === 0) {
            ToastsStore.info("First select a test center")
            return
        }
        setLoading(true)
        Request.get_users({center_id: search.center_id})
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setUsers(response.data.data)
                    if ((response.data.data as Array<any>).length === 0) {
                        ToastsStore.info("No users found")
                    }
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const save_user = () => {
        if (user.center_id === 0) {
            ToastsStore.info("Select a test center")
        } else if (Utils.invalid_name(user.first_name.trim())) {
            ToastsStore.error("Enter a valid first name")
        } else if (Utils.invalid_name(user.last_name.trim())) {
            ToastsStore.error("Enter a valid last name")
        } else if (Utils.invalid_name(user.user_name.trim())) {
            ToastsStore.error("Enter a valid username")
        } else if (user.email_address.trim().length > 0 && Utils.in_valid_email(user.email_address.trim())) {
            ToastsStore.error("Enter a valid email address")
        } else if (user.user_id === 0 && user.login_password.length < 6) {
            ToastsStore.error("Enter a password at least 6 characters")
        } else {
            setSaving(true)
            Request.save_user({
                login_password: user.login_password, first_name: user.first_name, user_id: user.user_id, last_name: user.last_name,
                user_name: user.user_name, user_roles: JSON.stringify(user.user_roles),
                center_id: user.center_id, email_address: user.email_address
            })
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (user.user_id === 0) {
                                setUser({...user, user_id: response.data.user_id})
                                setUsers([{...user, user_id: response.data.user_id}, ...users])
                            } else {
                                setUsers(users.map((_user) => _user.user_id === user.user_id ? user : _user))
                            }
                            ToastsStore.success("User has been successfully created")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Mobile contact is already in use")
                        } else if (response.data.code === 3) {
                            ToastsStore.error("Username is already in use")
                        }
                    } else {
                        ToastsStore.error("Could not save user, please retry")
                    }
                    setSaving(false)
                })
                .catch(() => {
                    ToastsStore.error("Could not save user, please retry")
                    setSaving(false)
                })
        }
    }

    const [disable, setDisable] = React.useState(false)
    React.useEffect(() => {
        if (!Utils.has_center_role({role: "manage_centers", roles: params.user.center.center_roles}) || users.length === 0) {
            setDisable(true)
        } else {
            if (Request.COMPANY === "MEDSAFE") {
                setDisable(params.user.user_id !== 1)
            } else {
                setDisable(false)
            }
        }
    }, [users, params.centers])

    React.useEffect(() => {
        let _center_roles: Roles = {}
        centers.forEach((_center) => {
            if (_center.center_id === user.center_id) {
                _center_roles = _center.center_roles
            }
        })
        let _roles: RoleNames = {
            "login": {title: "Allowed to login", show: true},
            "manage_centers": {
                title: "Manage collection centers",
                show: Utils.has_center_role({role: "manage_centers", roles: _center_roles})
            },
            "manage_users": {title: "Manage user rights and data", show: true},
            "save_client_data": {title: "Client information collection", show: true},
            "create_tests": {title: "Client PCR test creation", show: true},
            "make_payment": {title: "Client PCR payment collection", show: true},
            "receive_samples": {
                title: "Receive PCR test samples", show: Utils.has_center_role({role: "lab_testing", roles: _center_roles})
            },
            "update_tests": {
                title: "Update PCR test results", show: Utils.has_center_role({role: "lab_testing", roles: _center_roles})
            },
            "verify_results": {
                title: "Verify PCR test results", show: Utils.has_center_role({role: "lab_testing", roles: _center_roles})
            },
            "rds_sync": {
                title: "Sync PCR results to RDS", show: Utils.has_center_role({role: "lab_testing", roles: _center_roles})
            },
            "payment_reports": {title: "Make payment reports", show: true},
            "test_reports": {title: "Make testing reports", show: true},
            "manage_groups": {
                title: "Groups and company management", show: Utils.has_center_role({role: "manage_companies", roles: _center_roles})
            },
            "manage_prices": {title: "Diagnosis prices management", show: true},
            "email_results": {
                title: "Email client results", show: Utils.has_center_role({role: "lab_testing", roles: _center_roles})
            },
            "client_discounts": {title: "Add discount to clients", show: true},
            "adjust_payments": {title: "Adjust payment information", show: true},
        }

        if (Request.COMPANY === "MEDIPAL") {
            _roles = {
                ..._roles,
                "receive_igg_igm": {
                    title: "IGG/IGM sample receiving", show: Utils.has_center_role({role: "lab_testing", roles: _center_roles})
                },
                "update_igg_igm": {
                    title: "IGG/IGM results update",
                    show: Utils.has_center_role({role: "lab_testing", roles: _center_roles})
                },
                "verify_igg_igm": {
                    title: "IGG/IGM results verification",
                    show: Utils.has_center_role({role: "lab_testing", roles: _center_roles})
                },
            }
        }

        setRoles(_roles)
    }, [user.center_id, centers])

    React.useEffect(() => {
        setSearch({center_id: params.user.center.center_id})
        setInitialUser({...initialUser, center_id: params.user.center.center_id})
    }, [params.user])

    React.useEffect(() => {
        load_users()
        load_centers()
    }, [])

    return (
        <>
            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar">
                            <div style={{width: '250px'}}>
                                <MySelect
                                    disabled={disable} name={'center_id'} value={search.center_id} placeholder='Select a center'
                                    change={(value) => handle_search('center_id', value as number)}
                                    options={[...params.centers]}/>
                            </div>
                        </div>

                        <div className="content_buttons d-none d-md-inline-block">
                            <Button
                                primary size='mini' loading={loading} disabled={loading || saving}
                                icon='search' labelPosition="left" content="Search Users" onClick={load_users}/>
                        </div>

                        <div className="content_buttons d-md-none">
                            <Button primary size='mini' loading={loading} disabled={loading || saving} icon='search' onClick={load_users}/>
                        </div>
                    </div>

                    <div className="table_container user_container bg_white px-2 py-0">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Full Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>User Name</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    users.map((user, index) =>
                                        <Table.Row
                                            key={user.user_id}
                                            onClick={() => setUser(user)}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '250px'}}>{user.last_name} {user.first_name}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{user.user_name}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="app_data_form bg_white">

                        <div className="user_data_container">
                            <div className="label">Center Name</div>
                            <MySelect
                                disabled={disable} name={'center_id'} value={user.center_id} placeholder='Select a center'
                                change={(value) => handle_change('center_id', value as number)} options={[...params.centers]}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">First name</div>
                            <MyInput placeholder="Enter first name" name="first_name" value={user.first_name} change={handle_change}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Last name</div>
                            <MyInput placeholder="Enter last name" name="last_name" value={user.last_name} change={handle_change}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">User name</div>
                            <MyInput placeholder="Enter user name" name="user_name" value={user.user_name} change={handle_change}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Email address (optional)</div>
                            <MyInput placeholder="Enter email address" name="email_address" value={user.email_address} change={handle_change}/>
                        </div>

                        <div className="label">Default Password</div>
                        <div className="user_data_container">
                            <MyInput placeholder="Enter default password" name="login_password"
                                     value={user.login_password} change={handle_change}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">User Roles</div>
                            {
                                Object.entries(roles)
                                    .filter(([key, value]) => value.show)
                                    .map(([key, value]) =>
                                        <div className="diagnosis_test" key={key}>
                                            <Checkbox
                                                label={value.title} checked={user.user_roles.includes(key)}
                                                onChange={((event, data) => {
                                                    handle_role(key, data.checked as boolean)
                                                })}/>
                                        </div>
                                    )
                            }
                        </div>
                    </div>

                    <div className="app_data_button bg_white">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid disabled={saving || loading}
                                        content="Clear Data" onClick={() => setUser(initialUser)}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid loading={saving}
                                        content="Save User" onClick={save_user} disabled={saving || loading}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
