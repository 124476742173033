import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {Request} from "../../utils/Request";
import Utils, {MessageProps, MyMessage} from "../../utils/Utils";
import {useParams} from "react-router";
import axios from "axios";

export default function Password() {
    // @ts-ignore
    const {user_token} = useParams()

    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState("")
    const [credential, setLoginData] = React.useState({confirm: "", password: ""})

    const verify_account = () => {
        if (credential.password.length < 6) {
            setError("Password must be at least 6 characters")
        } else if (credential.password !== credential.confirm) {
            setError("Passwords do not match")
        } else {
            setError("")
            setLoading(true)

            Request.verify_user_account({login_token: user_token, new_password: credential.confirm})
                .then((response) => {
                    setLoading(false)
                    if (response.data.hasOwnProperty("code")) {
                        const code: number = response.data.code
                        if (code === 1) {
                            setMessage({active: true, type: "success", message: "Password updated successfully",})
                            window.setTimeout(() => window.location.href = "/", 2000)

                        } else if (code === 2) {
                            setError("Employee account not be found, please make sure that you coped the full link url")

                        } else if (code === 3) {
                            setMessage({active: true, type: "info", message: "Your account was already verified",})
                            window.setTimeout(() => window.location.href = "/", 3000)
                        }
                    } else {
                        setError("We could not verify your account, please retry")
                    }
                })
                .catch(() => {
                    setLoading(false)
                    setError("Could not verify account, please retry")
                })
        }
    }

    React.useEffect(() => {
        const set_axios_data = () => {
            axios.defaults.baseURL = Request.BASE_URL;
            axios.defaults.headers['common']['Content-Type'] = 'application/x-www-form-urlencoded';
            axios.defaults.headers['common']['Authorization'] = "Bearer " + user_token
            axios.defaults.timeout = 1000 * 60

            axios.interceptors.response.use(function (response) {
                console.log(response.data)
                console.log(response)
                return response;
            }, function (error) {
                console.log(error)
                return Promise.reject(error);
            });
        }
        set_axios_data()
    }, [])

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={true} centered={false} closeOnDimmerClick={false} closeOnEscape={false}
                   closeOnDocumentClick={false} closeOnTriggerBlur={false}>
                <div className="login_window">
                    <div className="ui large form">
                        <div className="ui stacked secondary segment">
                            <div className="login_logo">
                                <img src={Utils.get_logo()} alt=""/>
                            </div>

                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="lock icon"/>
                                    <input type="password" placeholder="Create account password" value={credential.password}
                                           onChange={(event) => {
                                               setLoginData({...credential, 'password': event.target.value})
                                           }}/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="lock icon"/>
                                    <input type="password" placeholder="Confirm account password" value={credential.confirm}
                                           onChange={(event) => {
                                               setLoginData({...credential, 'confirm': event.target.value})
                                           }}
                                           onKeyDown={(event: any) => {
                                               if (event.keyCode === 13) {
                                                   verify_account()
                                               }
                                           }}
                                    />
                                </div>
                            </div>

                            <div className="row m-0">
                                <Button className="ui button fluid primary" size='mini' loading={loading} disabled={loading}
                                        onClick={() => verify_account()} content="Update Password"/>
                            </div>
                        </div>
                        <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
