import React from "react";
import {Modal, Tab} from "semantic-ui-react";
import HandlePayment from "./HandlePayment";
import HandleInvoices from "./HandleInvoices";

export default function CompanyPayment(props: { show: boolean, company_id: number, close: () => void }) {
    const panes = [
        {
            menuItem: 'Company Payments',
            render: () => <Tab.Pane attached={false}><HandlePayment company_id={props.company_id}/></Tab.Pane>,
        },
        {
            menuItem: 'Invoice Generation',
            render: () => <Tab.Pane attached={false}><HandleInvoices company_id={props.company_id}/></Tab.Pane>,
        }
    ]

    return (
        <>
            <Modal size='large' open={props.show} centered={false} onClose={props.close} className='company_modal'
                   closeOnDocumentClick={true} closeOnEscape={true} closeOnDimmerClick={true}>
                <div className="">
                    <Tab menu={{pointing: true}} panes={panes}/>
                </div>
            </Modal>
        </>
    )
}