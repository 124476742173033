import {createBrowserHistory} from 'history'
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import root_reducer from "./reducers";

// @ts-ignore
import storage from 'redux-persist/lib/storage'
import {persistReducer, persistStore} from 'redux-persist'

export default function get_store(preloadedState?: any) {
    const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const persist_config = {key: 'root', storage,};
    const persisted_reducer = persistReducer(persist_config, root_reducer);

    let store = createStore(persisted_reducer, preloadedState, composeEnhancer(applyMiddleware(routerMiddleware(createBrowserHistory()))));
    let persistor = persistStore(store);

    return {store, persistor};
}
