import React from "react";
import {SystemUser} from "../../AppBar";
import {Request} from "../../../utils/Request";
import Utils, {Loading, MyDateInput, MyInput, MySelect, SelectData} from "../../../utils/Utils";
import {Button, Checkbox, Table} from "semantic-ui-react";
import {Group} from "./Groups";
import CompanyPayment from "./invoices/CompanyPayment";

interface Payment {
    test_amount: number
    client_id: number
    date_created: string
    diagnosis_no: string
    first_name: string
    group_name: string
    last_name: string
    payment_method: string
    payment_status: string
    payment_type: string
}

export default function CompanyReport(props: { systemUser: SystemUser, centers: Array<SelectData> }) {
    const [showInfo, setInfo] = React.useState(false)

    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [groups, setGroups] = React.useState<Array<SelectData>>([])
    const [search, setSearch] = React.useState({
        min_date: Utils.today(), max_date: Utils.today(), group_id: 0, client_name: '', payment_status: '',
        center_id: props.systemUser.center.center_id
    })
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [payments, setPayments] = React.useState(Array<Payment>())
    const [pdf, setPdf] = React.useState(false)
    const [url, setUrl] = React.useState("")

    const fetch_report = () => {
        setLoader({message: "Loading data, please wait", show: true})
        Request.get_group_report({
            client_name: search.client_name.trim(), group_id: search.group_id, center_id: search.center_id,
            max_date: search.max_date, min_date: search.min_date, payment_status: search.payment_status
        })
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("data")) {
                    setPayments(response.data.data.payments)
                    setUrl(Request.BASE_URL + response.data.data.url)
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
            })
    }

    const load_groups = () => {
        Request.get_groups({name: ""})
            .then((response) => {
                if (response.data.hasOwnProperty("data")) {
                    let _groups: Array<SelectData> = [{value: 0, text: "Select all company"}];
                    (response.data.data as Array<Group>).forEach((group) => {
                        _groups = [..._groups, {value: group.group_id, text: group.group_name}]
                    })
                    setGroups(_groups)
                }
            })
    }

    React.useEffect(() => {
        load_groups()
    }, [])

    const [fromStart, setFromStart] = React.useState(false)
    React.useEffect(() => {
        setSearch({...search, min_date: fromStart ? '2020-06-06' : Utils.today()})
    }, [fromStart])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <CompanyPayment show={showInfo} company_id={search.group_id} close={() => setInfo(false)}/>

            <>
                <div className="content_bar two_buttons">
                    <div className="search_bar">
                        <div className='dropdown'>
                            <Button icon='ordered list' primary size='tiny'/>
                            <div className='drop_list'>
                                <div className='hide_large'>
                                    <label>Company Name</label>
                                    <MySelect
                                        change={(value) => handle_search("group_id", value as number)}
                                        name="group_id" value={search.group_id} placeholder="Select company" options={groups}/>

                                    <Checkbox label='From Beginning' checked={fromStart}
                                              onChange={(event, data) => {
                                                  setFromStart(data.checked as boolean)
                                              }}/>

                                    <label>Minimum Date</label>
                                    <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                                 change={handle_search} maxDate={Utils.today()}/>

                                    <label>Maximum Date</label>
                                    <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                                 change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                                </div>
                                <label>Center Name</label>
                                <MySelect
                                    disabled={
                                        Request.COMPANY === 'MEDSAFE' ||
                                        !Utils.has_center_role({role: "manage_centers", roles: props.systemUser.center.center_roles})
                                    }
                                    name={'center_id'} value={search.center_id} placeholder='Select a center'
                                    change={(value) => handle_search('center_id', value as number)}
                                    options={[...props.centers]}/>

                                <label>Payment Mode</label>
                                <MyInput placeholder="Client Name" name='client_name'
                                         value={search.client_name} change={handle_search}/>

                                <label>Payment Mode</label>
                                <MySelect
                                    change={(value) => handle_search("payment_status", value as string)}
                                    name="payment_status" value={search.payment_status} placeholder="Select status"
                                    options={[
                                        {text: 'All Transactions', value: ''},
                                        {text: 'Pending Transactions', value: 'pending'},
                                        {text: 'Invoiced Transactions', value: 'invoiced'},
                                        {text: 'Paid Transactions', value: 'paid'}
                                    ]}/>
                            </div>
                        </div>
                        <div className='hide_small'>
                            <MySelect
                                change={(value) => handle_search("group_id", value as number)}
                                name="group_id" value={search.group_id} placeholder="Select company" options={groups}/>
                        </div>
                        <div className='search_check hide_small'>
                            <Checkbox label='From Beginning' checked={fromStart}
                                      onChange={(event, data) => {
                                          setFromStart(data.checked as boolean)
                                      }}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                         change={handle_search} maxDate={Utils.today()}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                         change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                        </div>
                    </div>

                    <div className="content_buttons d-none d-md-inline-block">
                        <div className="payment_button">
                            <Button negative size='mini' icon='search' onClick={fetch_report}/>
                        </div>

                        <div className="payment_button">
                            <Button negative size='mini' icon='file pdf' onClick={() => setPdf(true)}/>
                        </div>

                        <div className="payment_button">
                            <Button negative size='mini' icon='table' onClick={() => setPdf(false)}/>
                        </div>

                        <div className="payment_button">
                            <Button negative size='mini' icon='info circle' onClick={() => setInfo(true)}
                                    disabled={search.group_id === 0 || 1 === 1}/>
                        </div>
                    </div>

                    <div className="content_buttons d-md-none">
                        <Button negative size='mini' icon='search' onClick={fetch_report}/>

                        <Button negative size='mini' icon='file pdf' onClick={() => setPdf(true)}/>

                        <Button negative size='mini' icon='table' onClick={() => setPdf(false)}/>

                        <Button negative size='mini' icon='info circle' className="d-none" onClick={() => setInfo(true)}
                                disabled={search.group_id === 0 || 1 === 1}/>
                    </div>
                </div>

                <div className="table_container company_payments_table_container">
                    {
                        !pdf &&
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Diagnosis No</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Diagnosis Date</Table.HeaderCell>
                                    <Table.HeaderCell>Company Name</Table.HeaderCell>
                                    <Table.HeaderCell>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '110px'}}>Method</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '110px'}}>Status</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Amount Paid</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    payments
                                        .map((payment, index) =>
                                            <Table.Row key={payment.diagnosis_no}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}} textAlign="center">{payment.diagnosis_no}</Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{payment.date_created}</Table.Cell>
                                                <Table.Cell>{payment.group_name}</Table.Cell>
                                                <Table.Cell>{`${payment.last_name} ${payment.first_name}`}</Table.Cell>
                                                <Table.Cell style={{width: '110px'}}>
                                                    {payment.payment_method[0].toUpperCase() + payment.payment_method.slice(1)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '110px'}}>
                                                    {payment.payment_status[0].toUpperCase() + payment.payment_status.slice(1)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(payment.test_amount)}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    }
                    {url !== "" && pdf && <iframe src={url} style={{width: '100%', height: '100%'}} frameBorder="0"/>}
                </div>
            </>
        </>
    )
}
