import {SystemUser} from "../../AppBar";
import React from "react";
import Utils, {InputFile, Loading, MyInput, MySelect, SelectData} from "../../../utils/Utils";
import {Button, Table} from "semantic-ui-react";
import {Request} from "../../../utils/Request";
import {ToastsStore} from "react-toasts";
import AddDependant, {initial_parent, Parent} from "./AddDependant";
import AddDiscount from "./AddDiscount";
import Account from "./Account";
import CreateTest from "../diagnosis/CreateTest";
import AddPictures from "../diagnosis/AddPictures";

export interface ClientInfo {
    client_id: number

    first_name: string,
    last_name: string,
    other_name: string,
    client_gender: string,
    date_of_birth: string
    client_age: string | number
    passport_photo: InputFile

    mobile_contact: string
    email_address: string
    passport_no: string

    group_id: number
    group_name: string

    nationality: string
    village: string
    sub_county: string
    parish: string
    district: string

    next_of_kin: string
    next_of_kin_contact: string

    info: {
        tests: number
        last_date: string
        discount: number
    }
}

export const initial_client: ClientInfo = {
    client_gender: "", date_of_birth: "", district: "", email_address: "", first_name: "", last_name: "", mobile_contact: "+256",
    nationality: "", next_of_kin: "", next_of_kin_contact: "+256", group_id: 0, other_name: "", parish: "", sub_county: "", village: "",
    passport_no: "", client_id: 0, client_age: "", passport_photo: new InputFile(""), group_name: "",
    info: {tests: 0, last_date: "", discount: 0}
}

export default function Clients(params: { systemUser: SystemUser, centers: Array<SelectData>, companies: boolean, user_token: string }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})

    const [search, setSearch] = React.useState({search_name: '', center_id: params.systemUser.center.center_id})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [clients, setClients] = React.useState(Array<ClientInfo>())
    const [addDiscount, setAddDiscount] = React.useState({show: false, client_id: 0, client_name: ""})
    const [showDependant, setShowDependant] = React.useState<{ parent: Parent, show: boolean }>({parent: initial_parent, show: false})
    const [groups, setGroups] = React.useState<Array<SelectData>>([])

    const search_clients = () => {
        if (search.search_name.trim().length < 3) {
            ToastsStore.info("At least 3 characters must be inserted")
            return
        }

        setLoader({message: "Loading clients, please wait", show: true})
        Request.search_clients({center_id: search.center_id, search: search.search_name.trim()})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setClients(response.data.data.clients)
                    setGroups(response.data.data.groups)
                } else {
                    ToastsStore.error("Error while loading data, please retry")
                }
            })
            .catch(() => {
                ToastsStore.error("Error while loading data, please retry")
                setLoader({message: "", show: false})
            })
    }

    const [showCreate, setShowCreate] = React.useState<{ client_id: number, show: boolean }>({client_id: 0, show: false})

    const [showClient, setShowClient] = React.useState({client: initial_client, show: false})

    return (
        <>

            <AddDependant show={showDependant.show} systemUser={params.systemUser} groups={groups} parent={showDependant.parent}
                          close={() => setShowDependant({parent: initial_parent, show: false})}
                          create_test={() => {
                              setShowDependant({parent: initial_parent, show: false})
                          }}/>

            <AddDiscount client_id={addDiscount.client_id} show={addDiscount.show} client_name={addDiscount.client_name}
                         close={() => setAddDiscount({client_id: 0, client_name: "", show: false})}/>

            <Account systemUser={params.systemUser} groups={groups} client={showClient.client} show={showClient.show}
                     close={() => setShowClient({client: initial_client, show: false})}
                     create_test={(client_id) => {
                         setShowClient({client: initial_client, show: false})
                         setShowCreate({client_id: client_id, show: true})
                     }}/>

            <CreateTest user_token={params.user_token} show={showCreate.show} client_id={showCreate.client_id}
                        close={() => setShowCreate({client_id: 0, show: false})}/>

            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <div className="content_bar">
                <div className="search_bar">
                    <div className='dropdown hide_large'>
                        <Button icon='ordered list' primary size='tiny'/>
                        <div className='drop_list'>
                            <label>Center Name</label>
                            <MySelect
                                disabled={true} name={'center_id'} value={search.center_id} placeholder='Select a center'
                                change={(value) => handle_search('center_id', value as number)} options={params.centers}/>

                            <label>Client Name or Email Address</label>
                            <MyInput placeholder="Client name or email address" name='search_name'
                                     value={search.search_name} change={handle_search}/>
                        </div>
                    </div>

                    <div style={{width: '250px'}} className='hide_small'>
                        <MySelect
                            disabled={true} name={'center_id'} value={search.center_id} placeholder='Select a center'
                            change={(value) => handle_search('center_id', value as number)}
                            options={[{text: 'Show all centers', value: 0}, ...params.centers]}/>
                    </div>
                    <div style={{width: '250px'}} className='hide_small'>
                        <MyInput placeholder="Client name or email address" name='search_name'
                                 value={search.search_name} change={handle_search} enter={search_clients}/>
                    </div>
                </div>

                <div className="content_buttons d-none d-md-inline-block">
                    <Button primary size='mini' icon='search' labelPosition="left" content="Search Clients" onClick={search_clients}/>
                    <Button primary size='mini' icon='add' labelPosition="left" content="Add Client"
                            onClick={() => setShowClient({client: initial_client, show: true})}/>
                </div>

                <div className="content_buttons d-md-none">
                    <Button primary size='mini' icon='search' onClick={search_clients}/>
                    <Button primary size='mini' icon='add' onClick={() => setShowClient({client: initial_client, show: true})}/>
                </div>
            </div>

            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}/>
                            <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                            {params.companies && <Table.HeaderCell style={{width: '180px'}}>Company Name</Table.HeaderCell>}
                            <Table.HeaderCell style={{width: '220px'}}>Email Address</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '120px'}}>Phone Number</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}}>Gender</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}}>Discount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '60px'}} textAlign="center">Tests</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Last Test</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            clients.map((client, index) =>
                                <Table.Row key={client.client_id}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                    <Table.Cell style={{width: '150px'}}>
                                        <Button primary size='mini' icon='add' compact
                                                disabled={!params.systemUser.roles.includes("create_tests")}
                                                onClick={() => setShowCreate({client_id: client.client_id, show: true})}/>
                                        <Button primary size='mini' icon='edit' compact
                                                onClick={() => setShowClient({client: client, show: true})}/>
                                        <Button primary size='mini' icon='linkify' compact
                                                onClick={() => {
                                                    setShowDependant(
                                                        {
                                                            show: true,
                                                            parent: {
                                                                mobile_contact: client.mobile_contact, email_address: client.email_address,
                                                                village: client.village, sub_county: client.sub_county,
                                                                parish: client.parish, district: client.district,
                                                                next_of_kin: client.next_of_kin,
                                                                next_of_kin_contact: client.next_of_kin_contact
                                                            }
                                                        })
                                                }}/>
                                        <Button primary size='mini' icon='money' compact
                                                disabled={!params.systemUser.roles.includes("client_discounts")}
                                                onClick={() => setAddDiscount({
                                                    client_id: client.client_id, show: true,
                                                    client_name: `${client.last_name} ${client.first_name} ${client.other_name}`,
                                                })}/>
                                    </Table.Cell>
                                    <Table.Cell style={{width: '200px'}}>{`${client.last_name} ${client.first_name}`}</Table.Cell>
                                    {params.companies && <Table.Cell style={{width: '180px'}}>{client.group_name}</Table.Cell>}
                                    <Table.Cell style={{width: '220px'}}>{client.email_address.toLowerCase()}</Table.Cell>
                                    <Table.Cell style={{width: '120px'}}>{client.mobile_contact}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}}>{client.client_gender}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}}>{Utils.comma_number(client.info.discount)}</Table.Cell>
                                    <Table.Cell style={{width: '60px'}} textAlign="center">{client.info.tests}</Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>{Utils.localise_date(client.info.last_date)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
