import {Request} from "../../../../../../utils/Request";
import React from "react";
import Utils, {Loading, MySelect} from "../../../../../../utils/Utils";
import {Button, Checkbox, Table} from "semantic-ui-react";
import {Machine} from "./PCRMachines";
import {ToastsStore} from "react-toasts";
import {sample_status} from "../../PCRTests";

interface Results {
    lab_no: string
    result: string
    sample: string
    targets: Target[]
    status: sample_status
}

interface Target {
    cut_off: number
    id: number
    result: string
    target: string
    value: string
}

export default function UploadCSV(params: { close: () => void, machines: Machine[], setSize: () => void }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [results, setResults] = React.useState(Array<Results>())

    const [machineID, setMachineID] = React.useState(0)
    const [samples, setSamples] = React.useState(Array<String>())
    const [rowSpan, setRowSpan] = React.useState(1)

    const upload_file = (file: File) => {
        setLoader({message: "Uploading results template, please wait", show: true})
        Request.upload_csv_excel_results({machine_id: machineID, results_file: file})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        setResults(response.data.data.output)
                    } else if (response.data.code === 2) {
                        ToastsStore.error("No file selected")
                    } else if (response.data.code === 3) {
                        ToastsStore.error("Invalid file type")
                    } else if (response.data.code === 4) {
                        ToastsStore.error("Machine not found")
                    } else if (response.data.code === 5) {
                        ToastsStore.error("Machine not found")
                    }
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Error while uploading file")
            })
    }

    const save_results = () => {
        setLoader({message: "Saving results, please wait", show: true})
        Request.save_uploaded_csv_results({
            results: JSON.stringify(results.filter((result) => samples.includes(result.sample)))
        })
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        ToastsStore.success("Results updated successfully")
                    } else if (response.data.code === 2) {

                    }
                } else {
                    ToastsStore.error("Error while updating results, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Error while updating results, please retry")
            })
    }

    React.useEffect(() => {
        setRowSpan(results.length > 0 ? 2 : 1)
    }, [results])

    React.useEffect(() => {
        params.setSize()
    }, [])

    const close = () => {
        setResults([])
        setMachineID(0)
        setSamples([])
        params.close()
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <div className="modal_content">
                <div className="row mx-0" style={{marginBottom: '10px'}}>
                    <div className="col-6 col-md-4 offset-md-2 pl-0 pr-1">
                        <MySelect placeholder="Select machine name" name="machine_id" value={machineID}
                                  change={(value) => setMachineID(value as number)}
                                  options={[
                                      {text: "Select a machine", value: 0},
                                      ...params.machines.map((machine) => {
                                          return ({text: machine.machine_name + " (" + machine.machine_no + ")", value: machine.machine_id});
                                      })
                                  ]}/>
                    </div>
                    <div className="col-6 col-md-4 pl-1 pr-0">
                        {
                            machineID > 0 &&
                            <div className='csv_input'>
                                <input type="file" className="input_file" id="results_input" multiple={false} value=''
                                       accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           const results_input: HTMLInputElement | null =
                                               document.getElementById("results_input") as HTMLInputElement
                                           const files = event.target.files;
                                           if (files !== null && files.length > 0) {
                                               upload_file(files[0])
                                           }
                                           if (results_input !== null) {
                                               results_input.value = ""
                                           }
                                       }}/>
                                <label htmlFor="results_input">
                                    <i className="ui upload icon"/>
                                    <span>Select CSV/Excel File</span>
                                </label>
                            </div>
                        }
                    </div>
                </div>

                <div className='lab_form' style={{height: 'calc(100% - 90px)'}}>
                    <div className="test_header mt-0 mb-2">Upload CSV PCR Results</div>

                    <div className="received_container mt-2">
                        <div className="table_container table_100">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row key={0}>
                                        <Table.Cell style={{width: '40px'}} textAlign="center">
                                            <Checkbox
                                                checked={results.length > 0 && samples.length === results.length}
                                                onChange={(event, value) => {
                                                    setSamples((value.checked as boolean) ?
                                                        results
                                                            .filter((_result) => ['loaded', 'results'].includes(_result.status))
                                                            .map((_result) => (_result.sample)) : []
                                                    )
                                                }}/>
                                        </Table.Cell>
                                        <Table.HeaderCell style={{width: '40px'}} textAlign="center" rowSpan={rowSpan}>No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '80px'}} textAlign="center" rowSpan={rowSpan}>Well No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '140px'}} rowSpan={rowSpan}>Diagnosis No.</Table.HeaderCell>
                                        {
                                            results.length > 0 &&
                                            <>
                                                {
                                                    results[0].targets.map((target, index) =>
                                                        <Table.HeaderCell
                                                            style={{width: '140px'}} key={`${index}_${target.target}`}
                                                            colSpan={2} rowSpan={1} textAlign='center'>
                                                            {target.target}
                                                        </Table.HeaderCell>
                                                    )
                                                }
                                            </>
                                        }
                                        <Table.HeaderCell style={{width: '90px'}} rowSpan={rowSpan}>Results</Table.HeaderCell>
                                    </Table.Row>

                                    {
                                        results.length > 10000 &&
                                        <Table.Row>
                                            {
                                                results[0].targets.map((target, index) =>
                                                    <React.Fragment key={`${index}_${target.target}`}>
                                                        <Table.HeaderCell style={{width: '70px'}} rowSpan={1} textAlign="center">Value</Table.HeaderCell>
                                                        <Table.HeaderCell style={{width: '70px'}} rowSpan={1}>Result</Table.HeaderCell>
                                                    </React.Fragment>
                                                )
                                            }
                                        </Table.Row>
                                    }
                                </Table.Header>
                                <Table.Body>
                                    {
                                        results.map((result, index) =>
                                            <Table.Row key={result.lab_no}>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">
                                                    <Checkbox
                                                        className="ml-2" checked={samples.includes(result.sample)}
                                                        disabled={!['loaded', 'results'].includes(result.status)}
                                                        onChange={(event, value) => {
                                                            if (value.checked as boolean) {
                                                                setSamples([...samples, result.sample])
                                                            }
                                                            setSamples(samples.filter((sample) => sample !== result.sample))
                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '80px'}} textAlign="center">{result.lab_no}</Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{result.sample}</Table.Cell>
                                                {
                                                    result.targets.map((target) =>
                                                        <React.Fragment key={`${index}_${target.target}`}>
                                                            <Table.Cell style={{width: '70px'}} textAlign="center">
                                                                {target.value === "" ? "-" : target.value}
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '70px'}}>{target.result}</Table.Cell>
                                                        </React.Fragment>
                                                    )
                                                }
                                                <Table.Cell style={{width: '90px'}}>{result.result}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                <div className="row mx-0 p-0">
                    <div className="col-6 col-md-4 offset-md-2 pl-0 pr-1">
                        <Button negative icon="trash" labelPosition="left" size="tiny" fluid content="Close Window" onClick={close}/>
                    </div>
                    <div className="col-6 col-md-4 pl-1 pr-0">
                        <Button positive icon="save" labelPosition="left" size="tiny" fluid disabled={samples.length === 0}
                                content="Save Results" onClick={save_results}/>
                    </div>
                </div>
            </div>
        </>
    )
}
