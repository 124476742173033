import {Dispatch} from "redux";
import root_reducer from "./reducers";

export interface AppState {

}

export const initial_app: AppState = {};

/*TYPES*/
export enum Types {

}

export type StateActions = ""

/*DISPATCHING PROPS*/
export const mapStateToProps = (state: ReturnType<typeof root_reducer>) => {
    return {}
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({});

/*THESE ARE THE INTERFACES THAT ARE IMPORTED*/
export interface CombinedProps {
}
