import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from "./reportWebVitals";
import App from './components/App';
import get_store from "./store/store";

import {Provider} from 'react-redux'
import {ConnectedRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {PersistGate} from "redux-persist/integration/react";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";

import 'react-phone-input-2/lib/style.css';
import 'semantic-ui-css/semantic.min.css'

import './assets/css/bootstrap-grid.css';
import './assets/css/index.css';
import firebase from "firebase/compat";

const render = () => {
    let store_data = get_store();

    if (!firebase.apps.length) {
        firebase.initializeApp({
            apiKey: "AIzaSyA63Nl28w2HphP7Z0IEHzMWClp6zIb06lo",
            authDomain: "misc-applications.firebaseapp.com",
            databaseURL: "https://misc-applications.firebaseio.com",
            projectId: "misc-applications",
            storageBucket: "misc-applications.appspot.com",
            messagingSenderId: "797043390702",
            appId: "1:797043390702:web:3ef966e2493258eebcdbff",
            measurementId: "G-4Q91SLE1WZ"
        });
    }

    ReactDOM.render(
        <Provider store={store_data.store}>
            <PersistGate loading={null} persistor={store_data.persistor}>
                <ConnectedRouter history={createBrowserHistory()}>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER}/>
                    <App/>
                </ConnectedRouter>
            </PersistGate>
        </Provider>,
        document.getElementById('root')
    )
};
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
