import axios from "axios";
import React from "react";
import {Request} from "../../../utils/Request";
import {useParams} from "react-router";
import Utils, {Loading, MyInput} from "../../../utils/Utils";
import {Button} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";

export default function InitiatePayment() {
    // @ts-ignore
    const {user_token, client_id, amount} = useParams()

    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [payment, setPayment] = React.useState({amount: amount, reference: '', frame: ''})

    const set_axios_data = (params: { loginToken: string }) => {
        axios.defaults.baseURL = Request.BASE_URL;
        axios.defaults.headers['common']['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.defaults.headers['common']['Authorization'] = "Bearer " + params.loginToken
        axios.defaults.timeout = 1000 * 60

        axios.interceptors.response.use(function (response) {
            console.log(response.data)
            return response;
        }, function (error) {
            console.log(error)
            return Promise.reject(error);
        });
    }

    const initiate_payment = () => {
        if (!Utils.is_valid_number(payment.amount)) {
            ToastsStore.error("Enter a valid payment amount")
        } else {
            setLoader({message: "Initiating payment, please wait", show: true})
            Request.initiate_payment({client_id: parseInt(client_id), payment_amount: parseFloat(payment.amount)})
                .then((response) => {
                    setLoader({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setPayment({
                            ...payment,
                            reference: response.data.data.reference_no,
                            frame: response.data.data.frame
                        })
                    } else {
                        ToastsStore.error("Could not initiate payment, please retry")
                    }
                })
                .catch(() => {
                    setLoader({message: "", show: false})
                    ToastsStore.error("Could not initiate payment, please retry")
                })
        }
    }

    React.useEffect(() => {
        set_axios_data({loginToken: user_token})
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <div className='init_payment_window'>
                {
                    payment.reference === "" &&
                    <>
                        <div className='payment_form'>
                            <div className="test_header">Payment Amount</div>
                            <MyInput placeholder="Enter payment amount" name=""
                                     value={payment.amount} className='mb-2'
                                     change={(name, value) => setPayment({...payment, amount: value})}/>

                            <div className="row mx-0 mt-3">
                                <div className="col-8 offset-2 p-1">
                                    <Button positive icon="save" labelPosition="left" size="mini" fluid
                                            content="Initiate Payment" onClick={initiate_payment}/>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {
                    payment.frame !== "" &&
                    <>
                        <div className='reference_no'>{payment.reference}</div>
                        <div dangerouslySetInnerHTML={{__html: payment.frame}} className='frame'/>
                    </>
                }
            </div>

        </>
    )
}