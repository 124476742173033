import React from "react";
import {Button, Checkbox, Modal} from "semantic-ui-react";
import Utils, {MessageProps, MyInput, MyMessage} from "../../../utils/Utils";
import PhoneInput from "react-phone-input-2";
import {Request} from "../../../utils/Request";
import {ToastsStore} from "react-toasts";

export type payment_types = 'cash' | 'credit' | 'debit' | 'mtn' | 'airtel' | 'pesapal' | 'dtb' | 'booking'

export default function AddPayment(params: {
    payment_type: payment_types, show: boolean, close: () => void, payable: string,
    save_payment: (payment_type: payment_types, ref_no: string, account_name: string, amount_paid: number) => void
}) {
    const [payment, setPayment] = React.useState({ref_no: '', account_name: '', amount_paid: ''})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [booking, setBooking] = React.useState({ordinary: 0, express: 0, loading: false})
    const check_booking_reference = () => {
        setBooking({...booking, loading: true})
        Request.check_booking_reference({booking_ref: payment.ref_no})
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setBooking({express: response.data.data.express, loading: false, ordinary: response.data.data.loading})
                } else {
                    setBooking({...booking, loading: false})
                    ToastsStore.error("Invalid reference number")
                }
            })
            .catch(() => {
                setBooking({...booking, loading: false})
            })
    }

    const use_payment = () => {
        if (['credit', 'debit', 'mtn', 'airtel', 'dtb', 'pesapal'].includes(params.payment_type) && payment.ref_no.trim() === '') {
            setMessage({active: true, message: "Please enter valid payment reference no", type: 'error'})
        } else if (['mtn', 'airtel'].includes(params.payment_type) && Utils.invalid_contact(payment.account_name.trim())) {
            setMessage({active: true, message: "Enter a valid client contact used to make payment", type: 'error'})
        } else if (['credit', 'debit', 'dtb', 'pesapal'].includes(params.payment_type) && payment.account_name.trim() === '') {
            setMessage({active: true, message: "Enter valid credit card bank name", type: 'error'})
        } else if ((!Utils.is_valid_number(payment.amount_paid) || parseFloat(payment.amount_paid) < 0)) {
            setMessage({active: true, message: "Please enter a valid cash payment amount", type: 'error'})
        } else if (params.payment_type === 'booking' && booking.ordinary === 0 && booking.express === 0) {
            setMessage({active: true, message: "Please enter a valid booking reference number", type: 'error'})
        } else {
            params.save_payment(params.payment_type, payment.ref_no, payment.account_name, parseFloat(payment.amount_paid))
        }
    }

    React.useEffect(() => {
        setPayment({account_name: "", ref_no: "", amount_paid: params.payable})
    }, [params.payable])

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={params.show} dimmer="blurring" centered={false} onClose={params.close}
                   closeOnDimmerClick={false} closeOnEscape={false} closeOnDocumentClick={false}>
                <div className="client_modal">
                    <div className="modal_header">{Utils.get_method(params.payment_type) + " Payment"}</div>

                    {
                        params.payment_type === 'booking' &&
                        <>
                            <div className="test_header">Payment Reference</div>
                            <div className="row mx-0 mb-3">
                                <div className="col-10 pl-0 pr-1">
                                    <MyInput placeholder="Enter amount paid" name="" value={payment.ref_no}
                                             change={(name, value) => setPayment({...payment, ref_no: value})}/>
                                </div>
                                <div className="col-2  px-0">
                                    <Button
                                        negative icon="search" size="tiny" disabled={booking.loading} loading={booking.loading}
                                        onClick={check_booking_reference}/>
                                </div>
                            </div>

                           {/* <Checkbox
                                label={value.title} checked={user.user_roles.includes(key)}
                                onChange={((event, data) => {
                                    handle_role(key, data.checked as boolean)
                                })}/>*/}

                        </>
                    }

                    {
                        params.payment_type === 'cash' &&
                        <>
                            <div className="test_header">Cash Paid</div>
                            <MyInput placeholder="Enter amount paid" name="" value={payment.amount_paid} className='mb-3'
                                     change={(name, value) => setPayment({...payment, amount_paid: value})}/>
                        </>
                    }

                    {
                        (['credit', 'debit', 'dtb', 'pesapal'].includes(params.payment_type)) &&
                        <>
                            <div className="test_header">Name Of Bank</div>
                            <MyInput placeholder="Enter bank name" name="" value={payment.account_name} className='mb-2'
                                     change={(name, value) => setPayment({...payment, account_name: value})}/>

                            <div className="test_header">Payment Reference No.</div>
                            <MyInput placeholder="Enter payment reference number" name=""
                                     value={payment.ref_no} className='mb-2'
                                     change={(name, value) => setPayment({...payment, ref_no: value})}/>

                            <div className="test_header">Amount Paid</div>
                            <MyInput placeholder="Enter amount paid" name="" value={payment.amount_paid} className='mb-2'
                                     change={(name, value) => setPayment({...payment, amount_paid: value})}/>
                        </>
                    }

                    {
                        (params.payment_type === 'airtel' || params.payment_type === 'mtn') &&
                        <>
                            <div className="test_header">Client Contact Used</div>
                            <PhoneInput
                                countryCodeEditable={false} country={'ug'} value={payment.account_name}
                                onChange={(value) => setPayment({...payment, account_name: value})}/>

                            <div className="test_header">Payment Reference No.</div>
                            <MyInput placeholder="Enter payment reference number" name=""
                                     value={payment.ref_no} className='mb-2'
                                     change={(name, value) => setPayment({...payment, ref_no: value})}/>

                            <div className="test_header">Amount Paid</div>
                            <MyInput placeholder="Enter amount paid" name="" value={payment.amount_paid} className='my-2'
                                     change={(name, value) => setPayment({...payment, amount_paid: value})}/>
                        </>
                    }

                    <div className="row mx-0 mt-3">
                        <div className="col-6 p-1">
                            <Button negative icon="close" labelPosition="right" size="mini" fluid content="Cancel" onClick={params.close}/>
                        </div>
                        <div className="col-6 p-1">
                            <Button positive icon="save" labelPosition="left" size="mini"
                                    fluid content="Add Payment" onClick={use_payment}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
