import {Button, Form, Modal, TextArea} from "semantic-ui-react";
import {Loading, MessageProps, MyInput, MyMessage} from "../../../../../utils/Utils";
import React from "react";
import {Request} from "../../../../../utils/Request"
import {ReportTest} from "../PCRTests";

export default function EmailResults(
    params: { tests: ReportTest[], setTests: (tests: ReportTest[]) => void, test?: ReportTest, close: () => void },
) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [pdfFile, setPDFFile] = React.useState<File | null>(null)
    const [emailMessage, setEmailMessage] = React.useState(
        "Your Covid-19 test results and certificate is attached. <br/>Thank you for choosing Medipal International Hospital." +
        "<br/>In case of any queries, please contact Medipal International Hospital through corona@medipal.ug or +256417799900.")

    const close = () => {
        const certificate_input: HTMLInputElement | null = document.getElementById("certificate_input") as HTMLInputElement
        if (certificate_input !== null) {
            certificate_input.value = ""
        }
        params.close()
    }
    const [mail, setMail] = React.useState({client_name: "", email_address: "", diagnosis_id: 0})

    const send_mail = () => {
        setLoader({message: "Emailing client results, please wait", show: true})
        Request.send_user_certificate({
            certificate: pdfFile as File, client_name: mail.client_name, email_address: mail.email_address,
            main_body: emailMessage, diagnosis_id: mail.diagnosis_id
        })
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        params.setTests(
                            params.tests.map((test) =>
                                mail.diagnosis_id === test.diagnosis_id ? {...test, mail_status: 'pending'} : test
                            )
                        )
                        close()
                    } else if (response.data.code === 2) {
                        setMessage({active: true, message: "Could not email certificate, please retry", type: 'error'})
                    }
                } else {
                    setMessage({active: true, message: "Could not email certificate, please retry", type: 'error'})
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                setMessage({active: true, message: "Could not email certificate, please retry", type: 'error'})
            })
    }

    React.useEffect(() => {
        setPDFFile(null)
        const certificate_input: HTMLInputElement | null = document.getElementById("certificate_input") as HTMLInputElement
        if (certificate_input !== null) {
            certificate_input.value = ""
        }
    }, [mail])

    React.useEffect(() => {
        setMail({
            client_name: params.test !== undefined ? params.test.client_name : "",
            diagnosis_id: params.test !== undefined ? params.test.diagnosis_id : 0,
            email_address: params.test !== undefined ? params.test.email_address : ""
        })
    }, [params.test])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>


            <Modal size='mini' open={params.test !== undefined} dimmer="blurring" centered={false} onClose={close}>
                <div className="client_modal">
                    <div className="modal_header">{mail.client_name}</div>

                    <div className="label">Client Name</div>
                    <div className="user_data_container">
                        <MyInput placeholder="Enter client name" name="" value={mail.client_name}
                                 change={(name, value) => setMail({...mail, client_name: value})}/>
                    </div>

                    <div className="label">Client Email</div>
                    <div className="user_data_container">
                        <MyInput placeholder="Enter email address" name="" value={mail.email_address}
                                 change={(name, value) => setMail({...mail, email_address: value})}/>
                    </div>

                    <div className="label">Email Message</div>
                    <div className="user_data_container">
                        <Form className="mt-2">
                            <TextArea
                                placeholder='Enter more description' rows={6} value={emailMessage}
                                onChange={(event, data) => {
                                    setEmailMessage(data.value as string)
                                }}/>
                        </Form>
                    </div>

                    <div className="input_container">
                        <input type="file" className="input_file" id="certificate_input" accept=".pdf" multiple={false}
                               onChange={(event) => {
                                   const files = event.target.files;
                                   if (files !== null && files.length > 0) {
                                       const file: File | null = files.item(0);
                                       setPDFFile(file)
                                   }
                               }}/>
                        <label htmlFor="certificate_input" className="ui mini primary button fluid">
                            <i className="ui upload icon"/>
                            {pdfFile === null ? "Select PDF file to upload" : "File selected, click to change"}
                        </label>
                    </div>

                    <div className="row mx-0 mt-3">
                        <div className="col-8 offset-2 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="mini" fluid
                                    content="Send PDF Certificate" onClick={send_mail} disabled={pdfFile === null}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
