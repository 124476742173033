import React from "react";
import {Button, Modal} from "semantic-ui-react";
import Utils, {MyInput, MySelect} from "../../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Request} from "../../../../../utils/Request";
import {ReportTest} from "../PCRTests";

export default function UploadResults(params: { tests: Array<ReportTest>, close: (tests?: Array<ReportTest>) => void }) {
    const [modal, setModal] = React.useState<{ files: Array<File>, input: HTMLInputElement | null, show: boolean }>(
        {files: [], input: null, show: false})
    const [loading, setLoading] = React.useState(false)
    const [email, setEmail] = React.useState<{ email: 1 | 0, email_address: string }>({email: 0, email_address: ""})

    const close = (tests?: ReportTest[]) => {
        if (modal.input !== null) {
            modal.input.value = ""
        }
        setModal({files: [], input: null, show: false})
        params.close(tests)
    }

    const upload = () => {
        if (email.email_address.trim() !== "" && Utils.in_valid_email(email.email_address.trim())) {
            ToastsStore.error("Enter a valid email address")
            return
        }
        setLoading(true)
        Request.upload_certificates({certificates: modal.files, email: email.email, email_address: email.email_address.trim()})
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    ToastsStore.success("Successfully uploaded certificates")
                    let ids: Array<number> = response.data.data
                    close(
                        params.tests.map((item) => {
                            return ids.includes(item.diagnosis_id) ? {...item, mail_status: 'pending', server: 1} : item;
                        })
                    )
                } else {
                    ToastsStore.error("Error while uploading certificates, please retry")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Error while uploading certificates, please retry")
            })
    }

    React.useEffect(() => {
        setEmail({email: 0, email_address: ''})
    }, [modal.show])

    return (
        <>
            <div className='upload_results'>
                <div className="input_container">
                    <input type="file" className="input_file" id="certificates_input" accept=".pdf" multiple={true} value=''
                           onChange={(event) => {
                               const certificate_input: HTMLInputElement | null =
                                   document.getElementById("certificates_input") as HTMLInputElement

                               const files = event.target.files;
                               if (files !== null && files.length > 0) {
                                   let _files: Array<File> = []
                                   for (let index = 0; index < files.length; index++) {
                                       if (files.item(index) !== null) {
                                           _files = [..._files, files.item(index) as File]
                                       }
                                   }
                                   if (_files.length > 0) {
                                       setModal({files: _files, input: certificate_input, show: true})
                                   } else {
                                       if (certificate_input !== null) {
                                           certificate_input.value = ""
                                       }
                                   }
                               } else {
                                   if (certificate_input !== null) {
                                       certificate_input.value = ""
                                   }
                               }
                           }}/>
                    <label htmlFor="certificates_input">
                        <i className="ui upload icon"/>
                    </label>
                </div>
            </div>

            <Modal size='mini' open={modal.show} centered={false} closeOnDimmerClick={!loading}
                   closeOnDocumentClick={!loading} closeOnEscape={!loading} onClose={() => close()}>
                <div className='upload_modal'>
                    <div className='header'>Upload Certificates</div>
                    <div className='content'>
                        {`Do you want to continue uploading ${modal.files.length} PDF Certificates?`}
                    </div>

                    <div className='test_header'>Mode of Upload</div>
                    <MySelect name="email" value={email.email} placeholder="Select mail status"
                              change={(value) => setEmail({...email, email: value as 1 | 0})}
                              options={[{text: 'Upload without emailing', value: 0}, {text: 'Upload and send emails', value: 1}]}/>

                    <div className='test_header'>Single Destination Email</div>
                    <MyInput placeholder="Email Address" name="email_address" value={email.email_address}
                             change={(name, value) => setEmail({...email, email_address: value})}/>

                    <div className="row mx-0 mt-3">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="close" labelPosition="left" size="mini" fluid disabled={loading}
                                    content="Cancel Upload" onClick={() => close()}/>
                        </div>

                        <div className="col-6 pr-0 pl-1">
                            <Button positive icon="save" labelPosition="left" size="mini" fluid content="Upload Results"
                                    disabled={loading} loading={loading} onClick={upload}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
