import {Request} from "./Request";
declare var JSPM: any;

interface Receipt {
    client_age: number
    client_gender: string
    client_name: string
    date_paid: string
    diagnosis_no: string
    email_address: string
    other_payment: number
    other_payments: string
    passport_no: string
    payment_id: number
    qr_code: string
    test_amount: number
    test_type: string
    testing_purpose: string

    test_name: string
    center_name: string
    center_email: string
    box_number: string
    center_contact: string
    location: string
}

export default class JSPrinter {
    static print_receipt(receipts: Receipt[]) {
        receipts.forEach((receipt) => {
            let commands = ""
            commands += "\x1b\x40" //Initialize printer
            commands += `\x1b\x61\x01`// Select justification: Centering
            commands += "\x1b\x45\x01" //Enable bold mode
            commands += "\x1b\x21\x10\x01" //FontSize
            commands += `${receipt.center_name}\x0a`
            commands += "\x1b\x21\x00\x00" //FontSize
            commands += receipt.location
            commands += `\x0a`
            commands += `${receipt.center_contact.toString()}`
            commands += `\x0a`
            commands += receipt.box_number
            commands += `\x0a`
            commands += "\x1b\x45\x00" //Disable bold mode

            commands += `\x1b\x64\x01`
            commands += `\x1b\x61\x01`// Select justification: Centering
            commands += "\x1b\x45\x01" //Enable bold mode
            commands += "\x1b\x21\x10\x01" //FontSize
            commands += "\x1b\x2d\x02" //Enable underline
            commands += `${receipt.test_name} Payment Receipt`
            commands += "\x1b\x2d\x00" //Disable underline
            commands += "\x1b\x45\x00" //Disable bold mode
            commands += "\x1b\x21\x00\x00" //FontSize
            commands += `\x1b\x64\x01`

            const fields = [
                {title: "Client Name", text: receipt.client_name},
                {title: "Gender/Age", text: receipt.client_gender + " (" + receipt.client_age + " YRS)"},
                {title: "Email Address", text: receipt.email_address},
                {title: "Passport No.", text: receipt.passport_no},
                {title: "Diagnosis No.", text: receipt.diagnosis_no},
                {title: "Diagnosis Date", text: receipt.date_paid},
                {title: "Testing Purpose", text: receipt.testing_purpose}
            ]
            commands += `\x1b\x64\x01`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)

            commands += `\x1b\x61\x00`// Select justification: Left
            fields.forEach((field) => {
                commands += field.title
                commands += `\x09`// Horizontal Tab
                commands += "\x1b\x45\x01" //Enable bold mode
                commands += field.text
                commands += "\x1b\x45\x00" //Enable bold mode
                commands += `\x0a`
            })

            commands += `\x1b\x64\x02`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)

            commands += `\x1b\x61\x01`// Select justification: Centering
            commands += "\x1b\x45\x01" //Enable bold mode
            commands += "\x1b\x21\x20\x02" //FontSize
            commands += `UGX ${receipt.test_amount.toLocaleString()}\x0a`
            commands += "\x1b\x45\x01" //Disable bold mode
            commands += "\x1b\x21\x00\x00" //FontSize

            commands += `\x1d\x48\x02`
            commands += `\x1d\x66\x03`
            commands += `\x1d\x68\x50`
            commands += `\x1d\x6b\x04${receipt.diagnosis_no}\x00`

            commands += `\x1b\x64\x05`//Print and feed paper: Paper feeding amount = 1.13 mm (8/180 inches)
            commands += "\x1b\x69" //Execute paper full cut

            JSPrinter.print_data(commands)
        })
    }

    static print_code(diagnosis_no: string) {
        if (Request.COMPANY === "MEDIPAL") {
            let commands = "^XA"
            commands += "^BY3,6,120"
            commands += `^FO300,30^BC^FD${diagnosis_no}^FS`
            commands += "^XZ"
            JSPrinter.print_data(commands)

        } else if (Request.COMPANY === "PHARMA") {
            const command = 'SIZE 30mm,20mm \n' +
                'GAP 5mm,5mm\n' +
                'DIRECTION 0 \n' +
                'CLS \n' +
                'BARCODE 20,5,"128",100,1,0,1,2,"' + diagnosis_no + '" \n' +
                'PRINT 1\n' +
                'SOUND 1,200'
            JSPrinter.print_data(command)
        }
    }

    static print_data(commands: string) {
        JSPM.JSPrintManager
            .start()
            .then(() => {
                if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
                    const printJob = new JSPM.ClientPrintJob();
                    printJob.clientPrinter = new JSPM.UserSelectedPrinter()
                    printJob.printerCommands = commands;

                    printJob.sendToClient()
                        .catch((error: any) => {
                            console.log(error)
                        })
                } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
                    alert("JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm")
                } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
                    alert("JSPM has blocked this website")
                } else {
                    alert("Could not get printer status")
                }
            });
    }
}
