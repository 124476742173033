import React from "react";
import Utils, {Loading, MessageProps, MyInput, MyMessage, MySelect} from "../../../utils/Utils";
import {Request} from "../../../utils/Request";
import {Button, Modal, Table} from "semantic-ui-react";
import AddPayment, {payment_types} from "./AddPayment";
import cash_icon from "../../../../assets/images/payment/cash_icon.jpg";
import mtn_mm from "../../../../assets/images/payment/mtn_mm.jpg";
import airtel_mm from "../../../../assets/images/payment/airtel_mm.jpg";
import debit_card from "../../../../assets/images/payment/debit_card.png";
import credit_card from "../../../../assets/images/payment/credit_card.jpg";
import pesapal from "../../../../assets/images/payment/pesapal.png";
import dtb from "../../../../assets/images/payment/logo_dtb.jpeg";
import {SystemUser} from "../../AppBar";
import {test_types} from "../diagnosis/CreateTest";

interface Diagnosis {
    diagnosis_id: number
    diagnosis_no: string
    center_id: number
    discount: number | string
    discount_reason: string
    group_id: number
    other_payment: number | string
    other_payments: string
    payment_method: "cash" | "credit"
    test_type: test_types
    test_amount: number
    test_name: string
    client_name: string
}

interface Payment {
    account_name: string
    amount_paid: number | string
    payment_type: payment_types
    ref_no: string
}

interface Pricing {
    express_amount: number
    group_id: number
    group_name: string
    igg_igm_amount: number
    pcr_igg_igm_amount: number
    ordinary_price: number
    payment_method: "cash" | "credit"
}

export default function ModifyPayment(params: {
    user_token: string, client_id: number, payment_info_id: number, diagnosis_id: number,
    show: boolean, user: SystemUser, hide: (modified?: boolean) => void
}) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [enabled, setEnabled] = React.useState(true)

    const [diagnosis, setDiagnosis] = React.useState<Array<Diagnosis>>([])
    const [payments, setPayments] = React.useState<Array<Payment>>([])
    const [userId, setUserID] = React.useState(0)

    const [summary, setSummary] = React.useState({total_paid: 0, total_discount: 0, total_payable: 0})
    const [pricing, setPricing] = React.useState<Array<Pricing>>([])

    const load_payment_info = () => {
        setLoader({message: "Loading payment information, please wait", show: true})
        Request.get_payment_info({payment_info_id: params.payment_info_id, diagnosis_id: params.diagnosis_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setDiagnosis(response.data.data.diagnosis)
                    setPayments(response.data.data.payments)
                    setPricing(response.data.data.pricing)
                    setDiagnosisIndex(0)
                    setUserID(response.data.data.diagnosis[0].user_id)
                } else {
                    params.hide()
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                params.hide()
            })
    }

    const [diagnosisIndex, setDiagnosisIndex] = React.useState(-1)
    const handle_diagnosis = (name: string, value: string | number) => {
        setDiagnosis(diagnosis.map((_diagnosis, index) =>
            (index === diagnosisIndex) ? {..._diagnosis, [name]: value} : _diagnosis
        ))
    }

    const [addPayment, setAddPayment] = React.useState<{ amount: string, show: boolean, payment_type: payment_types }>(
        {amount: '0', show: false, payment_type: 'cash'})

    const update_payment = () => {
        setLoader({message: "Updating payment, please wait", show: true})
        Request.update_payment({
            diagnosis: JSON.stringify(diagnosis), payments: JSON.stringify(payments),
            user_id: userId, payment_info_id: params.payment_info_id
        })
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty('code') && response.data.code === 1) {
                    setMessage({active: true, message: "Successfully updated data", type: 'success'})
                    params.hide(true)
                } else {
                    setMessage({active: true, message: "Error while updating, please retry", type: 'error'})
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                setMessage({active: true, message: "Error while updating, please retry", type: 'error'})
            })
    }

    const get_amount_payable = (test_type: test_types) => {
        const company = pricing.filter((price) => price.group_id === diagnosis[diagnosisIndex].group_id)
        let test_amount = 0
        if (company.length === 1) {
            if (test_type === "ordinary") {
                test_amount = company[0].ordinary_price
            } else if (test_type === "express") {
                test_amount = company[0].express_amount
            } else if (test_type === "igg_igm") {
                test_amount = company[0].igg_igm_amount
            } else if (test_type === "igg_igm_pcr") {
                test_amount = company[0].pcr_igg_igm_amount
            }
        }
        return test_amount
    }


    React.useEffect(() => {
        if (params.show) {
            load_payment_info()
        }
    }, [params.show])

    React.useEffect(() => {
        let total_paid = 0
        let total_discount = 0
        let total_payable = 0

        diagnosis
            .forEach((diagnosis) => {
                if (diagnosis.discount.toString() !== "") {
                    if (!Utils.is_valid_number(diagnosis.discount.toString().trim())) {
                        setEnabled(false)
                        return;
                    }
                    total_discount += parseFloat(diagnosis.discount.toString().trim())
                }

                if (diagnosis.payment_method === "cash") {
                    total_payable += diagnosis.test_amount
                }
            })

        payments.forEach((payment) => {
            if (Utils.is_valid_number(payment.amount_paid.toString().trim())) {
                total_paid += parseFloat(payment.amount_paid.toString())
            }
        })

        setSummary({total_discount: total_discount, total_paid: total_paid, total_payable: total_payable})
        setEnabled((total_paid) === (total_payable - total_discount))
        setAddPayment({...addPayment, amount: (total_payable - total_paid - total_discount).toString()})
    }, [payments, diagnosis])

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <AddPayment payment_type={addPayment.payment_type} show={addPayment.show} payable={addPayment.amount}
                        close={() => setAddPayment({...addPayment, show: false})}
                        save_payment={(payment_type, ref_no, account_name, amount_paid) => {
                            setPayments([...payments, {
                                payment_type: payment_type, ref_no: ref_no, account_name: account_name, amount_paid: amount_paid
                            }])
                            setAddPayment({...addPayment, show: false})
                        }}/>

            <Modal size='small' open={params.show} dimmer="blurring" centered={true} closeOnDimmerClick={false}
                   closeOnDocumentClick={false} closeOnEscape={false} onClose={() => params.hide()}>
                <div className='modify_payments_container'>
                    <div className="modify_payments">
                        {/*clients container*/}
                        <div className="table_container">
                            <Table celled striped compact size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '40px'}} textAlign="center"/>
                                        <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '120px'}}>Diagnosis No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '100px'}}>Test Name</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        diagnosis
                                            .map((diagnosis, index) =>
                                                <Table.Row key={diagnosis.diagnosis_no}
                                                           onClick={() => setDiagnosisIndex(index)}>
                                                    <Table.Cell style={{width: '40px'}}>{Utils.row_number(index)}</Table.Cell>
                                                    <Table.Cell style={{width: '200px'}}>{diagnosis.client_name}</Table.Cell>
                                                    <Table.Cell style={{width: '120px'}}>{diagnosis.diagnosis_no}</Table.Cell>
                                                    <Table.Cell style={{width: '100px'}}>{diagnosis.test_name}</Table.Cell>
                                                </Table.Row>
                                            )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                        {/*payments form*/}
                        {
                            diagnosisIndex >= 0 &&
                            <div className="row m-0">
                                {/*group information*/}
                                <div className="col-12 col-md-6 p-1">
                                    <div className="test_header mb-1">Payment Company</div>
                                    <MySelect
                                        change={(value) => {
                                            pricing.forEach((_pricing) => {
                                                if (_pricing.group_id === value as number) {
                                                    setDiagnosis(
                                                        diagnosis.map((_diagnosis, index) =>
                                                            index === diagnosisIndex ? {
                                                                ..._diagnosis, group_id: _pricing.group_id, discount: "",
                                                                discount_reason: '', test_type: "ordinary",
                                                                payment_method: _pricing.payment_method,
                                                                test_amount: get_amount_payable("ordinary")
                                                            } : {..._diagnosis}
                                                        ))
                                                }
                                            })
                                        }}
                                        name="group_id" value={diagnosis[diagnosisIndex].group_id} placeholder="Select payment company"
                                        options={pricing.map((pricing) => ({text: pricing.group_name, value: pricing.group_id}))}/>
                                </div>

                                <div className="col-12 col-md-6 p-1">
                                    <div className='row m-0'>
                                        <div className='col-6 pl-0 pr-1'>
                                            <div className="test_header mt-3 mb-1">Payment method</div>
                                            <MySelect
                                                change={(value) => handle_diagnosis('payment_method', value as string)}
                                                name="payment_method" value={diagnosis[diagnosisIndex].payment_method}
                                                placeholder="Select payment method"
                                                options={[{value: "cash", text: "Cash Payment"}, {value: "credit", text: "Credit Payment"}]}/>
                                        </div>

                                        <div className='col-6 pl-1 pr-0'>
                                            <div className="test_header mt-3 mb-1">Test Type</div>
                                            <MySelect
                                                change={(value) => {
                                                    const test_type = value as test_types
                                                    setDiagnosis(
                                                        diagnosis.map((_diagnosis, index) =>
                                                            index === diagnosisIndex ? {
                                                                ..._diagnosis,
                                                                test_amount: get_amount_payable(test_type), test_type: test_type
                                                            } : {..._diagnosis}
                                                        ))
                                                }}
                                                name="test_type" value={diagnosis[diagnosisIndex].test_type} placeholder="Select test type"
                                                options={[
                                                    {value: "express", text: "Express PCR test"},
                                                    {value: "ordinary", text: "Ordinary PCR Test"},
                                                    {value: "igg_igm", text: "IGG/IGM Test"},
                                                    {value: "igg_igm_pcr", text: "IGG/IGM & PCR Test"}
                                                ]}/>
                                        </div>
                                    </div>
                                </div>

                                {/*DISCOUNT INFORMATION*/}
                                <div className="col-12 col-md-6 p-1">
                                    <div className="test_header mt-3">Discount received</div>
                                    <div className='row m-0'>
                                        <div className='col-4 pl-0 pr-1'>
                                            <MyInput placeholder="Amount" name="discount" className='mb-2'
                                                     value={diagnosis[diagnosisIndex].discount.toString()} change={handle_diagnosis}/>
                                        </div>
                                        <div className='col-8 pl-1 pr-0'>
                                            <MyInput placeholder="Enter discount reason" name="discount_reason"
                                                     disabled={!Utils.is_valid_number(diagnosis[diagnosisIndex].discount)}
                                                     value={diagnosis[diagnosisIndex].discount_reason} change={handle_diagnosis}/>
                                        </div>
                                    </div>
                                </div>

                                {/*OTHER PAYMENTS*/}
                                <div className="col-12 col-md-6 p-1">
                                    <div className="test_header mt-3">Other Payment</div>
                                    <div className='row m-0'>
                                        <div className='col-4 pl-0 pr-1'>
                                            <MyInput placeholder="Amount" name="other_payment" change={handle_diagnosis}
                                                     value={diagnosis[diagnosisIndex].other_payment as string}/>
                                        </div>
                                        <div className='col-8 pl-1 pr-0'>
                                            <MyInput placeholder="Enter payment description" name="other_payments" className='mb-2'
                                                     value={diagnosis[diagnosisIndex].other_payments} change={handle_diagnosis}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/*payments reception*/}
                        <div className="test_header">Payments Received</div>
                        <div className='payment_methods'>
                            <div onClick={() => setAddPayment({...addPayment, show: true, payment_type: 'cash'})}>
                                <img src={cash_icon} alt=''/>
                            </div>
                            <div onClick={() => setAddPayment({...addPayment, show: true, payment_type: 'airtel'})}>
                                <img src={airtel_mm} alt=''/>
                            </div>
                            <div onClick={() => setAddPayment({...addPayment, show: true, payment_type: 'mtn'})}>
                                <img src={mtn_mm} alt=''/>
                            </div>
                            <div onClick={() => setAddPayment({...addPayment, show: true, payment_type: 'debit'})}>
                                <img src={debit_card} alt=''/>
                            </div>
                            <div onClick={() => setAddPayment({...addPayment, show: true, payment_type: 'credit'})}>
                                <img src={credit_card} alt=''/>
                            </div>
                            <div onClick={() => setAddPayment({...addPayment, show: true, payment_type: 'dtb'})}>
                                <img src={dtb} alt=''/>
                            </div>
                            <div onClick={() => setAddPayment({...addPayment, show: true, payment_type: 'pesapal'})}>
                                <img src={pesapal} alt=''/>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-12 col-md-7 p-1">
                                <div className="table_container">
                                    <Table celled striped compact size='small' inverted color='grey' selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                                <Table.HeaderCell>Payment Information</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                payments
                                                    .map((payment, index) =>
                                                        <Table.Row key={index}>
                                                            <Table.Cell style={{width: '50px'}}>
                                                                <Button
                                                                    primary size='mini' icon='trash' compact
                                                                    onClick={() => {
                                                                        setPayments(payments.filter((_p, _i) => _i !== index))
                                                                    }}>
                                                                </Button>
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <div className='payment_type'>
                                                                    {Utils.get_method(payment.payment_type) + " Payment"}
                                                                </div>
                                                                {
                                                                    payment.account_name !== '' &&
                                                                    <div className='account_info'>{payment.account_name}</div>
                                                                }
                                                                {payment.ref_no !== '' && <div className='ref_no'>{payment.ref_no}</div>}
                                                                <div className='amount'>{Utils.comma_number(payment.amount_paid)}</div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>

                            <div className="col-12 col-md-5 p-1">
                                <div className="row m-0">
                                    <div className="col-5 p-1">
                                        <div className="test_header m-0">Total</div>
                                    </div>
                                    <div className="col-7 p-1">
                                        <div className='modify_payments_money'>
                                            {Utils.comma_number(summary.total_payable - summary.total_discount)}
                                        </div>
                                    </div>
                                </div>

                                <div className="row m-0">
                                    <div className="col-5 p-1">
                                        <div className="test_header m-0">Received</div>
                                    </div>
                                    <div className="col-7 p-1">
                                        <div className='modify_payments_money'>{Utils.comma_number(summary.total_paid)}</div>
                                    </div>
                                </div>

                                <div className="row m-0">
                                    <div className="col-5 p-1">
                                        <div className="test_header m-0">Balance</div>
                                    </div>
                                    <div className="col-7 p-1">
                                        <div className='modify_payments_money'>
                                            {Utils.comma_number(summary.total_payable - summary.total_paid - summary.total_discount)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 my-3">
                    <div className="col-6 col-md-3 offset-md-3 pl-0 pr-1">
                        <Button negative icon="close" labelPosition="left" size="mini" fluid
                                content="Cancel Update" onClick={() => params.hide()}/>
                    </div>

                    <div className="col-6 col-md-3 pr-0 pl-1">
                        <Button positive icon="save" labelPosition="left" size="mini" fluid content="Update Payment"
                                disabled={!enabled} onClick={update_payment}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}
