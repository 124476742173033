import React from "react";
import {Button, Checkbox, Form, Modal, Table, TextArea} from "semantic-ui-react";
import Utils, {MessageProps, MyInput, MyMessage, MySelect} from "../../../utils/Utils";
import {Request} from "../../../utils/Request";
import update from "react-addons-update";

export interface PaymentTier {
    tier_id: number
    tier_description: string
    test_type: 'ordinary' | 'express'
    test_amount: number | string
}

const payment_tier: PaymentTier = {test_amount: "", test_type: "ordinary", tier_description: "", tier_id: 0}

export default function PricingData(props: { show: boolean, close: () => void }) {
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [loading, setLoading] = React.useState(false)
    const [saving, setSaving] = React.useState(false)

    const [paymentCategories, setPaymentCategories] = React.useState<Array<PaymentTier>>([])
    const [paymentTier, setPaymentTier] = React.useState(payment_tier)
    const handle_change = (name: string, value: string) => {
        setPaymentTier({...paymentTier, [name]: value})
    }

    const save_tier = () => {
        if (!Utils.is_valid_number(paymentTier.test_amount as string)) {
            setMessage({active: true, message: "Insert a valid test amount", type: 'error'})
        } else {
            setSaving(true)
            Request.save_pricing(paymentTier)
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (paymentTier.tier_id === 0) {
                                const _paymentTier: PaymentTier = {...paymentTier, tier_id: response.data.tier_id}
                                setPaymentTier(_paymentTier)
                                setPaymentCategories([_paymentTier, ...paymentCategories])
                            } else {
                                for (let index = 0; index < paymentCategories.length; index++) {
                                    if (paymentCategories[index].tier_id === paymentTier.tier_id) {
                                        setPaymentCategories(update(paymentCategories, {[index]: {$set: paymentTier}}))
                                        break
                                    }
                                }
                            }
                            setMessage({active: true, message: "Successfully saved price group", type: 'success'})
                        } else if (response.data.code === 2) {
                            setMessage({active: true, message: response.data.message, type: 'info'})
                        }
                    } else {
                        setMessage({active: true, message: "Could not save payment tier, please retry", type: 'error'})
                    }
                })
                .catch(() => {
                    setSaving(false)
                    setMessage({active: true, message: "Could not save payment tier, please retry", type: 'error'})
                })
        }
    }

    const get_payment_categories = () => {
        setLoading(true)
        Request.get_pricing_tier()
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("data")) {
                    setPaymentCategories(response.data.data)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const get_test_type = (test_type: string) => {
        if (test_type === 'ordinary') {
            return 'Ordinary Test'
        } else if (test_type === 'express') {
            return 'Express Test'
        }
        return test_type
    }

    React.useEffect(() => {
        get_payment_categories()
    }, [])

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={props.show} dimmer="blurring" centered={false} onClose={props.close}>
                <div className="pricing_container">
                    <div className="table_container bg_white p-2">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Test Type</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Payment Amount</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    paymentCategories.map((tier, index) =>
                                        <Table.Row key={tier.tier_id} onClick={() => setPaymentTier(tier)}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{get_test_type(tier.test_type)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.comma_number(tier.test_amount as number)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="app_data_form bg_white">
                        <div className="label mb-2">Payment Amount</div>
                        <div className="user_data_container">
                            <MyInput placeholder="Enter pricing" name="test_amount"
                                     value={paymentTier.test_amount as string} change={handle_change}/>
                        </div>

                        <div className="label mt-3 mb-2">Test Type</div>
                        <div className='row m-0'>
                            <div className='col-6 p-0'>
                                <Checkbox label='Ordinary Test' value={paymentTier.test_type}
                                          checked={paymentTier.test_type === 'ordinary'}
                                          onChange={(event, data) => {
                                              handle_change("test_type", (data.checked as boolean) ? 'ordinary' : 'express')
                                          }}/>
                            </div>
                            <div className='col-6 p-0'>
                                <Checkbox label='Express Test' value={paymentTier.test_type}
                                          checked={paymentTier.test_type === 'express'}
                                          onChange={(event, data) => {
                                              handle_change("test_type", (data.checked as boolean) ? 'express' : 'ordinary')
                                          }}/>
                            </div>
                        </div>

                        <div className="label mt-3 mb-1 d-none">Price Description</div>
                        <div className="user_data_container d-none">
                            <Form className="mt-2">
                                <TextArea
                                    placeholder='Enter prices description' rows={2}
                                    value={paymentTier.tier_description}
                                    onChange={(event, data) => {
                                        handle_change("tier_description", data.value as string)
                                    }}/>
                            </Form>
                        </div>

                    </div>

                    <div className="app_data_button bg_white">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                        disabled={saving || loading}
                                        content="Clear Data" onClick={() => setPaymentTier(payment_tier)}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid loading={saving}
                                        content="Save Data" onClick={save_tier} disabled={saving || loading}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}