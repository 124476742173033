import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import {AppState, initial_app, StateActions} from "./types";
import {createBrowserHistory} from "history";

function system_reducer(state = initial_app, action: StateActions): AppState {
    return state
}

const root_reducer = combineReducers({
    router: connectRouter(createBrowserHistory()),
    parent: system_reducer,
    students: system_reducer,
    subscriptions: system_reducer,
    resources: system_reducer,
});

export default root_reducer;