import {Button, Modal} from "semantic-ui-react";
import React from "react";
import {Loading, WebCamView} from "../../../utils/Utils";
import {Request} from "../../../utils/Request";
import {ToastsStore} from "react-toasts";

export default function AddPictures(params: { show: boolean, diagnosis_id: number, close: () => void, diagnosis_no: string }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})

    const [image, setImage] = React.useState<string | null>(null)
    const [tube, setTube] = React.useState<string | null>(null)

    const save_image = () => {
        setLoader({message: "Uploading diagnosis proof, please wait", show: true})
        Request.prove_diagnosis({
            client: image as string, diagnosis_id: params.diagnosis_id, diagnosis_no: params.diagnosis_no, tube: tube as string
        })
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    ToastsStore.success("Diagnosis images saved successfully")
                    params.close()
                } else {
                    ToastsStore.error("Could not save diagnosis proof, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not save diagnosis proof, please retry")
            })
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Modal size='small' open={params.show} centered={false}
                   closeOnDimmerClick={false} closeOnDocumentClick={false} closeOnEscape={false}>
                <div className="capture_image_modal">
                    <div className="image_form">
                        <div className="row m-0">
                            <div className="col-12 px-1 mb-2">
                                <div className="test_header my-0">
                                    Take pictures to prove that samples have been collected from Health Center
                                </div>
                            </div>
                            <div className="col-12 col-md-6 px-1">
                                <div className="test_header my-0">Client Picture</div>
                                <WebCamView setImage={setImage}/>
                            </div>
                            <div className="col-12 col-md-6 px-1">
                                <div className="test_header my-0">Sample Collection Tube Picture</div>
                                <WebCamView setImage={setTube}/>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 button_data">
                        <div className="col-6 offset-3 pl-0 pr-0">
                            <Button
                                positive icon="save" labelPosition="left" size="tiny" fluid disabled={image === null || tube === null}
                                onClick={save_image} content="Upload Images"/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
