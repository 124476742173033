import {Button, Checkbox, Form, Modal, Table} from "semantic-ui-react";
import React from "react";
import Utils, {Loading, MessageProps, MyInput, MyMessage, MySelect} from "../../../../../../utils/Utils";
import {Request} from "../../../../../../utils/Request";
import {ToastsStore} from "react-toasts";

export interface Machine {
    machine_id: number
    machine_name: string
    machine_no: string
    kit_name: string
    genes: Gene[]
    interpretations: Interpretation[]
}

interface Gene {
    gene_control_id: number
    item_type: 'gene' | 'control'
    item_name: string
    threshold: number | string
}

export interface GeneValues {
    [key: string]: 'Positive' | 'Negative' | 'Invalid'
}

export interface Interpretation {
    rs: 'Positive' | 'Negative' | 'Invalid' | 'Inconclusive'
    genes: GeneValues
}

const initial_machine: Machine = {genes: [], kit_name: "", machine_id: 0, machine_name: "", machine_no: "", interpretations: []}
const initial_gene: Gene = {gene_control_id: 0, item_name: "", item_type: 'gene', threshold: ''}

export default function PCRMachines(params: {
    close: () => void, setSize: () => void, machines: Machine[], setMachines: (machines: Machine[]) => void,
}) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [showInterpretation, setShowInterpretation] = React.useState(false)

    const [machines, setMachines] = React.useState(Array<Machine>())
    const [machine, setMachine] = React.useState(initial_machine)
    const handle_machine = (name: string, value: string | Gene[] | Interpretation[]) => {
        setMachine({...machine, [name]: value})
    }

    const load_machines = () => {
        setLoader({message: "Getting machines", show: true})
        Request.get_machines()
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    params.setMachines(response.data.data)
                } else {
                    ToastsStore.error("Could not load machines, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not load machines, please retry")
            })
    }

    const save_machine = () => {
        if (machine.machine_name.trim().length < 5) {
            ToastsStore.error("Machine name must at least be 5 characters")
        } else if (machine.machine_no.trim().length === 0) {
            ToastsStore.error("Machine number cannot be left blank")
        } else if (machine.genes.length < 2) {
            ToastsStore.error("Enter at least 2 kit genes")
        } else if (machine.genes.filter((gene) => gene.item_type === "gene").length === 0) {
            ToastsStore.error("At least 1 non control must be specified")
        } else if (machine.genes.filter((gene) => gene.item_type === "control").length === 0) {
            ToastsStore.error("At least 1 control must be specified")
        } else {
            let has_error = false
            for (let index = 0; index < machine.genes.length && !has_error; index++) {
                const threshold = machine.genes[index].threshold.toString().trim();
                if (machine.genes[index].item_name.trim().length === 0) {
                    ToastsStore.error(`Gene or Control name at "row ${index + 1}" must be "AT LEAST 1 CHARACTERS"`)
                    has_error = true
                } else if (!Utils.is_valid_number(threshold) || parseFloat(threshold) < 0 || parseFloat(threshold) > 100) {
                    ToastsStore.error(`Threshold at "row ${index + 1}" must be between "0 OR 100"`)
                    has_error = true
                }
            }

            if (!has_error) {
                setLoader({message: "Saving machine please wait", show: true})
                Request.save_machine({
                    genes: JSON.stringify(machine.genes), kit_name: machine.kit_name, machine_id: machine.machine_id,
                    machine_name: machine.machine_name.trim(), machine_no: machine.machine_no.trim()
                })
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                if (machine.machine_id === 0) {
                                    params.setMachines([response.data.data, ...params.machines])
                                } else {
                                    params.setMachines(
                                        params.machines.map((_machine) =>
                                            _machine.machine_id === machine.machine_id ? response.data.data : _machine
                                        )
                                    )
                                }
                                setMachine(response.data.data)
                                ToastsStore.success("Successfully saved machine information")
                            } else if (response.data.code === 2) {
                                ToastsStore.error("Serial/ID Number is already in use by another machine")
                            }
                        } else {
                            ToastsStore.error("Could not save machine, please retry")
                        }
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        ToastsStore.error("Could not save machine, please retry")
                    })
            }
        }
    }

    const save_interpretations = () => {
        setLoader({message: "Saving machine interpretations please wait", show: true})
        Request.save_machine_interpretations({
            interpretations: JSON.stringify(machine.interpretations), machine_id: machine.machine_id
        })
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    params.setMachines(
                        params.machines.map((_machine) =>
                            _machine.machine_id === machine.machine_id ? response.data.data : _machine
                        )
                    )
                    setMachine(response.data.data)
                    ToastsStore.success("Successfully saved machine interpretations")
                } else {
                    ToastsStore.error("Could not save machine interpretations, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not save machine interpretations, please retry")
            })
    }

    const update_interpretation = (interpretation: Interpretation, index: number) => {
        setMachine({
            ...machine,
            interpretations: machine.interpretations.map((_int, _index) => _index === index ? interpretation : _int)
        })
    }

    React.useEffect(() => {
        setMachines(params.machines)
    }, [params.machines])

    React.useEffect(() => {
        params.setSize()
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <div className="modal_content">
                <div className="row m-0 h-100">
                    <div className="col-12 col-md-6 h-100 p-1">
                        <div className="content_bar">
                            <div className="search_bar"/>
                            <div className="content_buttons">
                                <Button primary size='mini' icon='add' labelPosition="left" content="Add New"
                                        onClick={() => setMachine(initial_machine)}/>

                                <Button primary size='mini' icon='search' labelPosition="left" content="Load"
                                        onClick={load_machines}/>
                            </div>
                        </div>

                        <div className="table_container">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '180px'}}>Machine Name</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        machines.map((machine, index) =>
                                            <Table.Row key={machine.machine_id} onClick={() => setMachine(machine)}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{machine.machine_name}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 p-1 h-100">
                        <div className='targets_form'>
                            <div className="test_header mt-0">Machine Information</div>

                            {/*machine name*/}
                            <div className="label">Machine Name</div>
                            <div className="user_data_container">
                                <MyInput placeholder="Enter machine name" name="machine_name"
                                         value={machine.machine_name} change={handle_machine}/>
                            </div>

                            {/*serial number*/}
                            <div className="label">Serial/ID No</div>
                            <div className="user_data_container">
                                <MyInput placeholder="Enter unique number" name="machine_no"
                                         value={machine.machine_no} change={handle_machine}/>
                            </div>

                            {/*kit name*/}
                            <div className="label">Machine Kit Name</div>
                            <div className="user_data_container">
                                <MyInput placeholder="Enter kit name" name="kit_name" value={machine.kit_name} change={handle_machine}/>
                            </div>

                            <div style={{display: "flex", flexDirection: 'row', marginBottom: '5px',}}>
                                <div style={{flex: 1, marginRight: '10px'}}>
                                    <div className="test_header mt-0">Kit Genes & Controls</div>
                                </div>
                                <div>
                                    <Button primary size='mini' icon='add' compact
                                            onClick={() => handle_machine('genes', [initial_gene, ...machine.genes])}/>

                                    <Button primary size='mini' icon='list alternate' compact
                                            disabled={
                                                machine.machine_id === 0 ||
                                                machine.genes.filter((gene) => gene.gene_control_id === 0).length > 0
                                            }
                                            onClick={() => setShowInterpretation(true)}/>
                                </div>
                            </div>

                            <div className="table_container">
                                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '40px'}} textAlign="center"/>
                                            <Table.HeaderCell style={{width: '110px'}}>Item Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '100px'}}>Type</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '70px'}}>Cut Off</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            machine.genes.map((gene, index) =>
                                                <Table.Row key={index}>
                                                    <Table.Cell style={{width: '40px'}}>
                                                        <Button primary size='mini' icon='trash' compact disabled={gene.gene_control_id > 0}
                                                                onClick={() =>
                                                                    handle_machine('genes',
                                                                        machine.genes.filter((_, _index) => _index !== index))
                                                                }/>
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '110px'}}>
                                                        <MyInput placeholder="" name={`item_name_${index}`} value={gene.item_name}
                                                                 change={(name, value) =>
                                                                     handle_machine('genes',
                                                                         machine.genes.map((_gene, _index) =>
                                                                             _index === index ? {..._gene, item_name: value} : {..._gene}
                                                                         )
                                                                     )
                                                                 }/>
                                                    </Table.Cell>

                                                    <Table.Cell style={{width: '100px'}}>
                                                        <Form>
                                                            <Form.Field>
                                                                <Checkbox
                                                                    label='Control' name={`item_type${index}`} value={0}
                                                                    checked={gene.item_type === 'control'}
                                                                    onChange={((event, data) => {
                                                                        const _x: Gene[] = machine.genes.map((_gene, _index) =>
                                                                            _index === index ?
                                                                                {..._gene, item_type: (data.checked as boolean) ? 'control' : 'gene'} :
                                                                                {..._gene}
                                                                        )
                                                                        handle_machine('genes', _x)
                                                                    })}/>
                                                            </Form.Field>
                                                        </Form>
                                                    </Table.Cell>

                                                    <Table.Cell style={{width: '70px'}}>
                                                        <MyInput placeholder="" name={`threshold_${index}`} style={{textAlign: "center"}}
                                                                 value={gene.threshold.toString()}
                                                                 change={(name, value) =>
                                                                     handle_machine('genes',
                                                                         machine.genes.map((_gene, _index) =>
                                                                             _index === index ? {..._gene, threshold: value} : {..._gene}
                                                                         )
                                                                     )
                                                                 }/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="row mx-0" style={{height: '30px'}}>
                            <div className="col-6 pl-0 pr-1">
                                <Button negative icon="close" labelPosition="left" size="tiny" fluid content="Close Window"
                                        onClick={params.close}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive icon="save" labelPosition="left" size="tiny" fluid content="Save Data"
                                        onClick={save_machine}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size='small' open={showInterpretation} centered={false} closeOnDimmerClick={false}
                   closeOnDocumentClick={false} closeOnEscape={false}>
                <div className="test_result controls">
                    <div style={{display: "flex", flexDirection: 'row', marginBottom: '5px'}}>
                        <div style={{flex: 1, marginRight: '10px'}}>
                            <div className="test_header mt-0">C<sub>t</sub> Values Interpretations</div>
                        </div>
                        <div>
                            <Button primary size='mini' icon='add' compact
                                    onClick={() => {
                                        const geneValues: GeneValues = {}
                                        machine.genes.forEach((gene) =>
                                            geneValues[gene.gene_control_id.toString()] = 'Positive'
                                        )
                                        handle_machine('interpretations',
                                            [...machine.interpretations, {rs: 'Invalid', genes: geneValues}]
                                        )
                                    }}/>
                        </div>
                    </div>

                    <div className="table_container controls">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center"/>
                                    {
                                        machine.genes.map((gene) =>
                                            <Table.HeaderCell style={{width: '120px'}} key={gene.gene_control_id} textAlign="center">
                                                {gene.item_name}
                                            </Table.HeaderCell>
                                        )
                                    }
                                    <Table.HeaderCell style={{width: '140px'}} textAlign="center">Results</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    machine.interpretations.map((interpretation, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '40px'}} textAlign="center">
                                                <Button primary size='mini' icon='trash' compact
                                                        onClick={() =>
                                                            handle_machine('interpretations',
                                                                machine.interpretations.filter((_, _index) => _index !== index))
                                                        }/>
                                            </Table.Cell>
                                            {
                                                machine.genes.map((gene) =>
                                                    <Table.Cell style={{width: '120px'}} key={gene.gene_control_id}>
                                                        <MySelect
                                                            name='' value={interpretation.genes[gene.gene_control_id.toString()]} placeholder=""
                                                            options={[
                                                                {text: 'Positive', value: 'Positive'},
                                                                {text: 'Negative', value: 'Negative'},
                                                            ]}
                                                            change={(value) => {
                                                                const _values: GeneValues = interpretation.genes
                                                                _values[gene.gene_control_id.toString()] = value as any
                                                                update_interpretation({...interpretation, genes: _values}, index)
                                                            }}/>
                                                    </Table.Cell>
                                                )
                                            }
                                            <Table.Cell style={{width: '140px'}}>
                                                <MySelect
                                                    name='' value={interpretation.rs} placeholder=""
                                                    options={[
                                                        {text: 'Positive', value: 'Positive'},
                                                        {text: 'Negative', value: 'Negative'},
                                                        {text: 'Invalid', value: 'Invalid'},
                                                        {text: 'Inconclusive', value: 'Inconclusive'}
                                                    ]}
                                                    change={(value) => {
                                                        const _int: Interpretation = {...interpretation, rs: value as any}
                                                        update_interpretation(_int, index)
                                                    }}/>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="row mx-0">
                        <div className="col-6 col-md-4 offset-md-2 pl-0 pr-1">
                            <Button negative icon="trash" labelPosition="left" size="tiny" fluid content="Close Window"
                                    onClick={() => setShowInterpretation(false)}/>
                        </div>
                        <div className="col-6 col-md-4 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid content="Save Data"
                                    onClick={save_interpretations}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
