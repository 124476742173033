import React from 'react';
import {Route, Switch} from 'react-router'
import AppBar from "./system/AppBar";
import Password from "./system/account/Password";
import InitiatePayment from "./system/app/diagnosis/InitiatePayment";


export default function App() {
    return (
        <Switch>
            <Route exact path={Path.route_home} component={AppBar}/>
            <Route exact path={Path.route_verify} component={Password}/>
            <Route exact path={Path.route_initiate} component={InitiatePayment}/>
            <Route component={AppBar}/>
        </Switch>
    )
};

export class Path {
    static route_home = "/"
    static route_verify = "/verify/:user_token"
    static route_initiate = "/initiate/:user_token/:client_id/:amount"
}


