import {Button, Checkbox, Confirm, Modal, Table} from "semantic-ui-react";
import React from "react";
import Utils, {Loading, my_initial_confirm, MyConfirmAction, MySelect, PaymentMethods, PaymentType, SelectData} from "../../../utils/Utils";
import AddPayment, {payment_types} from "./AddPayment";
import {Request} from "../../../utils/Request";
import {ToastsStore} from "react-toasts";

interface Sample {
    diagnosis_id: number
    diagnosis_no: string
    tier_description: string
    test_amount: number
    client_name: string
    date_created: string
}

interface Receive {
    payments: Array<PaymentType>
    diagnosis_ids: number[]
    total: number
    paid: number
}

export default function ReceiveSamples(params: { show: boolean, close: () => void, centers: Array<SelectData> }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)

    const [search, setSearch] = React.useState({center_id: 0})
    const [samples, setSamples] = React.useState(Array<Sample>())
    const load_pending_samples = () => {
        setLoader({message: "Loading samples, please wait", show: true})
        Request.get_pending_payments({center_id: search.center_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setSamples(response.data.data)
                } else {
                    ToastsStore.error("Error while loading samples")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Error while loading samples")
            })
    }

    React.useEffect(() => {
        if (search.center_id !== 0) {
            load_pending_samples()
        }
    }, [search.center_id])

    const receive_samples = () => {
        setConfirm({
            ...confirm, open: true, content: "Are You sure you want to proceed making this payment",
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                setLoader({message: "Loading samples, please wait", show: true})
                Request.save_pending_payments({
                    payments: JSON.stringify(receive.payments), diagnosis_ids: JSON.stringify(receive.diagnosis_ids)
                })
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            params.close()
                            ToastsStore.success("Received samples successfully")
                        } else {
                            ToastsStore.error("Could not receive samples, please retry")
                        }
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        ToastsStore.error("Could not receive samples, please retry")
                    })
            },
            onCancel: () => setConfirm({...confirm, open: false})
        })
    }

    const [receive, setReceive] = React.useState<Receive>({diagnosis_ids: [], payments: [], total: 0, paid: 0})
    const [payment, setPayment] = React.useState<{ amount: string, show: boolean, payment_type: payment_types }>(
        {amount: '0', show: false, payment_type: 'cash'})

    React.useEffect(() => {
        let paid = 0
        receive.payments.forEach((_payment) => paid += _payment.amount_paid)
        setReceive({...receive, paid: paid})
        setPayment({...payment, amount: (receive.total - paid).toString()})
    }, [receive.payments])

    React.useEffect(() => {
        let total = 0
        samples
            .filter((sample) => receive.diagnosis_ids.includes(sample.diagnosis_id))
            .forEach((sample) => total += sample.test_amount)
        setReceive({...receive, total: total})
        setPayment({...payment, amount: (total - receive.paid).toString()})
    }, [receive.diagnosis_ids])

    React.useEffect(() => {
        setReceive({diagnosis_ids: [], paid: 0, payments: [], total: 0})
        setSamples([])
        setSearch({center_id: 0})
    }, [params.show])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} centered={false}/>

            <AddPayment
                payment_type={payment.payment_type} show={payment.show} payable={payment.amount}
                close={() => setPayment({...payment, show: false})}
                save_payment={(payment_type, ref_no, account_name, amount_paid) => {
                    setReceive({
                        ...receive,
                        payments: [...receive.payments, {
                            payment_type: payment_type, ref_no: ref_no, account_name: account_name, amount_paid: amount_paid
                        }]
                    })
                    setPayment({...payment, show: false})
                }}/>

            <Modal size='large' open={params.show} dimmer="blurring" centered={false} closeOnDimmerClick={false} onClose={params.close}>
                <div className='lab_igg_igm_modal'>
                    <div className="row m-0 h-100">
                        <div className="col-12 col-md-8 p-1 h-100">
                            <div className="test_header mt-0">
                                Receive PCR, IGM/IGG Samples {receive.diagnosis_ids.length > 0 && <span>({Utils.comma_number(receive.total)})</span>}
                            </div>

                            <MySelect
                                name={'center_id'} value={search.center_id} placeholder='Select a center'
                                change={(value) => setSearch({...search, center_id: value as number})}
                                options={[{text: 'Select a test center', value: 0}, ...params.centers]}/>

                            <div className="received_container mt-2">
                                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '30px'}}/>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Diagnosis No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '150px'}}>Test Type</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Test Amount</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '250px'}}>Client Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '150px'}}>Date Tested</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            samples
                                                .map((sample, index) =>
                                                    <Table.Row key={sample.diagnosis_id}>
                                                        <Table.Cell style={{width: '30px'}}>
                                                            <Checkbox
                                                                checked={receive.diagnosis_ids.includes(sample.diagnosis_id)}
                                                                onChange={((event, data) => {
                                                                    setReceive({
                                                                        ...receive, diagnosis_ids: data.checked ?
                                                                            [...receive.diagnosis_ids, sample.diagnosis_id] :
                                                                            receive.diagnosis_ids.filter((_id) => _id !== sample.diagnosis_id)
                                                                    })
                                                                })}/>
                                                        </Table.Cell>
                                                        <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>{sample.diagnosis_no}</Table.Cell>
                                                        <Table.Cell style={{width: '150px'}}>{sample.tier_description}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>{Utils.comma_number(sample.test_amount)}</Table.Cell>
                                                        <Table.Cell style={{width: '250px'}}>{sample.client_name}</Table.Cell>
                                                        <Table.Cell style={{width: '150px'}}>{Utils.localise_date(sample.date_created)}</Table.Cell>
                                                    </Table.Row>
                                                )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 p-1 h-100">
                            <PaymentMethods setPayment={setPayment} payment={payment} payments={receive.payments}/>

                            <div className="table_container cashier_receive">
                                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                            <Table.HeaderCell style={{width: '200px'}}>Payment Information</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            receive.payments
                                                .map((payment, index) =>
                                                    <Table.Row key={index}>
                                                        <Table.Cell style={{width: '50px'}}>
                                                            <Button
                                                                primary size='mini' icon='trash' compact
                                                                onClick={() => {
                                                                    let _payments: any[] = []
                                                                    receive.payments
                                                                        .forEach((_payment, _index) => {
                                                                            if (_index !== index) {
                                                                                _payments = [..._payments, _payment]
                                                                            }
                                                                        })
                                                                    setReceive({...receive, payments: _payments})
                                                                }}>
                                                            </Button>
                                                        </Table.Cell>
                                                        <Table.Cell style={{width: '200px'}}>
                                                            <div className='payment_type'>{Utils.get_method(payment.payment_type) + " Payment"}</div>
                                                            {
                                                                payment.account_name !== '' &&
                                                                <div className='account_info'>{payment.account_name}</div>
                                                            }
                                                            {payment.ref_no !== '' && <div className='ref_no'>{payment.ref_no}</div>}
                                                            <div className='amount'>{Utils.comma_number(payment.amount_paid)}</div>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>

                            <div className="row mx-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative icon="close" labelPosition="left" size="tiny" fluid content="Close Window"
                                            onClick={params.close}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                            disabled={receive.total === 0 || receive.total !== receive.paid}
                                            content="Receive Samples" onClick={receive_samples}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
