import React from "react";
import Utils, {InputFile, my_initial_confirm, MyConfirmAction, MyDateInput, MyInput, MySelect, read_input, SelectData} from "../../../utils/Utils";
import PhoneInput from "react-phone-input-2";
import {Button, Confirm, Modal} from "semantic-ui-react";
import {Request} from "../../../utils/Request";
import {countries} from "../../../utils/Countries"
import {SystemUser} from "../../AppBar"
import moment from "moment";
import {ToastsStore} from "react-toasts";
import {ClientInfo, initial_client} from "./Clients";

export default function Account(params: {
    systemUser: SystemUser, groups: SelectData[], client: ClientInfo, create_test: (client_id: number) => void,
    show: boolean, close: () => void
}) {
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [saving, setSaving] = React.useState(false)
    const [client, setClient] = React.useState(initial_client)

    const handle_client = (name: string, value: string | number | InputFile) => {
        setClient({...client, [name]: value})
    }

    const create_test = (client_id: number) => {
        setConfirm({
            ...confirm, open: true, content: "Do you wish to create test for client",
            onCancel: () => setConfirm({...confirm, open: false}),
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                params.create_test(client_id)
            }
        })
    }

    const update_data = () => {
        if (Utils.invalid_name(client.first_name.trim())) {
            ToastsStore.error("Enter a valid first name")
        } else if (Utils.invalid_name(client.last_name.trim())) {
            ToastsStore.error("Enter a valid surname")
        } else if (client.other_name.trim().length > 0 && Utils.invalid_name(client.other_name.trim())) {
            ToastsStore.error("Enter a valid other name")
        } else if (client.nationality === '') {
            ToastsStore.error("Please select nationality")
        } else if (client.date_of_birth === "" && !Utils.is_valid_number(client.client_age)) {
            ToastsStore.error("Enter either a valid date of birth or age estimate")
        } else if (client.date_of_birth !== "" && moment(client.date_of_birth).isBefore(moment("1900-01-01"))) {
            ToastsStore.error("Date of birth cannot be before 01/01/1900")
        } else if (client.date_of_birth !== "" && moment(client.date_of_birth).isAfter(moment(Utils.today()))) {
            ToastsStore.error("Date of birth cannot be after today")
        } else if (client.client_age !== "" && (!Utils.is_valid_number(client.client_age.toString().trim()) ||
            parseInt(client.client_age.toString().trim()) < 0 || parseInt(client.client_age.toString().trim()) > 100)) {
            ToastsStore.error("Enter a valid age estimate")
        } else if (client.client_gender === "") {
            ToastsStore.error("Please select a gender")
        } else if (client.mobile_contact.trim().length > 0 && Utils.invalid_contact(client.mobile_contact)) {
            ToastsStore.error("Enter a valid mobile contact")
        } else if (client.email_address.trim().length > 0 && Utils.in_valid_email(client.email_address.trim())) {
            ToastsStore.error("Enter a valid email address")
        } else if (client.district.trim().length < 3) {
            ToastsStore.error("Enter residential district")
        } else if (client.village.trim().length < 3) {
            ToastsStore.error("Enter residential village")
        } else if (client.next_of_kin_contact.trim().length > 0 && Utils.invalid_contact(client.next_of_kin_contact)) {
            ToastsStore.error("Enter a valid next of kin mobile contact")
        } else {
            setSaving(true)
            Request.update_account({
                client_age: client.client_age, client_gender: client.client_gender, client_id: client.client_id,
                date_of_birth: client.date_of_birth, district: client.district, email_address: client.email_address,
                first_name: client.first_name, group_id: client.group_id, last_name: client.last_name, mobile_contact: client.mobile_contact,
                nationality: client.nationality, next_of_kin: client.next_of_kin, next_of_kin_contact: client.next_of_kin_contact,
                other_name: client.other_name, parish: client.parish, passport_no: client.passport_no,
                passport_photo: client.passport_photo, sub_county: client.sub_county, village: client.village
            })
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            ToastsStore.success("Successfully saved user profile")
                            setClient({...client, client_id: response.data.data.client_id})
                            create_test(response.data.data.client_id)
                        } else if (response.data.code === 4) {
                            ToastsStore.error("Client data could not be matched, please retry")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Could not process data")
                        }
                    } else {
                        ToastsStore.error("Error while saving client data, please retry")
                    }
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Error while saving client data, please retry")
                })
        }
    }

    React.useEffect(() => {
        const parse_client_data = (userInfo: ClientInfo) => {
            if (userInfo.mobile_contact !== "") {
                userInfo = {...userInfo, mobile_contact: `+${userInfo.mobile_contact}`}
            }
            if (userInfo.next_of_kin_contact !== "") {
                userInfo = {...userInfo, next_of_kin_contact: `+${userInfo.next_of_kin_contact}`}
            }
            return userInfo
        }
        setClient(parse_client_data(params.client))
    }, [params.client])

    return (
        <>
            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} header="Create Test"/>

            <Modal size='small' open={params.show} centered={false}
                   closeOnDimmerClick={false} closeOnDocumentClick={false} closeOnEscape={false}>
                <div className="account_modal">
                    <div className="row m-0 user_info_form">
                        <div className="col-12 p-1">
                            <div className="test_header">Personal Information</div>
                            <div className="user_info">
                                <div className="row m-0">
                                    <div className="col-12 col-md-6 p-1">
                                        <div className="passport_photo_container">
                                            <img src={client.passport_photo.file_src} alt='' className='client_passport_photo'/>

                                            <div>
                                                <input type="file" className="input_file" id="passport_photo_input"
                                                       accept="image/*" multiple={false}
                                                       onChange={(event) => {
                                                           read_input(event)
                                                               .then((response) => {
                                                                   handle_client('passport_photo', response);
                                                                   (document.getElementById("passport_photo_input") as HTMLInputElement).value = "";
                                                               })
                                                               .catch((error) => {
                                                                   console.log(error)
                                                               })
                                                       }}/>
                                                <label htmlFor="passport_photo_input" className="ui mini primary button fluid">
                                                    <i className="ui upload icon"/>Select an image file
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        <div className="user_data_container">
                                            <div className="label">Surname</div>
                                            <MyInput placeholder="Enter surname" name="last_name"
                                                     value={client.last_name} change={handle_client}/>
                                        </div>

                                        <div className="user_data_container">
                                            <div className="label">First Name</div>
                                            <MyInput placeholder="First Name" name="first_name"
                                                     value={client.first_name} change={handle_client}/>
                                        </div>

                                        <div className="user_data_container">
                                            <div className="label">Other Name (optional)</div>
                                            <MyInput placeholder="Other Name" name="other_name"
                                                     value={client.other_name} change={handle_client}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row m-0">
                                    <div className="col-7 col-md-4 p-1">
                                        <div className="user_data_container">
                                            <div className="label">Date of Birth</div>
                                            <MyDateInput
                                                value={client.date_of_birth} name="date_of_birth" maxDate={Utils.today()}
                                                minDate="1900-01-01" placeholder="Select date of birth" change={handle_client}/>
                                        </div>
                                    </div>

                                    <div className="col-5 col-md-2 p-1">
                                        <div className="user_data_container">
                                            <div className="label">Age</div>
                                            <MyInput value={client.client_age.toString()} name="client_age" placeholder="Age"
                                                     change={handle_client}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-0">
                                        <div className="row m-0">
                                            <div className="col-12 col-md-6 p-1">
                                                <div className="user_data_container">
                                                    <div className="label">Nationality</div>
                                                    <MySelect
                                                        change={(value) => handle_client("nationality", value as string)}
                                                        name="nationality" value={client.nationality} placeholder="Select nationality"
                                                        options={countries}/>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 p-1">
                                                <div className="user_data_container">
                                                    <div className="label">Gender</div>
                                                    <MySelect
                                                        change={(value) => handle_client("client_gender", value as string)}
                                                        name="client_gender" value={client.client_gender} placeholder="Select user gender"
                                                        options={[
                                                            {value: "Other", text: "Other"},
                                                            {value: "Male", text: "Male"},
                                                            {value: "Female", text: "Female"},
                                                        ]}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        <div className="mb-md-0 user_data_container">
                                            <div className="label">Passport No. (necessary for travellers)</div>
                                            <MyInput placeholder="Passport No" name="passport_no"
                                                     value={client.passport_no} change={handle_client}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        {
                                            Utils.has_center_role({role: "manage_companies", roles: params.systemUser.center.center_roles}) &&
                                            <div className="user_data_container mb-0">
                                                <div className="label">Client Group</div>
                                                <MySelect
                                                    change={(value) => handle_client("group_id", value as number)}
                                                    name="group_id" value={client.group_id} placeholder="Select company name"
                                                    options={params.groups}/>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="test_header">Address and Contact Info</div>
                            <div className="user_info">
                                <div className="row m-0">
                                    <div className="col-12 col-md-6 p-1">
                                        <div className="user_data_container">
                                            <div className="label">Email Address</div>
                                            <MyInput placeholder="Email Address" name="email_address"
                                                     value={client.email_address} change={handle_client}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        <div className="label">Mobile Contact</div>
                                        <PhoneInput
                                            countryCodeEditable={true} country={'ug'} value={client.mobile_contact}
                                            onChange={((value) => handle_client('mobile_contact', value))}/>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        <div className="user_data_container">
                                            <div className="label">Residential Sub Country (optional)</div>
                                            <MyInput placeholder="Enter sub county" name="sub_county" change={handle_client}
                                                     value={client.sub_county}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        <div className="user_data_container">
                                            <div className="label">Residential District (In Uganda)</div>
                                            <MyInput placeholder="Enter district" name="district" change={handle_client} value={client.district}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        <div className="mb-md-0 user_data_container">
                                            <div className="label">Residential Parish (optional)</div>
                                            <MyInput placeholder="Enter parish" name="parish"
                                                     value={client.parish} change={handle_client}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        <div className="mb-0 user_data_container">
                                            <div className="label">Residential Village (In Uganda)</div>
                                            <MyInput placeholder="Enter village" name="village"
                                                     value={client.village} change={handle_client}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="test_header">Next Of Kin</div>
                            <div className="user_info">
                                <div className="row m-0">
                                    <div className="col-12 col-md-6 p-1">
                                        <div className="mb-md-0 user_data_container">
                                            <div className="label">Next of kin full name</div>
                                            <MyInput placeholder="Next of kin name" name="next_of_kin"
                                                     change={handle_client} value={client.next_of_kin}/>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-1">
                                        <div className="label">Mobile Contact</div>
                                        <PhoneInput
                                            value={client.next_of_kin_contact} containerStyle={{marginBottom: '0px'}}
                                            onChange={((value) => {
                                                handle_client('next_of_kin_contact', value)
                                            })}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 user_info_button">
                        <div className="col-12 col-md-8 offset-md-2 p-1">
                            <div className="row m-0 align-items-center">
                                <div className="col-6 p-1">
                                    <Button negative icon="close" labelPosition="left" size="tiny" fluid
                                            content="Close Window" disabled={saving} onClick={params.close}/>
                                </div>

                                <div className="col-6 p-1">
                                    <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                            content={client.client_id === 0 ? "Save Client" : "Update Data"}
                                            onClick={update_data} disabled={saving} loading={saving}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
