import {SystemUser} from "../../../AppBar";
import Utils, {Loading, my_initial_confirm, MyConfirmAction, MyDateInput, MyInput, MySelect, SelectData, ShowPDF} from "../../../../utils/Utils";
import React from "react";
import {Button, Checkbox, Confirm, Modal, Table} from "semantic-ui-react";
import {Request} from "../../../../utils/Request";
import {ToastsStore} from "react-toasts";

interface Test {
    analysed_by: string
    diagnosis_id: number
    diagnosis_no: string
    igg_result: number | string
    igm_result: number | string
    sample_date: string
    test_date: string
    test_type: string
    test_name: string
    travel_time: string
    validated_date: string
    verified_by: string
    result_status: string
}

export default function LabAntibodyAntigen(props: { systemUser: SystemUser, centers: Array<SelectData> }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)

    const [fromStart, setFromStart] = React.useState(false)
    const [search, setSearch] = React.useState({
        min_date: Utils.today(), max_date: Utils.today(), diagnosis_no: "",
        center_id: Request.COMPANY === 'MEDSAFE' ? props.systemUser.center.center_id :
            (props.systemUser.center.center_id === 1 ? 0 : props.systemUser.center.center_id)
    })
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }
    const [tests, setTests] = React.useState(Array<Test>())

    const load_tests = () => {
        setLoader({message: "Loading samples, please wait", show: true})
        Request.get_lab_igg_igm({max_date: search.max_date, min_date: search.min_date, center_id: search.center_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setTests(response.data.data)
                } else {
                    ToastsStore.error("Could not load IGG/IGM tests")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not load IGG/IGM tests")
            })
    }

    const [showReceipt, setShowReceipt] = React.useState({show: false, url: ""})
    const [showReceive, setShowReceive] = React.useState<{ show: boolean, no: string, nos: string[] }>({show: false, no: "", nos: []})
    const receive_tests = () => {
        if (showReceive.nos.length === 0) {
            ToastsStore.error("No sample diagnosis no has been added")
        } else {
            setLoader({message: "Receiving IGM/IGG samples", show: true})
            Request.receive_igg_igm_tests({diagnosis_nos: JSON.stringify(showReceive.nos)})
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (response.data.data.url !== "") {
                                setTests(
                                    tests.map((test) =>
                                        showReceive.nos
                                            .map((no) => (no.toUpperCase()))
                                            .includes(test.diagnosis_no) ? {
                                            ...test, test_date: response.data.data.time_received, analysed_by: response.data.data.user_name
                                        } : {...test}
                                    ))
                                setShowReceive({no: "", nos: [], show: false})
                                setShowReceipt({show: true, url: Request.BASE_URL + response.data.data.url})
                                ToastsStore.success("Samples successfully received")
                            }

                        } else if (response.data.code === 2) {
                            ToastsStore.error("You have no rights to update test results, logout and login again to update your user rights")

                        } else if (response.data.code === 3) {
                            ToastsStore.error("Some test samples could not be found or have already been received")
                        }
                    } else {
                        ToastsStore.error("Could not receive results, please retry")
                    }
                    setLoader({message: "", show: false})
                })
                .catch(() => {
                    setLoader({message: "", show: false})
                    ToastsStore.error("Could not receive results, please retry")
                })
        }
    }

    const [showUpdate, setShowUpdate] = React.useState({diagnosis_no: "", diagnosis_id: 0, igg: "", igm: "", show: false})
    const update_tests = () => {
        if (!Utils.is_valid_number(showUpdate.igm)) {
            ToastsStore.error("Enter valid IGM results")
        } else if (!Utils.is_valid_number(showUpdate.igg)) {
            ToastsStore.error("Enter valid IGG results")
        } else {
            setLoader({message: "Updating IGG/IGM results, please wait", show: true})
            Request.update_igg_igm_tests({
                diagnosis_id: showUpdate.diagnosis_id, igg_result: parseFloat(showUpdate.igg),
                igm_result: parseFloat(showUpdate.igm)
            })
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (response.data.data.updated === 1) {
                                ToastsStore.success("Test results updated successfully")
                                setTests(tests.map((test) => test.diagnosis_id === showUpdate.diagnosis_id ?
                                    {...test, igm_result: showUpdate.igm, igg_result: showUpdate.igg} : test))
                            } else {
                                ToastsStore.error("Test already updated")
                            }
                            setShowUpdate({diagnosis_id: 0, diagnosis_no: "", igg: "", igm: "", show: false})

                        } else if (response.data.code === 2) {
                            ToastsStore.success("You have no rights to update test results, logout and login again to update your user rights")
                        }
                    } else {
                        ToastsStore.error("Could not update results, please retry")
                    }
                    setLoader({message: "", show: false})
                })
                .catch(() => {
                    setLoader({message: "", show: false})
                    ToastsStore.error("Could not update results, please retry")
                })
        }
    }

    const [showVerify, setShowVerify] = React.useState(false)
    const valid_verification = (test: Test) => {
        return !(test.verified_by !== '' || test.analysed_by === '' || !Utils.is_valid_number(test.igm_result) ||
            !Utils.is_valid_number(test.igg_result))
    }
    const verify_tests = () => {
        const valid_tests =
            tests
                .filter((test) => valid_verification(test))
                .map((test) => (test.diagnosis_id))
        if (valid_tests.length === 0) {
            ToastsStore.info("No tests to validate found")
            return
        }

        setConfirm({
            ...confirm,
            open: true, content: `Do you want to continue verifying ${valid_tests.length} IGM/IGG results`,
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                setLoader({message: `Verifying ${valid_tests.length} IGG/IGM results, please wait`, show: true})

                Request.verify_igg_igm_results({ids: JSON.stringify(valid_tests)})
                    .then((response) => {
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                ToastsStore.info("Successfully verified results")
                                setTests(tests.map((test) =>
                                    !valid_tests.includes(test.diagnosis_id) ? {...test} :
                                        {...test, verified_by: response.data.data.user_name, validated_date: response.data.data.date_verified}
                                ))
                            } else {
                                ToastsStore.error("Could not verify IGG/IGM results, please retry")
                            }
                        }
                        setLoader({message: "", show: false})
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        ToastsStore.error("Could not verify IGG/IGM results, please retry")
                    })
            },
            onCancel: () => setConfirm({...confirm, 'open': false})
        })
    }

    const download_report = (diagnosis_id: number) => {
        setLoader({message: "Printing IGG/IGM results", show: true})
        Request.print_igg_igm_results({diagnosis_id: diagnosis_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setShowReceipt({show: true, url: Request.BASE_URL + response.data.data.url})
                } else {
                    ToastsStore.error("Could not print IGG/IGM results, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not print IGG/IGM results, please retry")
            })
    }

    React.useEffect(() => {
        setSearch({...search, min_date: fromStart ? '2020-06-06' : Utils.today()})
    }, [fromStart])

    React.useEffect(() => {
        load_tests()
    }, [])

    React.useEffect(() => {
        console.log(showReceive)
    }, [showReceive])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} centered={false}/>

            <div className="h-100">
                <div className="content_bar">
                    <div className="search_bar">
                        <div className='dropdown'>
                            <Button icon='ordered list' primary size='tiny'/>

                            <div className='drop_list'>
                                <div className='hide_large'>
                                    <div className='search_check'>
                                        <Checkbox
                                            label='From Beginning' checked={fromStart}
                                            onChange={(event, data) => setFromStart(data.checked as boolean)}/>
                                    </div>
                                    <label>Start Date</label>
                                    <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum diagnosis date"
                                                 change={handle_search} maxDate={Utils.today()}/>

                                    <label>End Date</label>
                                    <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum diagnosis date"
                                                 change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                                </div>

                                <label>Diagnosis No.</label>
                                <MyInput placeholder="Diagnosis No." name='diagnosis_no' value={search.diagnosis_no}
                                         change={handle_search}/>

                                <label>Center Name</label>
                                <MySelect
                                    disabled={
                                        Request.COMPANY === 'MEDSAFE' ||
                                        !Utils.has_center_role({role: "manage_centers", roles: props.systemUser.center.center_roles})
                                    }
                                    name={'center_id'} value={search.center_id} placeholder='Select a center'
                                    change={(value) => handle_search('center_id', value as number)}
                                    options={[{text: 'Show all centers', value: 0}, ...props.centers]}/>
                            </div>
                        </div>
                        <div className='search_check hide_small'>
                            <Checkbox
                                label='From Beginning' checked={fromStart}
                                onChange={(event, data) => setFromStart(data.checked as boolean)}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                         change={handle_search} maxDate={Utils.today()}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                         change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                        </div>
                    </div>

                    <div className="content_buttons d-none d-md-inline-block">
                        <Button primary size='mini' icon='search' labelPosition="left" content="Search Results" onClick={load_tests}/>
                    </div>

                    <div className="content_buttons d-md-none">
                        <Button primary size='mini' icon='search' onClick={load_tests}/>
                    </div>
                </div>

                <div className="table_container igg_igm">
                    <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '80px'}}/>
                                <Table.HeaderCell style={{width: '100px'}}>Diagnosis No</Table.HeaderCell>

                                <Table.HeaderCell style={{width: '140px'}}>Sample Time</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Travel Time</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Test Type</Table.HeaderCell>

                                {/* <Table.HeaderCell style={{width: '150px'}}>Analysed By</Table.HeaderCell>*/}
                                <Table.HeaderCell style={{width: '140px'}}>Time Analysed</Table.HeaderCell>

                                <Table.HeaderCell style={{width: '70px'}} textAlign="center">IGG</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '70px'}} textAlign="center">IGM</Table.HeaderCell>

                                {/* <Table.HeaderCell style={{width: '150px'}}>Validated By</Table.HeaderCell>*/}
                                <Table.HeaderCell style={{width: '140px'}}>Time Validated</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                tests
                                    .filter((test) => {
                                        return test.diagnosis_no.toLocaleLowerCase().includes(search.diagnosis_no.toLocaleLowerCase())
                                    })
                                    .map((test, index) =>
                                        <Table.Row key={index} className={test.result_status === 'rejected' ? 'row_rejecteds' : ''}>
                                            <Table.Cell style={{width: '80px'}}>
                                                <Button
                                                    primary size='mini' icon='edit' compact
                                                    disabled={
                                                        test.result_status === 'rejected' || test.analysed_by === "" || test.verified_by !== ""
                                                    }
                                                    onClick={() => {
                                                        setShowUpdate({
                                                            diagnosis_id: test.diagnosis_id,
                                                            diagnosis_no: test.diagnosis_no,
                                                            igg: test.igg_result.toString(),
                                                            igm: test.igm_result.toString(),
                                                            show: true
                                                        })
                                                    }}/>

                                                <Button
                                                    primary size='mini' icon='file pdf' compact disabled={test.verified_by === ""}
                                                    onClick={() => download_report(test.diagnosis_id)}/>
                                            </Table.Cell>

                                            <Table.Cell style={{width: '100px'}}>{test.diagnosis_no}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{Utils.localise_date(test.sample_date)}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{Utils.localise_date(test.travel_time)}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{test.test_name}</Table.Cell>

                                            {/*  <Table.Cell style={{width: '150px'}}>{test.analysed_by}</Table.Cell>*/}
                                            <Table.Cell style={{width: '140px'}}>{Utils.localise_date(test.test_date)}</Table.Cell>

                                            <Table.Cell style={{width: '70px'}} textAlign="center">{test.igg_result}</Table.Cell>
                                            <Table.Cell style={{width: '70px'}} textAlign="center">{test.igm_result}</Table.Cell>

                                            {/* <Table.Cell style={{width: '150px'}}>{test.verified_by}</Table.Cell>*/}
                                            <Table.Cell style={{width: '140px'}}>{Utils.localise_date(test.validated_date)}</Table.Cell>
                                        </Table.Row>
                                    )
                            }
                        </Table.Body>
                    </Table>
                </div>

                <div className="row m-0">
                    <div className="col-6 col-md-3 offset-3 p-1">
                        <Button primary size='mini' content="Receive Samples" fluid
                                onClick={() => setShowReceive({...showReceive, show: true})}/>
                    </div>

                    <div className="col-6 col-md-3 p-1">
                        <Button primary size='mini' content="Verify Results" fluid onClick={() => setShowVerify(true)}/>
                    </div>
                </div>
            </div>

            <Modal size='mini' open={showReceive.show} centered={false} onClose={() => setShowReceive({...showReceive, show: false})}>
                <div className='lab_igg_igm_modal'>
                    <div className="content">
                        <div className="test_header mt-0">Receive IGM/IGG Samples</div>

                        <MyInput
                            placeholder="Enter sample diagnosis no" name="" value={showReceive.no}
                            change={((name, value) => setShowReceive({...showReceive, no: value}))}
                            enter={() => {
                                const new_no = showReceive.no.trim()
                                if (!showReceive.nos.includes(new_no)) {
                                    setShowReceive({...showReceive, nos: [new_no, ...showReceive.nos], no: ""})
                                } else {
                                    setShowReceive({...showReceive, no: ""})
                                }
                            }}/>

                        <div className="received_container mt-2">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}}/>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '150px'}}>Diagnosis No.</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        showReceive.nos
                                            .map((no, index) =>
                                                <Table.Row key={index}>
                                                    <Table.Cell style={{width: '50px'}}>
                                                        <Button
                                                            primary size='mini' icon='trash' compact
                                                            onClick={() => {
                                                                setShowReceive({
                                                                    ...showReceive, nos: showReceive.nos.filter((_no) => _no != no)
                                                                })
                                                            }}>
                                                        </Button>
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                                    <Table.Cell style={{width: '150px'}}>{no}</Table.Cell>
                                                </Table.Row>
                                            )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="row mx-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="close" labelPosition="left" size="tiny" fluid content="Close Window"
                                    onClick={() => setShowReceive({...showReceive, show: false})}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                    content="Receive Samples" onClick={receive_tests}/>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal size='mini' open={showUpdate.show} centered={false} onClose={() => setShowUpdate({...showUpdate, show: false})}>
                <div className='p-2'>
                    <div className="test_header mb-3 mt-0">Update IGG/IGM Results</div>

                    <div className="row m-0">
                        <div className="col-6 p-1">
                            <MyInput
                                placeholder="IGG Results" name="" value={showUpdate.igg.toString()}
                                change={(name, value) => setShowUpdate({...showUpdate, igg: value})}/>
                        </div>
                        <div className="col-6 p-1">
                            <MyInput
                                placeholder="IGM Results" name="" value={showUpdate.igm.toString()}
                                change={(name, value) => setShowUpdate({...showUpdate, igm: value})}/>
                        </div>
                    </div>

                    <div className="row mx-0 mt-3">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="close" labelPosition="left" size="tiny" fluid content="Cancel Update"
                                    onClick={() => setShowUpdate({...showUpdate, show: false})}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                    content="Update Results" onClick={update_tests}/>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal size='mini' open={showVerify} centered={false} onClose={() => setShowVerify(false)}>
                <div className='lab_igg_igm_modal'>
                    <div className="content">
                        <div className="received_container mt-2">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '150px'}}>Diagnosis No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}} textAlign="center">IGG</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}} textAlign="center">IGM</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        tests
                                            .filter((test) => valid_verification(test))
                                            .map((test, index) =>
                                                <Table.Row key={index}>
                                                    <Table.Cell style={{width: '40px'}} textAlign="center">{index + 1}</Table.Cell>
                                                    <Table.Cell style={{width: '150px'}}>{test.diagnosis_no}</Table.Cell>
                                                    <Table.Cell style={{width: '60px'}} textAlign="center">{test.igg_result}</Table.Cell>
                                                    <Table.Cell style={{width: '60px'}} textAlign="center">{test.igm_result}</Table.Cell>
                                                </Table.Row>
                                            )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>

                    <div className="row mx-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="close" labelPosition="left" size="tiny" fluid content="Close Window"
                                    onClick={() => setShowUpdate({...showUpdate, show: false})}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                    content="Verify Results" onClick={verify_tests}/>
                        </div>
                    </div>
                </div>
            </Modal>

            <ShowPDF show={showReceipt.show} close={() => setShowReceipt({...showReceipt, show: false})} url={showReceipt.url}/>
        </>
    )
}
