import {Button, Confirm, Modal} from "semantic-ui-react";
import {Loading, my_initial_confirm, MyConfirmAction} from "../../../../../utils/Utils";
import React from "react";
import {Request} from "../../../../../utils/Request";
import {ReportTest, sample_status} from "../PCRTests";
import {SystemUser} from "../../../../AppBar";
import axios from "axios";
import {ToastsStore} from "react-toasts";


interface Log {
    log_details: string
    log_name: sample_status
    log_time: string
}

interface User {
    logs: Log[]
}

export default function DiagnosisDetails(params: {
    tests: ReportTest[], setTests: (tests: ReportTest[]) => void, diagnosis_id: number, close: () => void, systemUser: SystemUser
}) {
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [user, setUser] = React.useState<User>({logs: []})

    const get_test_data = (diagnosis_id: number) => {
        setLoader({message: "Loading test data, please wait", show: true})
        Request.get_test_data({diagnosis_id: diagnosis_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setUser(response.data.data)
                } else {
                    params.close()
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                params.close()
            })
    }

    const re_upload_results = () => {
        setConfirm({
            ...confirm,
            open: true, content: 'Are you sure you want to re-dispatch these results',
            confirmButton: "Proceed", cancelButton: "Cancel",
            onCancel: () => setConfirm({...confirm, open: false}),
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                axios.defaults.timeout = 1000 * 60 * 5
                setLoader({message: "Dispatching results to RDS, please wait", show: true})

                Request.synchronise_csv_file({
                    ids: JSON.stringify([params.diagnosis_id]), no_gene: 1,
                    duplicate: user.logs.filter((log) => log.log_name === "uploaded").length
                })
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                ToastsStore.success("Successfully synchronised results to RDS")
                                //setResponses(response.data.responses)
                            } else {
                                ToastsStore.error("Could not synchronise results to RDS, please retry")
                            }
                        }
                        axios.defaults.timeout = 1000 * 60
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        ToastsStore.error("Could not synchronise results to RDS, please retry")
                        axios.defaults.timeout = 1000 * 60
                    })
            }
        })
    }

    React.useEffect(() => {
        if (params.diagnosis_id > 0) {
            get_test_data(params.diagnosis_id)
        }
    }, [params.diagnosis_id])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} centered={false}/>

            <Modal size='mini' open={params.diagnosis_id > 0} centered={false} onClose={params.close}>
                <div className="verify_container">
                    <div className="label">Diagnosis Results</div>
                    <div className="row m-0">

                    </div>

                    <div className="label mt-3">Clinical Diagnosis Results</div>

                    <div className="label mt-3">Other Conditions</div>

                    <div className="row m-0">
                        <div className="col-6 pr-1 pl-0">
                            <Button positive icon="save" labelPosition="left" size="mini" fluid
                                    disabled={
                                        !params.systemUser.roles.includes("rds_sync") ||
                                        user.logs.filter((log) => log.log_name === "uploaded").length === 0
                                    }
                                    content="Re-Upload" onClick={re_upload_results}/>
                        </div>
                        <div className="col-6 pr-0 pl-1">

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
