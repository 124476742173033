import {ReportTest} from "../../PCRTests";
import {Machine} from "./PCRMachines";
import React from "react";
import {ToastsStore} from "react-toasts";
import {Loading, MessageProps, MyInput, MyMessage, MySelect, ShowPDF} from "../../../../../../utils/Utils";
import {Request} from "../../../../../../utils/Request";
import {Button, Checkbox, Table} from "semantic-ui-react";
import moment from "moment";

interface Sample {
    lab_no: string
    diagnosis_no: string
}

interface Test {
    date_created: string
    diagnosis_no: string
    test_name: string
    loaded: boolean
    lab_no: string
}

export default function LoadSamples(params: {
    tests: ReportTest[], setTests: (tests: ReportTest[]) => void, close: () => void, machines: Machine[], setSize: () => void
}) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [showReceipt, setShowReceipt] = React.useState({show: false, url: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [tests, setTests] = React.useState(Array<Test>())
    const [machineID, setMachineID] = React.useState(0)

    const load_received_samples = () => {
        setLoader({message: "Fetching samples, please wait", show: true})
        Request.get_received_samples()
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setTests(response.data.data.samples.map((sample: any) => ({...sample, loaded: false, lab_no: ''})))
                    if (response.data.data.samples.length === 0) {
                        ToastsStore.info("There are no samples to load")
                    }
                } else {
                    ToastsStore.error("Could not get samples, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not get samples, please retry")
            })
    }

    const load_samples = () => {
        const samples: Sample[] = tests
            .filter((test) => test.loaded)
            .map((test) => ({lab_no: test.lab_no, diagnosis_no: test.diagnosis_no}))
        if (samples.length === 0) {
            ToastsStore.info("You have not selected any samples for loading")
            return
        }

        let has_errors = false
        for (let index = 0; index < samples.length && !has_errors; index++) {
            const similar = samples.filter((_sample) => {
                console.log(samples[index], _sample)
                return samples[index].lab_no.trim() === _sample.lab_no.trim() && samples[index].diagnosis_no !== _sample.diagnosis_no
            })

            if (samples[index].lab_no.trim().length === 0) {
                ToastsStore.error(`Enter a valid Well No. for '${samples[index].diagnosis_no}'`)
                has_errors = true
            } else if (similar.length > 0) {
                ToastsStore.error(`Well No. is already assigned to Sample No. '${similar[0].diagnosis_no}'`)
                has_errors = true
            }
        }

        if (has_errors) {
            return
        }

        if (machineID === 0) {
            ToastsStore.error("Select a machine where samples are to be loaded")
        } else {
            setLoader({message: "Loading PCR samples", show: true})
            Request.load_samples({samples: JSON.stringify(samples), machine_id: machineID, has_targets: Request.CURRENT ? 1 : 0})
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            if (response.data.data.url !== "") {
                                setShowReceipt({show: true, url: `${Request.BASE_URL}${response.data.data.url}`})
                            }
                            const loaded: string[] = samples.map((sample) => (sample.diagnosis_no))
                            params.setTests(
                                params.tests.map((test) => {
                                    return loaded.includes(test.diagnosis_no) ? {...test, result_status: 'loaded'} : test;
                                })
                            )
                            setTests(tests.filter((test) => !loaded.includes(test.diagnosis_no)))
                        } else if (response.data.code === 2) {
                            setMessage({
                                active: true, type: 'info',
                                message: `Diagnosis No. <b>${response.data.data.diagnosis_no}</b> is already loaded or not in the system`,
                            })
                        } else if (response.data.code === 3) {
                            setMessage({
                                active: true, type: 'info',
                                message: `Lab No. <b>${response.data.data.lab_no}</b> is still in use by<br/> Diagnosis No. <b>${response.data.data.diagnosis_no}</b>`,
                            })
                        }
                    } else {
                        ToastsStore.error("Could not receive results, please retry")
                    }
                    setLoader({message: "", show: false})
                })
                .catch((error) => {
                    console.log(error)
                    setLoader({message: "", show: false})
                    ToastsStore.error("Could not receive results, please retry")
                })
        }
    }

    const print_loaded_samples = () => {
        setLoader({message: "Printing loaded PCR samples worksheet", show: true})
        Request.print_loaded_samples()
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        setShowReceipt({show: true, url: `${Request.BASE_URL}${response.data.data.url}`})
                    } else if (response.data.code === 2) {
                        ToastsStore.info("There are no loaded samples")
                    }
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not print sample worksheet, please retry")
            })
    }

    const close = () => {
        setTests([])
        params.close()
    }

    React.useEffect(() => {
        load_received_samples()
        params.setSize()
    }, [])

    return (
        <>
            <ShowPDF show={showReceipt.show} close={() => setShowReceipt({...showReceipt, show: false})} url={showReceipt.url}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <div className="modal_content">
                <div className='lab_form' style={{height: 'calc(100% - 50px)'}}>
                    <div className="test_header mt-0 mb-2">Receive PCR Samples</div>

                    <div style={{background: 'white', padding: '10px'}}>
                        <div className="row mx-0 mb-2">
                            <div className="col-12 pl-0 pr-0">
                                <div className="label mb-1">Select machine name.</div>
                                <MySelect placeholder="Select machine name" name="machine_id" value={machineID}
                                          change={(value) => setMachineID(value as number)}
                                          options={[
                                              {text: "Select a machine", value: 0},
                                              ...params.machines.map((machine) => {
                                                  return ({text: machine.machine_name + " (" + machine.machine_no + ")", value: machine.machine_id});
                                              })
                                          ]}/>
                            </div>
                        </div>
                    </div>

                    <div className="received_container mt-2">
                        <div className="table_container table_100">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">
                                            <Checkbox
                                                checked={tests.length > 0 && tests.filter((sample) => sample.loaded).length === tests.length}
                                                onChange={(event, value) => {
                                                    setTests(tests.map((sample) => ({...sample, loaded: (value.checked as boolean)})))
                                                }}/>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '120px'}}>Diagnosis No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '200px'}}>Date Created</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '100px'}}>Well No.</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        tests.map((test, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">
                                                    <Checkbox
                                                        className="ml-2" checked={test.loaded}
                                                        onChange={(event, value) => {
                                                            setTests(
                                                                tests.map((_test) =>
                                                                    test.diagnosis_no === _test.diagnosis_no ?
                                                                        {..._test, loaded: (value.checked as boolean)} : {..._test}
                                                                ))
                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{test.diagnosis_no}</Table.Cell>
                                                <Table.Cell style={{width: '200px'}}>
                                                    {moment(test.date_created).format("llll")}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>
                                                    <MyInput placeholder="Well No." name="lab_no" value={test.lab_no}
                                                             change={(name, value) => {
                                                                 setTests(
                                                                     tests.map((_test) =>
                                                                         _test.diagnosis_no === test.diagnosis_no ?
                                                                             {..._test, lab_no: value} : _test
                                                                     )
                                                                 )
                                                             }}/>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                <div className="row mx-0 p-0">
                    <div className="col-2 pl-0 pr-1">
                        <Button primary icon="search" labelPosition="left" size="tiny" fluid
                                content="Search" onClick={load_received_samples}/>
                    </div>
                    <div className="col-2 pl-0 pr-1">
                        <Button primary icon="print" labelPosition="left" size="tiny" fluid
                                content="Print" onClick={print_loaded_samples}/>
                    </div>
                    <div className="col-4 pl-0 pr-1">
                        <Button negative icon="close" labelPosition="left" size="tiny" fluid content="Close Window" onClick={close}/>
                    </div>
                    <div className="col-4 pl-1 pr-0">
                        <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                content="Load Samples" onClick={load_samples}/>
                    </div>
                </div>
            </div>
        </>
    )
}
