import React from "react";
import {Button, Confirm, Modal} from "semantic-ui-react";
import Utils, {InputFile, Loading, my_initial_confirm, MyConfirmAction, MyDateInput, MyInput, MySelect, read_input, SelectData} from "../../../utils/Utils";
import {countries} from "../../../utils/Countries";
import moment from "moment";
import {SystemUser} from "../../AppBar";
import {ToastsStore} from "react-toasts";
import {Request} from "../../../utils/Request";

interface Child {
    passport_photo: InputFile
    client_id: number
    group_id: number
    first_name: string,
    last_name: string,
    other_name: string,
    client_gender: string,
    date_of_birth: string
    passport_no: string
    nationality: string
    client_age: string | number
}

export interface Parent {
    mobile_contact: string
    email_address: string
    village: string
    sub_county: string
    parish: string
    district: string
    next_of_kin: string
    next_of_kin_contact: string
}

export const initial_parent: Parent = {
    district: "", email_address: "", mobile_contact: "", next_of_kin: "",
    next_of_kin_contact: "", parish: "", sub_county: "", village: ""
}

let initial_child: Child = {
    client_gender: "", client_id: 0, date_of_birth: "", first_name: "", group_id: 0, last_name: "", nationality: "",
    other_name: "", passport_no: "", passport_photo: new InputFile(""), client_age: "",
}

export default function AddDependant(props: {
    show: boolean, close: () => void, systemUser: SystemUser, groups: Array<SelectData>,
    create_test: (client_id: number) => void, parent: Parent
}) {
    const [loader, setLoader] = React.useState({show: false, message: "Uploading data, please wait"})
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)

    const [child, setChild] = React.useState<Child>(initial_child)
    const handle_client = (name: string, value: string | number | InputFile) => {
        setChild({...child, [name]: value})
    }

    const save_child = () => {
        if (Utils.invalid_name(child.first_name.trim())) {
            ToastsStore.error("Enter a valid first name")
        } else if (Utils.invalid_name(child.last_name.trim())) {
            ToastsStore.error("Enter a valid last name")
        } else if (child.date_of_birth === "") {
            ToastsStore.error("Enter a valid date of birth")
        } else if (moment(child.date_of_birth).isBefore(moment("1900-01-01"))) {
            ToastsStore.error("Date of birth cannot be before 01/01/1900")
        } else if (moment(child.date_of_birth).isAfter(moment(Utils.today()))) {
            ToastsStore.error("Date of birth cannot be after today")
        } else if (child.client_gender === "") {
            ToastsStore.error("Please select a gender")
        } else if (child.nationality === "") {
            ToastsStore.error("Please select nationality")
        } else {
            setConfirm({
                ...confirm, open: true, content: 'Do you want to save client data?',
                onConfirm: () => {
                    setConfirm({...confirm, open: false})
                    setLoader({message: "Saving client data, please wait", show: true})

                    Request.update_account({...child, ...props.parent})
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    ToastsStore.success("Successfully saved user profile")
                                    setChild({...child, client_id: response.data.data.client_id})
                                } else if (response.data.code === 4) {
                                    ToastsStore.error("Client data could not be matched, please retry")
                                } else if (response.data.code === 2) {
                                    ToastsStore.error("Could not process data")
                                }
                            } else {
                                ToastsStore.error("Error while saving client data, please retry")
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                            ToastsStore.error("Error while saving client data, please retry")
                        })
                },
                onCancel: () => setConfirm({...confirm, open: false})
            })
        }
    }

    React.useEffect(() => {
        if (props.show) {

        }
    }, [props.show])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content} header="Clear Data"/>

            <Modal size='mini' open={props.show} centered={false} onClose={props.close}>
                <div className="test_slip">
                    <div className="family_form">
                        <div className="passport_photo_container">
                            <img src={child.passport_photo.file_src} alt='' className='client_passport_photo'/>

                            <div>
                                <label htmlFor="dependant_photo_input" className="ui mini primary button fluid">
                                    <i className="ui upload icon"/>Select an image file
                                </label>
                                <input type="file" className="input_file" id="dependant_photo_input"
                                       accept="image/*" multiple={false}
                                       onChange={(event) => {
                                           read_input(event)
                                               .then((response) => {
                                                   handle_client('passport_photo', response);
                                                   (document.getElementById("dependant_photo_input") as HTMLInputElement).value = "";
                                               })
                                               .catch((error) => {
                                                   console.log(error)
                                               })
                                       }}/>
                            </div>
                        </div>

                        <div className="user_data_container">
                            <div className="label">First Name</div>
                            <MyInput placeholder="First Name" name="first_name" value={child.first_name} change={handle_client}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Last Name</div>
                            <MyInput placeholder="Last Name" name="last_name" value={child.last_name} change={handle_client}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Other Name (optional)</div>
                            <MyInput placeholder="Other Name" name="other_name" value={child.other_name} change={handle_client}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Nationality</div>
                            <MySelect
                                change={(value) => handle_client("nationality", value as string)}
                                name="nationality" value={child.nationality} placeholder="Select nationality" options={countries}/>
                        </div>

                        <div className="row m-0">
                            <div className="col-8 p-1">
                                <div className="user_data_container">
                                    <div className="label">Date of Birth</div>
                                    <MyDateInput
                                        value={child.date_of_birth} name="date_of_birth" maxDate={Utils.today()}
                                        placeholder="Select date of birth" change={handle_client}/>
                                </div>
                            </div>

                            <div className="col-4 p-1">
                                <div className="user_data_container">
                                    <div className="label">Age</div>
                                    <MyInput value={child.client_age.toString()} name="client_age" placeholder="Age" change={handle_client}/>
                                </div>
                            </div>
                        </div>


                        <div className="user_data_container">
                            <div className="label">Gender</div>
                            <MySelect
                                change={(value) => handle_client("client_gender", value as string)}
                                name="client_gender" value={child.client_gender} placeholder="Select user gender"
                                options={[{value: "Other", text: "Other"}, {value: "Male", text: "Male"}, {value: "Female", text: "Female"}]}/>
                        </div>

                        <div className="user_data_container">
                            <div className="label">Passport No. (necessary for travellers)</div>
                            <MyInput placeholder="Passport No" name="passport_no" value={child.passport_no} change={handle_client}/>
                        </div>

                        {
                            Utils.has_center_role({role: "manage_companies", roles: props.systemUser.center.center_roles}) &&
                            <>
                                <div className="user_data_container mb-0">
                                    <div className="label">Client Company</div>
                                    <MySelect
                                        change={(value) => handle_client("group_id", value as number)}
                                        name="group_id" value={child.group_id} placeholder="Select company name" options={props.groups}/>
                                </div>
                            </>
                        }
                    </div>

                    <div className="app_data_button bg_white">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative icon="close" labelPosition="left" size="mini" fluid
                                        content="Close Window" onClick={props.close}/>
                            </div>

                            <div className="col-6 p-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid
                                        content="Save Data" onClick={save_child}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
