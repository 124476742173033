import React from "react";
import {Request} from "../utils/Request";
import Utils, {Loading, MyDateInput} from "../utils/Utils";
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {Button} from "semantic-ui-react";

interface Graph {
    date: string
    negative: number
    pending: number
    positive: number
    total: number
}

interface Data {
    male: { negative: number, pending: number, positive: number, invalid: number }
    other: { negative: number, pending: number, positive: number, invalid: number }
    female: { negative: number, pending: number, positive: number, invalid: number }
    graph: Array<Graph>
}

export default function DashBoard() {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [search, setSearch] = React.useState({min_date: "2021-08-30", max_date: Utils.today()})
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [data, setData] = React.useState<Data>({
        graph: [],
        female: {invalid: 0, negative: 0, pending: 0, positive: 0},
        male: {invalid: 0, negative: 0, pending: 0, positive: 0},
        other: {invalid: 0, negative: 0, pending: 0, positive: 0}
    })

    const large_div = React.createRef<HTMLDivElement>()
    const [dimens, setDimens] = React.useState({width: 0, height: 0})

    const get_report = () => {
        setLoader({message: "Loading data, please wait", show: true})
        Request.dashboard({min_date: search.min_date, max_date: search.max_date})
            .then((response) => {
                if (response.data.hasOwnProperty('data')) {
                    console.log(response.data.data.data)
                    setData(response.data.data.data)
                }
                setLoader({message: "", show: false})
            })
            .catch(() => {
                setLoader({message: "", show: false})
            })
    }

    React.useEffect(() => {
        if (large_div.current) {
            setDimens({width: large_div.current.offsetWidth, height: large_div.current.offsetHeight});
        }
        get_report()
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <div className='container-fluid'>
                <div className='row m-0'>
                    <div className="col-12 p-1">
                        <div className="content_bar">
                            <div className="search_bar">
                                <div className='dropdown'>
                                    <Button icon='ordered list' primary size='tiny'/>

                                    <div className='drop_list'>
                                        <div className='hide_large'>
                                            <label>Minimum Date</label>
                                            <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                                         change={handle_search} maxDate={Utils.today()}/>

                                            <label>Maximum Date</label>
                                            <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                                         change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='hide_small'>
                                    <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                                 change={handle_search} maxDate={Utils.today()}/>
                                </div>
                                <div className='hide_small'>
                                    <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                                 change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                                </div>
                            </div>

                            <div className="content_buttons d-none d-md-inline-block">
                                <div className="payment_button">
                                    <Button positive size='mini' content='Search' onClick={get_report}/>
                                </div>
                            </div>

                            <div className="content_buttons d-md-none">
                                <Button positive size='mini' icon='search' onClick={get_report}/>
                            </div>
                        </div>
                    </div>

                    {
                        [data.female, data.male, data.other]
                            .map((data, index) => {
                                const gender = index === 0 ? "Females" : (index === 1 ? "Males" : "Others")

                                return (
                                    <div className='col-12 col-sm-6 col-md-4 p-1'>
                                        <div className='dash_container female'>
                                            <div className='header'>{gender}</div>
                                            <div className='count'>
                                                {Utils.comma_number(data.pending + data.invalid + data.negative + data.positive, '', '')}
                                            </div>
                                            <div className="row dash_divider">
                                                <div className="col-3  sub_content">
                                                    <div className="sub_header">Negative</div>
                                                    <div className="sub_count"> {Utils.comma_number(data.negative, '', '')}</div>
                                                </div>
                                                <div className="col-3  sub_content">
                                                    <div className="sub_header">Positive</div>
                                                    <div className="sub_count"> {Utils.comma_number(data.positive, '', '')}</div>
                                                </div>
                                                <div className="col-3  sub_content">
                                                    <div className="sub_header">Pending</div>
                                                    <div className="sub_count"> {Utils.comma_number(data.pending, '', '')}</div>
                                                </div>
                                                <div className="col-3  sub_content">
                                                    <div className="sub_header">Invalid</div>
                                                    <div className="sub_count"> {Utils.comma_number(data.invalid, '', '')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    }

                    <div className='col-12 p-1 mt-3'>
                        <div className='dash_graph' ref={large_div}>
                            <LineChart
                                width={dimens.width - 20} height={dimens.height - 20} data={data.graph}
                                margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="date"/>
                                <YAxis/>
                                <Tooltip/>
                                <Line type="monotone" dataKey="total" stroke="#072158"/>
                                <Line type="monotone" dataKey="negative" stroke="#06440B"/>
                                <Line type="monotone" dataKey="positive" stroke="#680A0A"/>
                                <Line type="monotone" dataKey="pending" stroke="#BB4F09"/>
                            </LineChart>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
