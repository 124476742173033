import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {Request} from "../../utils/Request";
import Utils from "../../utils/Utils";

export default function Login(props: { show: boolean, close: (token: string, userData: any) => void, message: string }) {
    const [saving, setSaving] = React.useState(false)
    const [error, setError] = React.useState("")
    const [credential, setLoginData] = React.useState({email: "", password: ""})

    const login_user = () => {
        setSaving(true)

        let fcm_token = localStorage.getItem("fcm_token")
        fcm_token = fcm_token === null ? "" : fcm_token

        Request.login_user({credential: credential.email, login_password: credential.password, fcm_token: fcm_token})
            .then((response) => {
                setSaving(false)
                if (response.data.hasOwnProperty("code")) {
                    const code: number = response.data.code
                    if (code === 1) {
                        localStorage.setItem("fcm_token", fcm_token as string)
                        props.close(response.data.data.login_token, response.data.data)
                    } else if (code === 2) {
                        setError("Please make sure you have provided email or contact or username and password")
                    } else if (code === 3) {
                        setError("Invalid credentials, please check your credentials and retry")
                    } else if (code === 4) {
                        window.location.replace("/verify/" + response.data.data.login_token)
                    }
                } else {
                    setError("Could not login, please retry")
                }
            })
            .catch((error) => {
                console.error(error)
                setSaving(false)
                setError("Could not login, please retry")
            })
    }

    React.useEffect(() => {
        setError(props.message)
    }, [props.message])

    React.useEffect(() => {
        setLoginData({...credential, password: ""})
    }, [props.show])

    return (
        <>
            <Modal size='mini' open={props.show} dimmer="blurring" centered={false} closeOnDimmerClick={false} closeOnEscape={false}>
                <div className="login_window">
                    <div className="ui large form">
                        <div className="ui stacked secondary segment">
                            <div className="login_logo">
                                <img alt="" src={Utils.get_logo()}/>
                            </div>


                            <input id="username" className="hidden_for" type="text" name="fakeusernameremembered"/>
                            <input id="password" className="hidden_for" type="password" name="fakepasswordremembered"/>

                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="user icon"/>
                                    <input type="text" placeholder="Enter username" value={credential.email}
                                           onChange={(event) => {
                                               setLoginData({...credential, 'email': event.target.value})
                                           }}/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="ui left icon input">
                                    <i className="lock icon"/>
                                    <input type="password" placeholder="Login password" value={credential.password}
                                           autoComplete="autocomplete_off_hack_xfr4!k"
                                           onChange={(event) => {
                                               setLoginData({...credential, 'password': event.target.value})
                                           }}
                                           onKeyDown={(event: any) => {
                                               if (event.keyCode === 13) {
                                                   login_user()
                                               }
                                           }}
                                    />
                                </div>
                            </div>

                            <div className="row m-0">
                                <div className="col-6 offset-3 pl-0 pr-1">
                                    <Button className="ui button fluid primary" size='small' loading={saving}
                                            disabled={saving} onClick={login_user} content="Proceed Login"/>
                                </div>
                            </div>

                        </div>
                        <div className={`ui error message ${error === "" ? '' : 'show'}`}>{error}</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
