import React from "react";
import Utils, {Loading, MessageProps, my_initial_confirm, MyConfirmAction, MyDateInput, MyInput, MyMessage, MySelect} from "../../../../utils/Utils";
import {Request} from "../../../../utils/Request";
import {Button, Checkbox, Confirm, Table} from "semantic-ui-react";
import update from "react-addons-update";
import PhoneInput from "react-phone-input-2";

interface Details {
    [key: string]: string
}

interface Payment {
    payment_id: number
    group_id: number
    amount_paid: number | string
    payment_method: string
    date_paid: string
    reference_no: string
    other_details: Details
    user_name: string
    date_created: string
}

const initial_payment: Payment = {
    amount_paid: '0', date_paid: Utils.today(), group_id: 0, other_details: {}, payment_id: 0, payment_method: "cash",
    reference_no: "", user_name: "", date_created: Utils.today()
}

export default function HandlePayment(params: { company_id: number }) {
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [loader, setLoader] = React.useState({show: false, message: "Uploading data, please wait"})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    /*payments*/
    const [payments, setPayments] = React.useState<Array<Payment>>([])
    const [invoices, setInvoices] = React.useState
    < Array < {used: 1 | 0, payment_id: number, diagnosis_no: string, client_name: string, amount_paid: number} >> ([])

    const [payment, setPayment] = React.useState(initial_payment)
    const handle_payment = (name: string, value: string | number) => {
        setPayment({...payment, [name]: value})
    }
    const handle_other_details = (name: string, value: string) => {
        setPayment({...payment, other_details: {...payment.other_details, [name]: value}})
    }

    const full_payment = () => {
        if (Utils.is_valid_number(payment.amount_paid as string) && parseFloat(payment.amount_paid as string) > 0) {
            let total_selected = 0
            invoices.forEach((invoice) => {
                if (invoice.used) {
                    total_selected += invoice.amount_paid
                }
            })
            return parseFloat(payment.amount_paid as string) == total_selected
        } else {
            return false
        }
    }

    const add_payment = () => {
        setConfirm({
            ...confirm,
            'open': true,
            'content': 'Do you want to continue making this invoice payment? ' +
                'Please note that this cannot be reversed',
            'onConfirm': () => {
                setConfirm({...confirm, 'open': false})
                let ids = Array<number>()
                invoices.forEach((invoice) => {
                    if (invoice.used) {
                        ids = [...ids, invoice.payment_id]
                    }
                })
                setLoader({message: "Saving group payment, please wait", show: true})
                Request.save_group_payment({
                    amount_paid: parseFloat(payment.amount_paid as string),
                    date_paid: payment.date_paid, group_id: params.company_id, other_details: JSON.stringify(payment.other_details),
                    payment_id: 0, payment_method: payment.payment_method, reference_no: payment.reference_no, ids: JSON.stringify(ids)
                })
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            setMessage({active: true, message: "Payment successfully made", type: 'success'})
                            setInvoices(response.data.data.invoices)
                            setPayments(response.data.data.payments)
                            setPayment(initial_payment)
                        } else {
                            setMessage({active: true, message: "Could not make payment, please retry", type: 'error'})
                        }
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                    })
            },
            'onCancel': () => setConfirm({...confirm, 'open': false})
        })
    }

    const get_group_payments = () => {
        setLoader({message: "Loading group payments, please wait", show: true})
        Request.get_group_payment({group_id: params.company_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setInvoices(response.data.data.invoices)
                    setPayments(response.data.data.payments)
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
            })
    }

    React.useEffect(() => {
        if (params.company_id > 0) {
            get_group_payments()
        }
    }, [])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content} centered={false}/>

            <div className="row m-0 h-100" style={{overflowY: 'auto'}}>
                <div className="col-12 col-md-5 p-1 h-100">
                    <div className="table_container group_payments p-0">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Amount Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Date Paid</Table.HeaderCell></Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    payments.map((payment, index) =>
                                        <Table.Row key={payment.payment_id}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{Utils.comma_number(payment.amount_paid as number)}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{Utils.localise_date(payment.date_paid)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className='col-12 col-md-7 p-1 h-100'>
                    <div className='app_data_form'>
                        <div className="group_payment_form">
                            <div className='row m-0'>
                                <div className='col-12 col-md-6 p-1'>
                                    <div className="test_header">Amount Paid</div>
                                    <MyInput placeholder="Enter amount paid" name="amount_paid"
                                             value={payment.amount_paid as string} change={handle_payment}/>

                                    <div className="test_header mt-3">Date Paid</div>
                                    <MyDateInput value={payment.date_paid} name="date_paid" placeholder=""
                                                 change={handle_payment} maxDate={Utils.today()}/>

                                    <div className="test_header mt-3">Payment Method</div>
                                    <MySelect
                                        change={(value) => {
                                            let details: Details = {}
                                            switch (value as string) {
                                                case 'cheque':
                                                    details = {bank_name: "", cheque_no: ""}
                                                    break
                                                case 'transfer':
                                                    details = {ref_no: ""}
                                                    break
                                                case 'card':
                                                    details = {bank_name: "", ref_no: "", expiry_date: ""}
                                                    break
                                                case 'mobile':
                                                    details = {contact: "", ref_no: ""}
                                                    break
                                            }
                                            setPayment({...payment, other_details: details, payment_method: value as string})
                                        }}
                                        name="test_type" value={payment.payment_method} placeholder="Select payment method"
                                        options={[
                                            {value: "cash", text: "Cash Payment"},
                                            {value: "cheque", text: "Bank Cheque"},
                                            {value: "transfer", text: "EFT"},
                                            {value: "card", text: "Debit or Credit Card"},
                                            {value: "mobile", text: "Mobile Money"}
                                        ]}/>
                                </div>
                                <div className='col-12 col-md-6 p-1'>
                                    {
                                        payment.payment_method === "cheque" &&
                                        <>
                                            <div className="test_header">Bank Name</div>
                                            <MyInput placeholder="Enter bank name" name="bank_name"
                                                     value={payment.other_details.bank_name} change={handle_other_details}/>

                                            <div className="test_header mt-3">Cheque Number</div>
                                            <MyInput placeholder="Enter cheque no." name="cheque_no"
                                                     value={payment.other_details.cheque_no} change={handle_other_details}/>
                                        </>
                                    }
                                    {
                                        payment.payment_method === "transfer" &&
                                        <>
                                            <div className="test_header">Reference Number</div>
                                            <MyInput placeholder="Enter payment reference no." name="ref_no"
                                                     value={payment.other_details.ref_no} change={handle_other_details}/>
                                        </>
                                    }
                                    {
                                        payment.payment_method === "card" &&
                                        <>
                                            <div className="test_header">Bank Name</div>
                                            <MyInput placeholder="Enter bank name" name="bank_name"
                                                     value={payment.other_details.bank_name} change={handle_other_details}/>

                                            <div className="test_header mt-3">Card Expiry Date</div>
                                            <MyDateInput value={payment.other_details.expiry_date} name="expiry_date"
                                                         change={handle_other_details} placeholder=""/>

                                            <div className="test_header mt-3">Reference Number</div>
                                            <MyInput placeholder="Enter payment reference no." name="ref_no"
                                                     value={payment.other_details.ref_no} change={handle_other_details}/>
                                        </>
                                    }
                                    {
                                        payment.payment_method === "mobile" &&
                                        <>
                                            <div className="test_header">Reference Number</div>
                                            <MyInput placeholder="Enter payment reference no." name="ref_no"
                                                     value={payment.other_details.ref_no} change={handle_other_details}/>

                                            <div className="test_header mt-3">Phone Number</div>
                                            <PhoneInput
                                                countryCodeEditable={false} country={'ug'} value={payment.other_details.contact}
                                                onChange={(value) => handle_other_details('contact', value)}/>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="group_payment_form">
                            <div className="table_container group_payments p-0">
                                <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center"/>
                                            <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Diagnosis No</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '140px'}}>Amount</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            invoices.map((invoice, index) =>
                                                <Table.Row key={invoice.payment_id}>
                                                    <Table.Cell style={{width: '50px'}} textAlign='center'>{Utils.row_number(index)}</Table.Cell>
                                                    <Table.Cell style={{width: '50px'}} textAlign="center">
                                                        <Checkbox
                                                            label='' checked={invoice.used === 1} disabled={payment.payment_id > 0}
                                                            onChange={(event, data) => {
                                                                setInvoices(update(invoices, {
                                                                    [index]: {$set: {...invoice, used: (data.checked as boolean) ? 1 : 0}}
                                                                }))
                                                            }}/>
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '200px'}}>{invoice.client_name}</Table.Cell>
                                                    <Table.Cell style={{width: '120px'}}>{invoice.diagnosis_no}</Table.Cell>
                                                    <Table.Cell style={{width: '140px'}}>{Utils.comma_number(invoice.amount_paid)}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>

                    <div className="app_data_button bg_white">
                        <div className="row m-0">
                            <div className="col-6 col-md-4 offset-md-2 pl-0 pr-1">
                                <Button negative icon="trash" labelPosition="left" size="mini" fluid
                                        content="Clear Data" onClick={() => setPayment(initial_payment)}/>
                            </div>

                            <div className="col-6 col-md-4 p-0">
                                <Button positive icon="save" labelPosition="left" size="mini" fluid content="Save Data"
                                        onClick={add_payment} disabled={payment.payment_id > 0 || !full_payment()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}